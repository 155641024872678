// ** Types Import
import { Settings } from 'src/@core/context/settingsContext';
import { NavLink, NavGroup, NavSectionTitle, VerticalNavItemsType } from 'src/@core/layouts/types';

// ** Custom Menu Components
import VerticalNavLink from './VerticalNavLink';
import VerticalNavGroup from './VerticalNavGroup';
import VerticalNavSectionTitle from './VerticalNavSectionTitle';
import ImportFailureNavLink from './custom/ImportFailureNavLink';

export interface Props {
  parent?: NavGroup;
  navHover?: boolean;
  settings: Settings;
  navVisible?: boolean;
  groupActive: string[];
  isSubToSub?: NavGroup;
  currentActiveGroup: string[];
  navigationBorderWidth: number;
  verticalNavItems?: VerticalNavItemsType;
  saveSettings: (values: Settings) => void;
  setGroupActive: (value: string[]) => void;
  setCurrentActiveGroup: (item: string[]) => void;
}

const resolveNavItemComponent = (item: NavGroup | NavLink | NavSectionTitle) => {
  if ((item as NavSectionTitle).sectionTitle) return VerticalNavSectionTitle;
  if ((item as NavGroup).children && (item as NavGroup).children!.length > 0) return VerticalNavGroup;

  return VerticalNavLink;
};

const VerticalNavItems = (props: Props) => {
  // ** Props
  const { verticalNavItems } = props;

  const RenderMenuItems =
    verticalNavItems?.map((item: NavGroup | NavLink | NavSectionTitle, index: number) => {
      if ((item as any).menuItemComponentCode === 'IMPORTFAILURES') {
        return <ImportFailureNavLink {...props} props={{ ...props }} key={(item as any).id} item={item} />;
      }

      const TagName: any = resolveNavItemComponent(item);

      // eslint-disable-next-line react/no-array-index-key
      return <TagName {...props} key={index} item={item} />;
    }) ?? [];

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{RenderMenuItems}</>;
};

export default VerticalNavItems;
