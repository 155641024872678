import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ParsedCustomerConfig } from 'src/views/uiflow/orders/helpers/parseCustomerConfig';

const initialState: Partial<ParsedCustomerConfig> = {
  unitCodeData: {},
  cutOffData: {},
  hazardousData: {},
  paperworkData: {},
  generalConfigProfileData: {},
  customerData: {},
  rdcInformation: {},
  configurationUpdateAvailable: true
};

export const customerConfigurationSlice = createSlice({
  name: 'customerConfiguration',
  initialState: initialState,
  reducers: {
    setCustomerConfiguration: (state, action: PayloadAction<ParsedCustomerConfig>) => {
      state.unitCodeData = action.payload?.unitCodeData || {};
      state.cutOffData = action.payload?.cutOffData || {};
      state.hazardousData = action.payload?.hazardousData || {};
      state.paperworkData = action.payload?.paperworkData || {};
      state.generalConfigProfileData = action.payload?.generalConfigProfileData || {};
      state.customerData = action.payload?.customerData || {};
      state.rdcInformation = action.payload?.rdcInformation || {};
      state.configurationUpdateAvailable = action.payload?.configurationUpdateAvailable;
    },
    setUpdateAvailableFlag: (state, action: PayloadAction<boolean>) => {
      state.configurationUpdateAvailable = action.payload;
    },
    resetCustomerConfiguration: () => initialState
  }
});

export const { setCustomerConfiguration, setUpdateAvailableFlag, resetCustomerConfiguration } =
  customerConfigurationSlice.actions;

export default customerConfigurationSlice.reducer;
