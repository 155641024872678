import React from 'react';
import { Box, useTheme } from '@mui/material';
import styles from '../../vigofiledrop.module.css';
import DropZoneBackground from './DropZoneBackground';
import { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone';

interface DropZoneProps {
  maxFileSize?: number;
  acceptedFileTypes?: Record<string, string[]>;
  dropBoxHeight?: string;
  required?: boolean;
  description: string;
  isDragAccept: boolean;
  getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T;
  getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T;
}

const DropZone = React.forwardRef(
  (
    {
      maxFileSize,
      acceptedFileTypes,
      dropBoxHeight,
      required,
      description,
      isDragAccept,
      getRootProps,
      getInputProps
    }: DropZoneProps,
    ref
  ) => {
    const theme = useTheme();

    /**
     * Get the dropzone text color
     */
    const dropzoneTextColor = isDragAccept
      ? theme.palette.customColors.primaryGradient
      : theme.palette.mode === 'light'
      ? '#626679'
      : '#fff';

    return (
      <Box
        sx={{
          minHeight: '100px',
          ...(dropBoxHeight ? { height: dropBoxHeight } : { flex: 1 }),
          margin: '0px 0px 0px 0px'
        }}>
        <Box
          ref={ref}
          {...getRootProps()}
          sx={{
            border: `1px dashed ${isDragAccept ? theme.palette.customColors.primaryGradient : '#acacac'}`,
            borderRadius: '4px',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            position: 'relative',
            width: '100%'
          }}
          className={styles.dropFilesBox}>
          <input {...getInputProps()} />
          <DropZoneBackground
            required={required}
            dropzoneTextColor={dropzoneTextColor}
            description={description}
            maxFileSize={maxFileSize}
            acceptedFileTypes={acceptedFileTypes}
          />
        </Box>
      </Box>
    );
  }
);

export default DropZone;
