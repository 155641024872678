// ** React Imports
import { useState } from 'react';

// ** MUI Imports
import Fab from '@mui/material/Fab';
import Backdrop from '@mui/material/Backdrop';
import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';

// ** Icons Imports
import ArrowUp from 'mdi-material-ui/ArrowUp';

// ** Theme Config Import
import themeConfig from 'src/configs/themeConfig';

// ** Type Import
import { LayoutProps } from 'src/@core/layouts/types';

// ** Components
import AppBar from './components/vertical/appBar';
import Navigation from './components/vertical/navigation';
import Footer from './components/shared-components/footer';
import ScrollToTop from 'src/@core/components/scroll-to-top';

// ** Styled Component
import DatePickerWrapper from 'src/@core/styles/libs/react-datepicker';

const VerticalLayoutWrapper = styled('div')({
  height: '100%',
  display: 'flex',
  overflow: 'hidden'
});

const MainContentWrapper = styled(Box)<BoxProps>({
  flex: 1,
  display: 'flex',
  height: '100vh',
  flexDirection: 'column',
  overflow: 'hidden'
});

const ContentWrapper = styled('main')(({ theme }) => ({
  overflowY: 'auto',
  height: 0,
  flex: 1,
  width: '100%',
  padding: theme.spacing(6),
  paddingTop: 0,
  transition: 'padding .25s ease-in-out',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4)
  }
}));

const VerticalLayout = (props: LayoutProps) => {
  // ** Props
  const { settings, children, scrollToTop } = props;

  // ** Vars
  const { skin } = settings;
  const navWidth = themeConfig.navigationSize;
  const navigationBorderWidth = skin === 'bordered' ? 1 : 0;
  const collapsedNavWidth = themeConfig.collapsedNavigationSize;

  // ** States
  const [navHover, setNavHover] = useState<boolean>(false);
  const [navVisible, setNavVisible] = useState<boolean>(false);
  const [showBackdrop, setShowBackdrop] = useState<boolean>(false);

  // ** Toggle Functions
  const toggleNavVisibility = () => {
    setNavVisible(!navVisible);
    setNavHover(!navHover);
  };

  return (
    <>
      <VerticalLayoutWrapper className="layout-wrapper">
        {/* Navigation Menu */}
        {settings.navHidden ? null : (
          <Navigation
            navWidth={navWidth}
            navHover={navHover}
            navVisible={navVisible}
            setNavHover={setNavHover}
            setNavVisible={setNavVisible}
            collapsedNavWidth={collapsedNavWidth}
            toggleNavVisibility={toggleNavVisibility}
            navigationBorderWidth={navigationBorderWidth}
            {...props}
          />
        )}
        <MainContentWrapper className="layout-content-wrapper">
          {/* AppBar Component */}
          <AppBar setShowBackdrop={setShowBackdrop} toggleNavVisibility={toggleNavVisibility} {...props} />

          {/* Content */}
          <ContentWrapper>{children}</ContentWrapper>

          {/* Footer Component */}
          <Footer showBackdrop={showBackdrop} {...props} />

          {/* Portal for React Datepicker */}
          <DatePickerWrapper sx={{ zIndex: 11, border: '1px solid red', position: 'absolute' }}>
            <Box id="react-datepicker-portal"></Box>
          </DatePickerWrapper>
        </MainContentWrapper>

        {/* Backdrop */}
        <Backdrop open={showBackdrop} onClick={() => setShowBackdrop(false)} sx={{ zIndex: 12 }} />
      </VerticalLayoutWrapper>

      {/* Scroll to top button */}
      {scrollToTop ? (
        scrollToTop(props)
      ) : (
        <ScrollToTop className="mui-fixed">
          <Fab color="primary" size="small" aria-label="scroll back to top">
            <ArrowUp />
          </Fab>
        </ScrollToTop>
      )}
    </>
  );
};

export default VerticalLayout;
