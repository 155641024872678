import { Box } from '@mui/material';
import { ReactNode } from 'react';

interface ErrorWrapperProps {
  isError: boolean;
  message?: string;
  children: ReactNode;
}

const ErrorWrapper = ({ isError, message, children }: ErrorWrapperProps) => {
  const ERROR_COLOUR = 'red';

  const errorStyles = {
    border: `1px solid ${ERROR_COLOUR}`,
    padding: '5px',
    borderRadius: '5px',
    minHeight: '50px',
    position: 'relative'
  };

  return (
    <Box sx={{ ...(isError ? errorStyles : {}), width: '100%', height: '100%' }}>
      {children}
      <small style={{ position: 'absolute', bottom: '-20px', left: '5px', color: ERROR_COLOUR }}>{message}</small>
    </Box>
  );
};

export default ErrorWrapper;
