import { AxiosRequestConfig } from 'axios';
import {
  GetDataTypeQueryParams,
  GetDtoPropertyQueryParams,
  GetImportDataSourceByidQueryParams,
  GetImportDataSourceQueryParams,
  GetImportTemplateByidQueryParams,
  GetImportTemplateMappingQueryParams,
  GetImportTemplateQueryParams,
  GetImportTemplateViewQueryParams,
  ImportDataSourceDto,
  ImportTemplateDto,
  ImportTemplateMappingDto,
  ImportTemplateMappingDtoApiResponseListApiResponse,
  ImportTemplateMappingModelBulkItemModel,
  ImportTemplateModel,
  ImportTemplateViewDto,
  DtoPropertyDto,
  GetDtoPropertyByidQueryParams,
  DataTypeDto,
  GetDataTypeByidQueryParams,
  PropertyTypeDto,
  GetPropertyTypeQueryParams,
  GetPropertyTypeByidQueryParams,
  GetDtoQueryParams,
  GetFailedImportsViewQueryParams,
  GetImportByidQueryParams,
  GetImportByidFileQueryParams,
  GetImportDataSourceAllowedKeyFieldsQueryParams
} from 'src/models/import';
import { GetAxiosInstance } from 'src/services/baseService';
import { ServiceLocation } from 'src/services/serviceLocationsAxios';
import { BulkDeleteModel } from 'src/views/uiflow/util/types';

/**
 * Retrieves the import templates.
 * @param params - Optional query parameters for the request.
 * @returns A Promise that resolves to the import templates.
 */
export const getImportTemplates = async (params?: GetImportTemplateQueryParams) => {
  return GetAxiosInstance().get<ImportTemplateDto[]>(ServiceLocation.import + '/ImportTemplate', { params });
};

/**
 * Retrieves an import template by its ID.
 * @param id - The ID of the import template.
 * @param params - Optional query parameters for the request.
 * @returns A Promise that resolves to the import template.
 */
export const getImportTemplateById = async (id: string, params?: GetImportTemplateByidQueryParams) => {
  return GetAxiosInstance().get(ServiceLocation.import + '/ImportTemplate/' + id, { params });
};

/**
 * Retrieves the import template views.
 * @param params - Optional query parameters for the request.
 * @returns A promise that resolves to the import template views.
 */
export const getImportTemplateViews = async (params?: GetImportTemplateViewQueryParams) => {
  return GetAxiosInstance().get<ImportTemplateViewDto[]>(ServiceLocation.import + '/ImportTemplateView', { params });
};

/**
 * Retrieves the import data sources.
 * @param params - Optional query parameters for the request.
 * @returns A promise that resolves to the import data sources.
 */
export const getImportDataSources = async (params?: GetImportDataSourceQueryParams) => {
  return GetAxiosInstance().get<ImportDataSourceDto[]>(ServiceLocation.import + '/ImportDataSource', { params });
};

/**
 * Retrieves the import data source by its ID.
 * @param id - The ID of the import data source.
 * @param params - Optional query parameters for the request.
 * @returns A promise that resolves to the import data source.
 */
export const getImportDataSourceById = async (id: string, params?: GetImportDataSourceByidQueryParams) => {
  return GetAxiosInstance().get(ServiceLocation.import + '/ImportDataSource/' + id, { params });
};

/**
 * Retrieves the data types for the import mapping.
 * @param params - Optional query parameters for the request.
 * @returns A promise that resolves to the data types.
 */
export const getImportMappingDataTypes = async (params?: GetDataTypeQueryParams) => {
  return GetAxiosInstance().get<DataTypeDto[]>(ServiceLocation.import + '/DataType', { params });
};

/**
 * Retrieves the data type by its ID.
 * @param id - The ID of the data type.
 * @param params - Optional query parameters for the request.
 * @returns A promise that resolves to the data type.
 */
export const getImportMappingDataTypeById = async (id: string, params: GetDataTypeByidQueryParams) => {
  return GetAxiosInstance().get<DataTypeDto>(ServiceLocation.import + '/DataType/' + id, { params });
};

/**
 * Retrieves the import template mappings.
 * @param params - Optional query parameters for the request.
 * @returns A promise that resolves to the import template mappings.
 */
export const getImportTemplateMappings = async (params: GetImportTemplateMappingQueryParams) => {
  return GetAxiosInstance().get<ImportTemplateMappingDto[]>(ServiceLocation.import + '/ImportTemplateMapping', {
    params
  });
};

/**
 * Retrieves the property types.
 * @param params - Optional query parameters for the request.
 * @returns A promise that resolves to the property types.
 */
export const getPropertyTypes = async (params?: GetPropertyTypeQueryParams) => {
  return GetAxiosInstance().get<PropertyTypeDto[]>(ServiceLocation.import + '/PropertyType', { params });
};

/**
 * Retrieves a property type by its ID
 * @param id - The ID of the property type.
 * @param params - Optional query parameters for the request.
 * @returns A promise that resolves to the property type.
 */
export const getPropertyTypeById = async (id: string, params: GetPropertyTypeByidQueryParams) => {
  return GetAxiosInstance().get<PropertyTypeDto>(ServiceLocation.import + '/PropertyType/' + id, { params });
};

/**
 * Retrieves the DTOs.
 * @param params - Optional query parameters for the request.
 * @returns A promise that resolves to the DTOs.
 */
export const getDtos = async (params?: GetDtoQueryParams) => {
  return GetAxiosInstance().get(ServiceLocation.import + '/Dto', { params });
};

/**
 * Retrieves the DTO properties.
 * @param params - Optional query parameters for the request.
 * @returns A promise that resolves to the DTO properties.
 */
export const getDtoProperties = async (params: GetDtoPropertyQueryParams) => {
  return GetAxiosInstance().get<DtoPropertyDto[]>(ServiceLocation.import + '/DtoProperty', { params });
};

/**
 * Retrieves the DTO property by its ID.
 * @param id - The ID of the DTO property.
 * @param params - Optional query parameters for the request.
 * @returns A promise that resolves to the DTO property.
 * */
export const getDtoPropertyById = async (id: string, params: GetDtoPropertyByidQueryParams) => {
  return GetAxiosInstance().get<DtoPropertyDto>(ServiceLocation.import + '/DtoProperty/' + id, { params });
};

/**
 * Retrieves the import by its ID.
 * @param id - The ID of the import.
 * @param params - Optional query parameters for the request.
 * @returns A promise that resolves to the import.
 */
export const getImportById = (id: string, params?: GetImportByidQueryParams) => {
  return GetAxiosInstance().get(ServiceLocation.import + '/Import/' + id, { params });
};

/**
 * Retrieves the failed imports view.
 * @param params - Optional query parameters for the request.
 * @returns A promise that resolves to the failed imports view.
 */
export const getFailedImportsViews = async (params?: GetFailedImportsViewQueryParams) => {
  return GetAxiosInstance().get(ServiceLocation.import + '/FailedImportsView', { params });
};

/**
 *
 * @param id string
 * @param params GetImportByidFileQueryParams
 * @returns returns the file
 */
export const getImportFile = async (id: string, params?: GetImportByidFileQueryParams, partnerId?: string) => {
  return GetAxiosInstance(undefined, undefined, partnerId).get(ServiceLocation.import + '/Import/' + id + '/file', {
    params
  });
};

/**
 * Posts an import template.
 * @param params The import template model.
 * @returns A promise that resolves to the result of the post request.
 */
export const postImportTemplate = async (params: ImportTemplateModel, partnerId?: string, source?: string) => {
  return GetAxiosInstance(undefined, undefined, partnerId, source).post(
    ServiceLocation.import + '/ImportTemplate',
    params
  );
};

export interface UploadModel {
  instanceId?: string;
  templateId: string;
  file: File;
  notifyOnCompletion?: string;
  notifyOnFailureOnly?: boolean;
  customerId: string;
}

/**
 * Uploads a file for import.
 * @param params The import template model.
 * @returns A promise that resolves to the result of the post request.
 */
export const postImportFileUpload = async (params: UploadModel) => {
  const formData = new FormData();
  formData.append('fileContent', params.file);
  formData.append('templateId', params.templateId);
  formData.append('notifyOnCompletion', params.notifyOnCompletion || '');
  formData.append('notifyOnFailureOnly', params.notifyOnFailureOnly ? 'true' : 'false');

  return GetAxiosInstance(
    undefined,
    undefined,
    params?.customerId ?? undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    'multipart/form-data'
  ).post(ServiceLocation.import + '/upload', formData);
};

/**
 *
 * @param id string
 * @param formData FormData
 * @returns A promise that resolves to the result of the post request
 */
export const postFailedImportRetry = async (id: string, formData: FormData) => {
  return GetAxiosInstance(
    undefined,
    undefined,
    (formData.get('customerId') as string) ?? undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    'multipart/form-data'
  ).post(ServiceLocation.import + '/upload?failedImportId=' + id, formData);
};

/**
 * Updates an import template.
 * @param params - The import template data to be updated.
 * @param partnerId - The partner ID (optional).
 * @param source - The source (optional).
 * @returns A promise that resolves to the updated import template.
 */
export const putImportTemplate = async (params: ImportTemplateDto, partnerId?: string, source?: string) => {
  return GetAxiosInstance(undefined, undefined, partnerId, source).put(
    ServiceLocation.import + '/ImportTemplate/' + params.id,
    params
  );
};

/**
 * Puts an import template.
 * @param params The import template model.
 * @returns A promise that resolves to the result of the put request.
 */
export const bulkPutTemplateMappings = async (models: ImportTemplateMappingModelBulkItemModel[]) => {
  return GetAxiosInstance().put<ImportTemplateMappingDtoApiResponseListApiResponse>(
    ServiceLocation.import + '/ImportTemplateMapping',
    models
  );
};

/**
 * Deletes an import template by ID.
 * @param params - The parameters for the delete request.
 * @param params.id - The ID of the import template to delete.
 * @param params.data - The data for the delete request, including remarks and version.
 * @returns A promise that resolves with the result of the delete request.
 */
export const deleteImportTemplateById = async (params: {
  id: string;
  data: AxiosRequestConfig<{
    remarks: string;
    version: string;
  }>;
}) => {
  return GetAxiosInstance().delete(ServiceLocation.import + '/ImportTemplate/' + params.id, params.data);
};

/**
 * Deletes an import by ID.
 * @param params - The parameters for the delete request.
 * @param params.id - The ID of the import to delete.
 * @param params.data - The data for the delete request, including remarks and version.
 * @returns A promise that resolves with the result of the delete request.
 */
export const deleteImportById = async (params: {
  id: string;
  data: AxiosRequestConfig<{
    remarks: string;
    version: string;
  }>;
}) => {
  return GetAxiosInstance().delete(ServiceLocation.import + '/Import/' + params.id, params.data);
};

/**
 * Posts an import template mapping.
 * @param params The import template mapping model.
 * @returns A promise that resolves to the result of the post request.
 */
export const bulkDeleteTemplateMappings = (models: BulkDeleteModel[]) => {
  const reqBody: AxiosRequestConfig<BulkDeleteModel[]> = {
    data: models
  };

  return GetAxiosInstance().delete(ServiceLocation.import + '/ImportTemplateMapping', reqBody);
};

/**
 * Get ImportDataSourceAllowedKeyFields
 *
 * @param params GetImportDataSourceByidQueryParams
 * @returns GetImportDataSourceAllowedKeyFieldsQueryParams[]
 */
export const getImportDataSourceAllowedKeyFields = async (params?: GetImportDataSourceAllowedKeyFieldsQueryParams) => {
  return GetAxiosInstance().get(ServiceLocation.import + '/ImportDataSourceAllowedKeyFields', { params });
};
