// ** MUI Imports
import Box from '@mui/material/Box';

// ** Type Import
import { Settings } from 'src/@core/context/settingsContext';

// ** Components
import ModeToggler from 'src/@core/layouts/components/shared-components/ModeToggler';
import Autocomplete from 'src/@core/layouts/components/shared-components/OmniSearch';
import UserDropdown from 'src/layouts/components/UserDropdown';
import LanguageDropdown from 'src/@core/layouts/components/shared-components/LanguageDropdown';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import NotificationDropdown from 'src/@core/layouts/components/shared-components/NotificationDropdown';

interface Props {
  hidden: boolean;
  settings: Settings;
  setShowBackdrop: (val: boolean) => void;
  saveSettings: (values: Settings) => void;
}
const AppBarContent = (props: Props) => {
  // ** Props
  const { hidden, settings, saveSettings, setShowBackdrop } = props;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Autocomplete hidden={hidden} setShowBackdrop={setShowBackdrop} />
      <LanguageDropdown settings={settings} saveSettings={saveSettings} />
      <ModeToggler settings={settings} saveSettings={saveSettings} />
      {/* <NotificationDropdown settings={settings} /> */}
      <UserDropdown settings={settings} saveSettings={saveSettings} />
    </Box>
  );
};

export default AppBarContent;
