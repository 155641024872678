import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../.';

export interface AttachmentsDataState {
  attachmentDtoId: string;
  selectedEntityId: string;
  selectedEntityIds: string[];
}

const initialState: AttachmentsDataState = {
  attachmentDtoId: '',
  selectedEntityId: '',
  selectedEntityIds: []
};

export const attachmentsDataSlice = createSlice({
  name: 'attachmentsData',
  initialState: initialState,
  reducers: {
    setAttachmentDtoId: (state, action: PayloadAction<string>) => {
      state.attachmentDtoId = action.payload;
    },
    setSelectedEntityId: (state, action: PayloadAction<string>) => {
      state.selectedEntityId = action.payload;
    },
    setSelectedEntityIds: (state, action: PayloadAction<string[]>) => {
      state.selectedEntityIds = action.payload;
    }
  }
});

export const { setAttachmentDtoId, setSelectedEntityId, setSelectedEntityIds } = attachmentsDataSlice.actions;

export const selectSelectedEntityIds = (state: RootState) => state.attachmentsData.selectedEntityIds;

export default attachmentsDataSlice.reducer;
