// ** Types
import { HorizontalNavItemsType, NavGroup, NavLink } from 'src/@core/layouts/types';

// ** Custom Navigation Components
import HorizontalNavLink from './HorizontalNavLink';
import HorizontalNavGroup from './HorizontalNavGroup';

interface Props {
  hasParent?: boolean;
  horizontalNavItems?: HorizontalNavItemsType;
}
const resolveComponent = (item: NavGroup | NavLink) => {
  if ((item as NavGroup).children) return HorizontalNavGroup;

  return HorizontalNavLink;
};

const HorizontalNavItems = (props: Props) => {
  // eslint-disable-next-line react/destructuring-assignment
  const RenderMenuItems = props.horizontalNavItems?.map((item: NavGroup | NavLink) => {
    const TagName: any = resolveComponent(item);

    return <TagName {...props} key={item} item={item} />;
  });

  return <div>{RenderMenuItems}</div>;
};

export default HorizontalNavItems;
