// ** React Imports
import { createContext, useState, ReactNode } from 'react';

export type VigoPage = {
  pageTitle?: string;
  pageSubTitle?: string;
};

export type PathToTitle = {
  path: string;
  title: string;
  icon: string;
};

export type VigoPageContextValue = {
  VigoPage: VigoPage;
  saveVigoPage: (updatedVigoPage: VigoPage) => void;
  pathToTitleMap: any[];
  setPathToTitleMap: (val: PathToTitle[]) => void;
};

interface VigoPageProviderProps {
  children: ReactNode;
}

const initialVigoPage: VigoPage = {
  pageTitle: '',
  pageSubTitle: ''
};

const initialPathToTitle: PathToTitle = {
  path: '',
  title: '',
  icon: ''
};

// ** Create Context
export const VigoPageContext = createContext<VigoPageContextValue>({
  saveVigoPage: () => null,
  VigoPage: initialVigoPage,
  pathToTitleMap: [],
  setPathToTitleMap: () => null
});

export const VigoPageProvider = ({ children }: VigoPageProviderProps) => {
  // ** State
  const [vigoPage, setVigoPage] = useState<VigoPage>({ ...initialVigoPage });
  const [pathToTitleMap, setPathToTitleMap] = useState<PathToTitle[]>([initialPathToTitle]);

  const value = {
    VigoPage: vigoPage,
    saveVigoPage: setVigoPage,
    pathToTitleMap,
    setPathToTitleMap
  };

  return <VigoPageContext.Provider value={value}>{children}</VigoPageContext.Provider>;
};
