// ** React Imports
import React, { ElementType, ReactNode } from 'react';

// ** Next Imports
import Link from 'next/link';
import { useRouter } from 'next/router';

// ** MUI Imports
import Chip from '@mui/material/Chip';
import ListItem from '@mui/material/ListItem';
import Box, { BoxProps } from '@mui/material/Box';
import ListItemIcon from '@mui/material/ListItemIcon';
import { styled, useTheme } from '@mui/material/styles';
import ListItemButton, { ListItemButtonProps } from '@mui/material/ListItemButton';

// ** Configs Import
import themeConfig from 'src/configs/themeConfig';

// ** Types
import { NavLink, NavGroup } from 'src/@core/layouts/types';
import { Settings } from 'src/@core/context/settingsContext';

// ** Custom Components Imports
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import UserIcon from 'src/layouts/components/UserIcon';
import CanViewNavLink from 'src/layouts/components/acl/CanViewNavLink';
import { VigoTypography } from 'src/@vigo/vigo-typography';

// ** Utils
import { handleURLQueries } from 'src/@core/layouts/utils';
import { useVigoPage } from 'src/@core/hooks/useVigoPage';
import dynamic from 'next/dynamic';
import { IconButton } from '@mui/material';

interface Props {
  parent?: boolean;
  item: NavLink;
  navHover?: boolean;
  settings: Settings;
  navVisible?: boolean;
  collapsedNavWidth: number;
  navigationBorderWidth: number;
  toggleNavVisibility: () => void;
  isSubToSub?: NavGroup | undefined;
}

// ** Styled Components
const MenuNavLink = styled(ListItemButton)<
  ListItemButtonProps & { component?: ElementType; target?: '_blank' | undefined }
>(({ theme }) => ({
  width: '100%',
  borderTopRightRadius: 5,
  borderBottomRightRadius: 5,
  borderTopLeftRadius: 5,
  borderBottomLeftRadius: 5,
  color: theme.palette.text.primary,
  padding: theme.spacing(1.25, 3.5),
  transition: 'opacity .25s ease-in-out',
  '&.active, &.active:hover': {
    boxShadow: theme.shadows[3],

    backgroundImage: `linear-gradient(98deg, ${theme.palette.customColors.primaryGradient}, ${theme.palette.customColors.primaryGradient} 100%)`
  },
  '&.active .MuiTypography-root, &.active .MuiSvgIcon-root': {
    color: `${theme.palette.common.white} !important`
  }
}));

const MenuItemTextMetaWrapper = styled(Box)<BoxProps>({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  transition: 'opacity .25s ease-in-out',
  ...(themeConfig.menuTextTruncate && { overflow: 'hidden' })
});

const VerticalNavLink = ({
  item,
  parent,
  navHover,
  settings,
  navVisible,
  isSubToSub,
  collapsedNavWidth,
  toggleNavVisibility,
  navigationBorderWidth
}: Props) => {
  // ** Hooks
  const theme = useTheme();
  const router = useRouter();
  const { saveVigoPage } = useVigoPage();

  // ** Vars
  const { skin, navCollapsed } = settings;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const IconTag: ReactNode = parent && !item.icon ? themeConfig.navSubItemIcon : item.icon;

  const icon = React.useMemo(() => {
    if (!item.icon) {
      item.icon = 'AccountCircleOutline';
    }

    // @ts-ignore
    return dynamic(() => import('mdi-material-ui').then(mod => mod[[item.icon]]));
  }, [item]);

  const conditionalBgColor = () => {
    if (skin === 'semi-dark' && theme.palette.mode === 'light') {
      return {
        color: `rgba(${theme.palette.customColors.dark}, 0.87)`,
        '&:hover': {
          backgroundColor: `rgba(${theme.palette.customColors.dark}, 0.04)`
        }
      };
    } else if (skin === 'semi-dark' && theme.palette.mode === 'dark') {
      return {
        color: `rgba(${theme.palette.customColors.light}, 0.87)`,
        '&:hover': {
          backgroundColor: `rgba(${theme.palette.customColors.light}, 0.04)`
        }
      };
    } else return {};
  };

  const isNavLinkActive = () => {
    // fix hilight if routing to UI flow
    if (router.pathname === '/uiflow/[code]/[[...params]]' && router.asPath.split('?')[0] != null) {
      if (router.asPath.split('?')[1] && router.asPath.split('?')[1] === item.path?.split('?')[1]) {
        return true;
      }
    }

    if (router.pathname === item.path || handleURLQueries(router, item.path)) {
      return true;
    } else {
      return false;
    }
  };

  function setPageHeader(item: string) {
    saveVigoPage({
      pageTitle: item,
      pageSubTitle: ''
    });
  }

  return (
    <CanViewNavLink navLink={item}>
      <ListItem
        disablePadding
        className="nav-link"
        disabled={item.disabled || false}
        sx={{ mt: 1.5, ml: '10px', pr: '0 !important' }}>
        <Link passHref href={item.path === undefined ? '/' : `${item.path}`}>
          <MenuNavLink
            component={'a'}
            className={isNavLinkActive() ? 'active' : ''}
            {...(item.openInNewTab ? { target: '_blank' } : null)}
            onClick={e => {
              if (item.path === undefined) {
                e.preventDefault();
                e.stopPropagation();
              }
              if (navVisible) {
                toggleNavVisibility();
              }
            }}
            sx={{
              ...conditionalBgColor(),
              ...(item.disabled ? { pointerEvents: 'none' } : { cursor: 'pointer' }),
              pl: navCollapsed && !navHover ? (collapsedNavWidth - navigationBorderWidth - 24) / 8 : 4.3,
              ml: '0px'
            }}>
            {isSubToSub ? null : (
              <ListItemIcon
                id={item.title}
                sx={{
                  marginLeft: '-15px',
                  color: 'text.primary',
                  transition: 'margin .25s ease-in-out',
                  ...(navCollapsed && !navHover ? { mr: 0 } : { mr: 2.5 }),
                  ...(parent ? { ml: '0px', mr: 1.75 } : {})
                }}>
                <IconButton
                  sx={{
                    fontSize: '1.3rem',
                    ...(!parent ? { fontSize: '1.5rem' } : {}),
                    ...(parent && item.icon ? { fontSize: '1.0rem' } : {})
                  }}>
                  {React.createElement(
                    icon,
                    {
                      //@ts-ignore
                      sx: {
                        fontSize: '1.5rem',
                        ...(!parent ? { fontSize: '2.0rem' } : {}),
                        ...(parent && item.icon ? { fontSize: '1.3rem' } : {})
                      }
                    },
                    null
                  )}
                </IconButton>
              </ListItemIcon>
            )}

            <MenuItemTextMetaWrapper
              onClick={() => {
                setPageHeader(item.title);
              }}
              sx={{
                ...(isSubToSub ? { marginLeft: 9 } : {}),
                ...(navCollapsed && !navHover ? { opacity: 0 } : { opacity: 1 })
              }}>
              <VigoTypography
                value={item.title}
                {...((themeConfig.menuTextTruncate || (!themeConfig.menuTextTruncate && navCollapsed && !navHover)) && {
                  noWrap: true
                })}
              />
              {item.badgeContent ? (
                <Chip
                  label={item.badgeContent}
                  color={item.badgeColor || 'primary'}
                  sx={{
                    height: 20,
                    fontWeight: 500,
                    marginLeft: 1.25,
                    '& .MuiChip-label': { px: 1.5, textTransform: 'capitalize' }
                  }}
                />
              ) : null}
            </MenuItemTextMetaWrapper>
          </MenuNavLink>
        </Link>
      </ListItem>
    </CanViewNavLink>
  );
};

export default VerticalNavLink;
