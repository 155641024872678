// ** MUI Import
import { SxProps, Theme, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

export interface IVigoSpinnerProps {
  customSx?: SxProps<Theme>;
  name: string;
}

const FallbackSpinner = (props: IVigoSpinnerProps) => {
  const { customSx, name } = props;

  const theme = useTheme();

  return (
    <Box
      id={name}
      sx={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        ...customSx
      }}>
      <CircularProgress
        disableShrink
        sx={{ mt: 6, circle: { color: theme.palette.mode === 'light' ? 'black' : 'white' } }}
      />
    </Box>
  );
};

export default FallbackSpinner;
