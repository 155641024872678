import {
  ClientDtoApiResponse,
  GetClientQueryParams,
  GetOwnedSiteQueryParams,
  GetSubAccountUserByidQueryParams,
  GetTenantConfigByidQueryParams,
  GetUserByidQueryParams,
  GetUserQueryParams,
  GetUserStateQueryParams,
  OwnedSiteDto,
  RoleDto,
  TenantConfigDto,
  UserDto,
  UserDtoApiResponse,
  UserListItemDto,
  UserModel,
  UserProfileDto,
  UserProfileDtoApiResponse,
  UserProfileModel,
  UserRolesDto,
  UserRolesDtoApiResponse,
  UserRolesModel,
  UserStateDto
} from 'src/models/identity';
import { GetAxiosInstance } from '../baseService';
import { ServiceLocation } from '../serviceLocationsAxios';
import { GetRoleQueryParams } from 'src/models/account';

// Get all users
export const getAllUsersAsync = async (params?: GetUserQueryParams, partnerId?: string, tenantId?: string) => {
  return GetAxiosInstance(undefined, tenantId, partnerId).get<UserListItemDto[]>(ServiceLocation.identity + '/user', {
    params
  });
};

//get User by UserId
export const getUserByIdAsync = async (
  userId: string,
  params?: GetUserByidQueryParams,
  partnerId?: string,
  ignoreImpersonation?: boolean
) => {
  return GetAxiosInstance(
    undefined,
    undefined,
    partnerId,
    undefined,
    undefined,
    undefined,
    ignoreImpersonation ?? true
  ).get<UserDto>(ServiceLocation.identity + '/User/' + userId, { params });
};

//get UserProfile By UserId
export const getUserProfileByIdAsync = async (userId: string) => {
  return GetAxiosInstance().get<UserProfileDto>(ServiceLocation.identity + '/UserProfile' + '/' + userId);
};

//get UserRole By UserId
export const getUserRoleByUserIdAsync = async (userId: string) => {
  return GetAxiosInstance().get<UserRolesDto>(ServiceLocation.identity + '/user' + '/' + userId + '/roles');
};

//post User
export const postUser = async (model: UserModel) => {
  return GetAxiosInstance().post<UserDtoApiResponse>(ServiceLocation.identity + '/User', model);
};

//put User Profile
export const putUserProfile = async (id: string, model: UserProfileModel) => {
  return GetAxiosInstance().put<UserProfileDtoApiResponse>(
    ServiceLocation.identity + '/User/' + id + '/profile',
    model
  );
};

//GET Roles
export const getRolesAsync = async (
  tenantId?: string,
  partnerId?: string,
  ignoreImpersonation?: boolean,
  params?: GetRoleQueryParams
) => {
  return GetAxiosInstance(undefined, tenantId, partnerId, undefined, undefined, undefined, ignoreImpersonation).get<
    RoleDto[]
  >(ServiceLocation.identity + '/Role', { params });
};

//put Roles
export const putRoles = async (id: string, model: UserRolesModel) => {
  return GetAxiosInstance().put<UserRolesDtoApiResponse>(
    ServiceLocation.identity + '/User' + '/' + id + '/roles',
    model
  );
};

// Put User Profile
export const putUserProfileAsync = async (id: string, userProfile: UserProfileDto) => {
  return GetAxiosInstance().put<UserProfileModel, UserProfileDtoApiResponse>(
    `${ServiceLocation.identity}/User/${id}/profile`,
    userProfile
  );
};

/**
 * Get Tenant Config By Id
 * @param id Tenant Id
 * @param params GetTenantConfigByidQueryParams
 * @returns TenantConfigDto
 */
export const getTenantConfigById = async (id: string, params?: GetTenantConfigByidQueryParams) => {
  return GetAxiosInstance().get<TenantConfigDto>(ServiceLocation.identity + '/TenantConfig/' + id, { params });
};

/**
 * Get Owned Sites
 * @param params GetOwnedSiteQueryParams
 * @returns OwnedSiteDto[]
 */
export const getOwnedSites = async (params?: GetOwnedSiteQueryParams) => {
  return GetAxiosInstance().get<OwnedSiteDto[]>(ServiceLocation.identity + '/OwnedSite', { params });
};

/**
 * Send user invite
 * @param id of the user
 * @returns UserDtoApiResponse
 */
export const sendUserInvite = async (id: string) => {
  return GetAxiosInstance().put<UserDtoApiResponse>(ServiceLocation.identity + '/User/' + id + '/invite');
};

/**
 * Retrieves the user states from the server.
 *
 * @param {GetUserStateQueryParams} params - Optional query parameters for filtering the user states.
 * @return {Promise<UserStateDto[]>} A promise that resolves to an array of user state objects.
 */
export const getUserStates = async (params?: GetUserStateQueryParams) => {
  return GetAxiosInstance().get<UserStateDto[]>(`${ServiceLocation.identity}/UserState`, { params });
};

/**
 * Suspends a user by their ID.
 *
 * @param {string} id - The ID of the user to suspend.
 * @return {Promise<UserDtoApiResponse>} - A promise that resolves with the response from the API.
 */
export const suspendUser = async (id: string) => {
  return GetAxiosInstance().put<UserDtoApiResponse>(`${ServiceLocation.identity}/User/${id}/suspend`);
};

/**
 * Activates a user by sending a PUT request to the server.
 *
 * @param {string} id - The ID of the user to reactivate.
 * @return {Promise<UserDtoApiResponse>} - A promise that resolves to the response from the server.
 */
export const reactivateUser = async (id: string) => {
  return GetAxiosInstance().put<UserDtoApiResponse>(`${ServiceLocation.identity}/User/${id}/reactivate`);
};

/**
 * Resets the password for a user with the specified ID.
 *
 * @param {string} id - The ID of the user.
 * @return {Promise<UserDtoApiResponse>} - A promise that resolves to the response from the server.
 */
export const resetPassword = async (id: string) => {
  return GetAxiosInstance().put<UserDtoApiResponse>(`${ServiceLocation.identity}/User/${id}/resetPassword`);
};

/**
 * Get Clients
 */
export const getClients = (params?: GetClientQueryParams) => {
  return GetAxiosInstance().get<ClientDtoApiResponse>(`${ServiceLocation.identity}/Client`, { params });
};

/**
 * Get User Licences
 * @param {string} id - The ID of the user.
 * @param {{ subscriptions: string[] }} data
 * @return {Promise<UserDtoApiResponse>} - A promise that resolves to the response from the server.
 * */
export const putUserLicences = async (id: string, data: { subscriptions: string[] }) => {
  return GetAxiosInstance().put<UserDtoApiResponse>(`${ServiceLocation.identity}/User/${id}/licences`, data);
};

/**
 * Get Sub Account User
 * @param id Id of the user
 * @param params GetSubAccountUserByidQueryParams
 * @returns UserDto
 */
export const getSubAccountUser = (id: string, params?: GetSubAccountUserByidQueryParams) => {
  return GetAxiosInstance().get<UserDto>(ServiceLocation.identity + '/SubAccountUser/' + id, { params });
};
