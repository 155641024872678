import { Box, Stack, useTheme } from '@mui/material';
import UserAvatar from './UserAvatar';
import { VigoTypography } from 'src/@vigo/vigo-typography';
import { lighten } from '@mui/material/styles';
import { UserDto } from 'src/models/identity';

interface ProfileHeaderProps {
  userData?: UserDto;
  company?: string;
}

const ProfileHeader = ({ userData, company }: ProfileHeaderProps) => {
  const theme = useTheme();

  const { forname, surname, email } = userData?.userProfile ?? {};
  const fullName = `${forname ?? ''} ${surname ?? ''}`;

  return (
    <Box sx={{ padding: '20px 20px 16px 20px' }}>
      <Stack direction="row" gap="20px">
        <UserAvatar
          userProfile={userData?.userProfile ?? {}}
          sx={{ width: '46px', height: '46px', fontSize: '1.643rem' }}
        />

        <Stack direction="column">
          <VigoTypography
            value={fullName}
            disableTranslation
            sx={{
              fontSize: '1.429rem',
              color: theme.palette.mode === 'light' ? '#2B2D42' : '#fff',
              fontWeight: '600'
            }}
          />
          <VigoTypography
            value={email}
            disableTranslation
            sx={{ fontSize: '1rem', color: lighten('#626679', 0.5), fontWeight: '600' }}
          />
          <VigoTypography
            value={company}
            disableTranslation
            sx={{
              marginTop: '10px',
              fontSize: '1rem',
              color: theme.palette.mode === 'light' ? '#2B2D42' : '#fff',
              fontWeight: '600',
              textTransform: 'capitalize'
            }}
          />
        </Stack>
      </Stack>
    </Box>
  );
};

export default ProfileHeader;
