import { SyntheticEvent, useState } from 'react';
import { UserDto } from 'src/models/identity';
import AppbarIcon from './components/AppbarIcon';
import UserProfileMenu from './components/UserProfileMenu';

interface UserProfileProps {
  userData?: UserDto;
  company?: string;
}

const UserProfile = ({ userData, company }: UserProfileProps) => {
  const [anchorEl, setAnchorEl] = useState<(EventTarget & Element) | null>(null);

  /**
   * Handles the event when the dropdown is opened.
   *
   * @param {SyntheticEvent} event - The event object.
   * @return {void} This function does not return anything.
   */
  const handleDropdownOpen = (event: SyntheticEvent): void => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * Handles the event when the dropdown is closed.
   *
   * @param {void} - This function does not take any parameters.
   * @return {void} - This function does not return any value.
   */
  const handleDropdownClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <>
      <AppbarIcon userData={userData} handleClick={handleDropdownOpen} />
      <UserProfileMenu userData={userData} company={company} anchorEl={anchorEl} handleClose={handleDropdownClose} />
    </>
  );
};

export default UserProfile;
