/* eslint-disable @typescript-eslint/no-explicit-any */
import Axios, { AxiosRequestConfig, AxiosResponse, AxiosInstance } from 'axios';
import qs from 'qs';
import { User } from 'oidc-client-ts';

import { v4 as uuidv4 } from 'uuid';

//import for runtime config
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

const baseConfig: AxiosRequestConfig = {
  baseURL: publicRuntimeConfig.BaseAPIUri,
  paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
  headers: {
    'content-Type': 'application/json-patch+json'
  }
};

// needs to be fixed
function getUser() {
  const oidcStorage = sessionStorage.getItem(
    'oidc.user:' + publicRuntimeConfig.Authority + ':' + publicRuntimeConfig.ClientId
  );
  if (!oidcStorage) {
    return null;
  }

  return User.fromStorageString(oidcStorage);
}

const GetAxiosInstance = (
  axiosInstance?: AxiosInstance,
  tenantId?: string,
  partnerId?: string,
  source?: string,
  responseType?: string,
  customTimeout?: number,
  ignoreImpersonation?: boolean,
  contentType?: string
) => {
  if (!axiosInstance) {
    axiosInstance = Axios.create(baseConfig);
  }

  if (!ignoreImpersonation) {
    ignoreImpersonation = false;
  }

  axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    err => {
      const status = err.response?.status || 500;
      switch (status) {
        case 401: {
          return Promise.reject(new APIError('You do not have permision to access entity', 401, err.response));
        }

        // forbidden (permission related issues)
        case 403: {
          return Promise.reject(new APIError('You do not have permision to access entity', 403, err.response));
        }

        // bad request
        case 400: {
          return Promise.reject(new APIError(err.message, 400, err.response));
        }

        // not found
        case 404: {
          return Promise.reject(new APIError(err.message, 404, err.response));
        }

        // conflict
        case 409: {
          return Promise.reject(new APIError(err.message, 409, err.response));
        }

        // unprocessable
        case 422: {
          return Promise.reject(new APIError(err.message, 422, err.response));
        }

        // generic api error (server related) unexpected
        default: {
          return Promise.reject(new APIError(err.message, 500, err.response));
        }
      }
    }
  );

  // ًًRequest interceptor
  axiosInstance.interceptors.request.use(
    async config => {
      const requestId = uuidv4();
      const user = getUser();
      const token = user?.access_token;
      config.headers.set('Authorization', 'Bearer ' + token);
      config.headers.set('Accept', 'application/json');
      config.headers.set('content-Type', contentType ?? 'application/json-patch+json');
      config.headers.set('x-requestId', requestId);

      // optionally overide the tenantId if we are impersonating a different tenant
      if (tenantId) {
        config.headers.set('x-tenantId', tenantId);
      }

      // optionally overide the partnerId if we are impersonating a different tenant
      if (partnerId) {
        config.headers.set('x-partnerId', partnerId);
      }

      if (source) {
        config.headers.set('x-source', source);
      }

      if (customTimeout) {
        config.timeout = customTimeout;
      }

      // optionally overide the response type if provided
      if (responseType) {
        config.responseType = responseType as any;
      }

      const loggedInUserId = user?.profile.tenantUserId as string;
      // check if we are already impersonating a tenant
      const fromSession =
        typeof window !== 'undefined' && window.sessionStorage.getItem(`impersonate-${loggedInUserId}`);
      if (fromSession && !ignoreImpersonation) {
        if (user?.profile.product === 'fusion') {
          !tenantId && config.headers.set('x-tenantId', JSON.parse(fromSession).tenantId);
          !partnerId && config.headers.set('x-partnerId', JSON.parse(fromSession).partnerId);
        }
        if (user?.profile.product === 'flow') {
          !partnerId && config.headers.set('x-partnerId', JSON.parse(fromSession).partnerId);
        }
      }

      return config;
    },
    error => {
      Promise.reject(error);
    }
  );

  return axiosInstance;
};

class APIError extends Error {
  constructor(public message: string, public status?: number, public response?: AxiosResponse, public data?: any) {
    super(message);
  }

  isApiException = true;
}

export { GetAxiosInstance, APIError };
