import mock from 'src/@fake-db/mock';

export const searchResults = [
  {
    Id: 'GB|TS|A|1605',
    Type: 'Address',
    Text: 'Baxter Building, 0 Gravelpit Close',
    Highlight: '0-15',
    Description: 'L9 5AS, Metropolis Herefordshire L9 5AS'
  },
  {
    Id: 'GB|TS|A|1610',
    Type: 'Address',
    Text: 'Baxter Building, 0 Evergreen Gardens',
    Highlight: '0-15',
    Description: 'E20 2ST, Metropolis Herefordshire E20 2ST'
  },
  {
    Id: 'GB|TS|A|1616',
    Type: 'Address',
    Text: 'Baxter Building, 0 Windsor Street',
    Highlight: '0-15',
    Description: 'HA9 0WS, Gotham City Gloucestershire HA9 0WS'
  },
  {
    Id: 'GB|TS|A|1619',
    Type: 'Address',
    Text: 'Baxter Building, 0 Spooner Street',
    Highlight: '0-15',
    Description: 'M16 0RA, Quahog West Midlands M16 0RA'
  },
  {
    Id: 'GB|TS|A|1625',
    Type: 'Address',
    Text: 'Baxter Building, 0 Windsor Street',
    Highlight: '0-15',
    Description: 'M10 9AA, Metropolis Gloucestershire M10 9KC'
  },
  {
    Id: 'GB|TS|A|1630',
    Type: 'Address',
    Text: 'Baxter Building, 0 Evergreen Croft',
    Highlight: '0-15',
    Description: 'M16 0RA, Gotham City West Midlands M16 0RA'
  },
  {
    Id: 'GB|TS|A|1632',
    Type: 'Address',
    Text: 'Baxter Building, 0 Privet Street',
    Highlight: '0-15',
    Description: 'WR53DA, Quahog Gloucestershire WR53DA'
  },
  {
    Id: 'GB|TS|A|1646',
    Type: 'Address',
    Text: 'Baxter Building, 0 Evergreen Grove',
    Highlight: '0-15',
    Description: 'SW1A 2AA, Springfield Herefordshire SW1A 2AA'
  },
  {
    Id: 'GB|TS|A|1652',
    Type: 'Address',
    Text: 'Baxter Building, 0 Evergreen Road',
    Highlight: '0-15',
    Description: 'M16 0RA, Springfield Gloucestershire M16 0RA'
  },
  {
    Id: 'GB|TS|A|1673',
    Type: 'Address',
    Text: 'Baxter Building, 0 Sulivan Croft',
    Highlight: '0-15',
    Description: 'WR53DA, Calisota Worcestershire WR53DA'
  }
].map(result => ({
  ...result,
  id: result.Id,
  description: `${result.Text} ${result.Description}`
}));

export const geo_codes = [
  { Name: 'L9 5AS', Latitude: 53.475027, Longitude: -2.952388 },
  { Name: 'E20 2ST', Latitude: 51.538636, Longitude: -0.016403 },
  { Name: 'HA9 0WS', Latitude: 51.556692, Longitude: -0.279666 },
  { Name: 'M16 0RA', Latitude: 53.462559, Longitude: -2.291032 },
  { Name: 'M10 9AA', Latitude: 53.496518, Longitude: -2.209488 },
  { Name: 'M16 0RA', Latitude: 53.462559, Longitude: -2.291032 },
  { Name: 'WR53DA', Latitude: 52.182961, Longitude: -2.221667 },
  { Name: 'SW1A 2AA', Latitude: 51.50354, Longitude: -0.127695 },
  { Name: 'M16 0RA', Latitude: 53.462559, Longitude: -2.291032 },
  { Name: 'WR53DA', Latitude: 52.182961, Longitude: -2.221667 }
];

export const addresses = [
  {
    Id: 'GB|TS|A|1605',
    DomesticId: '',
    Language: 'GBR',
    LanguageAlternatives: 'GBR',
    Department: '',
    Company: 'Loqate',
    SubBuilding: '',
    BuildingNumber: '0',
    BuildingName: 'Baxter Building',
    SecondaryStreet: '',
    Street: 'Gravelpit Close',
    Block: '',
    Neighbourhood: '',
    District: '',
    City: 'Metropolis',
    Line1: 'Baxter Building',
    Line2: '0 Gravelpit Close',
    Line3: '',
    Line4: '',
    Line5: '',
    AdminAreaName: 'Brumersfield',
    AdminAreaCode: '',
    Province: 'Herefordshire',
    ProvinceName: 'Herefordshire',
    ProvinceCode: '',
    PostalCode: 'L9 5AS',
    CountryName: 'United Kingdom',
    CountryIso2: 'GB',
    CountryIso3: 'GBR',
    CountryIsoNumber: 'GBR',
    SortingNumber1: '',
    SortingNumber2: '',
    Barcode: '',
    POBoxNumber: '',
    Label: 'Loqate\nBaxter Building\n0 Gravelpit Close \nMETROPOLIS\nL9 5AS\nUNITED KINGDOM',
    Type: 'Commercial',
    DataLevel: 'Premise',
    Field1: '',
    Field2: '',
    Field3: '',
    Field4: '',
    Field5: '',
    Field6: '',
    Field7: '',
    Field8: '',
    Field9: '',
    Field10: '',
    Field11: '',
    Field12: '',
    Field13: '',
    Field14: '',
    Field15: '',
    Field16: '',
    Field17: '',
    Field18: '',
    Field19: '',
    Field20: ''
  },
  {
    Id: 'GB|TS|A|1610',
    DomesticId: '',
    Language: 'GBR',
    LanguageAlternatives: 'GBR',
    Department: '',
    Company: 'Test Solutions',
    SubBuilding: '',
    BuildingNumber: '0',
    BuildingName: 'Baxter Building',
    SecondaryStreet: '',
    Street: 'Evergreen Gardens',
    Block: '',
    Neighbourhood: '',
    District: '',
    City: 'Metropolis',
    Line1: 'Baxter Building',
    Line2: '0 Evergreen Gardens',
    Line3: '',
    Line4: '',
    Line5: '',
    AdminAreaName: 'Nearsbridge',
    AdminAreaCode: '',
    Province: 'Herefordshire',
    ProvinceName: 'Herefordshire',
    ProvinceCode: '',
    PostalCode: 'E20 2ST',
    CountryName: 'United Kingdom',
    CountryIso2: 'GB',
    CountryIso3: 'GBR',
    CountryIsoNumber: 'GBR',
    SortingNumber1: '',
    SortingNumber2: '',
    Barcode: '',
    POBoxNumber: '',
    Label: 'Test Solutions\nBaxter Building\n0 Evergreen Gardens \nMETROPOLIS\nE20 2ST\nUNITED KINGDOM',
    Type: 'Commercial',
    DataLevel: 'Premise',
    Field1: '',
    Field2: '',
    Field3: '',
    Field4: '',
    Field5: '',
    Field6: '',
    Field7: '',
    Field8: '',
    Field9: '',
    Field10: '',
    Field11: '',
    Field12: '',
    Field13: '',
    Field14: '',
    Field15: '',
    Field16: '',
    Field17: '',
    Field18: '',
    Field19: '',
    Field20: ''
  },
  {
    Id: 'GB|TS|A|1616',
    DomesticId: '',
    Language: 'GBR',
    LanguageAlternatives: 'GBR',
    Department: '',
    Company: 'PCA Predict',
    SubBuilding: '',
    BuildingNumber: '0',
    BuildingName: 'Baxter Building',
    SecondaryStreet: '',
    Street: 'Windsor Street',
    Block: '',
    Neighbourhood: '',
    District: '',
    City: 'Gotham City',
    Line1: 'Baxter Building',
    Line2: '0 Windsor Street',
    Line3: '',
    Line4: '',
    Line5: '',
    AdminAreaName: 'Tetchford',
    AdminAreaCode: '',
    Province: 'Gloucestershire',
    ProvinceName: 'Gloucestershire',
    ProvinceCode: '',
    PostalCode: 'HA9 0WS',
    CountryName: 'United Kingdom',
    CountryIso2: 'GB',
    CountryIso3: 'GBR',
    CountryIsoNumber: 'GBR',
    SortingNumber1: '',
    SortingNumber2: '',
    Barcode: '',
    POBoxNumber: '',
    Label: 'PCA Predict\nBaxter Building\n0 Windsor Street \nGOTHAM CITY\nHA9 0WS\nUNITED KINGDOM',
    Type: 'Commercial',
    DataLevel: 'Premise',
    Field1: '',
    Field2: '',
    Field3: '',
    Field4: '',
    Field5: '',
    Field6: '',
    Field7: '',
    Field8: '',
    Field9: '',
    Field10: '',
    Field11: '',
    Field12: '',
    Field13: '',
    Field14: '',
    Field15: '',
    Field16: '',
    Field17: '',
    Field18: '',
    Field19: '',
    Field20: ''
  },
  {
    Id: 'GB|TS|A|1619',
    DomesticId: '',
    Language: 'GBR',
    LanguageAlternatives: 'GBR',
    Department: '',
    Company: 'Test Solutions',
    SubBuilding: '',
    BuildingNumber: '0',
    BuildingName: 'Baxter Building',
    SecondaryStreet: '',
    Street: 'Spooner Street',
    Block: '',
    Neighbourhood: '',
    District: '',
    City: 'Quahog',
    Line1: 'Baxter Building',
    Line2: '0 Spooner Street',
    Line3: '',
    Line4: '',
    Line5: '',
    AdminAreaName: 'Brumersfield',
    AdminAreaCode: '',
    Province: 'West Midlands',
    ProvinceName: 'West Midlands',
    ProvinceCode: '',
    PostalCode: 'M16 0RA',
    CountryName: 'United Kingdom',
    CountryIso2: 'GB',
    CountryIso3: 'GBR',
    CountryIsoNumber: 'GBR',
    SortingNumber1: '',
    SortingNumber2: '',
    Barcode: '',
    POBoxNumber: '',
    Label: 'Test Solutions\nBaxter Building\n0 Spooner Street \nQUAHOG\nM16 0RA\nUNITED KINGDOM',
    Type: 'Commercial',
    DataLevel: 'Premise',
    Field1: '',
    Field2: '',
    Field3: '',
    Field4: '',
    Field5: '',
    Field6: '',
    Field7: '',
    Field8: '',
    Field9: '',
    Field10: '',
    Field11: '',
    Field12: '',
    Field13: '',
    Field14: '',
    Field15: '',
    Field16: '',
    Field17: '',
    Field18: '',
    Field19: '',
    Field20: ''
  },
  {
    Id: 'GB|TS|A|1625',
    DomesticId: '',
    Language: 'GBR',
    LanguageAlternatives: 'GBR',
    Department: '',
    Company: 'PCA Predict',
    SubBuilding: '',
    BuildingNumber: '0',
    BuildingName: 'Baxter Building',
    SecondaryStreet: '',
    Street: 'Windsor Street',
    Block: '',
    Neighbourhood: '',
    District: '',
    City: 'Metropolis',
    Line1: 'Baxter Building',
    Line2: '0 Windsor Street',
    Line3: '',
    Line4: '',
    Line5: '',
    AdminAreaName: 'Tetchford',
    AdminAreaCode: '',
    Province: 'Gloucestershire',
    ProvinceName: 'Gloucestershire',
    ProvinceCode: '',
    PostalCode: 'M10 9KC',
    CountryName: 'United Kingdom',
    CountryIso2: 'GB',
    CountryIso3: 'GBR',
    CountryIsoNumber: 'GBR',
    SortingNumber1: '',
    SortingNumber2: '',
    Barcode: '',
    POBoxNumber: '',
    Label: 'PCA Predict\nBaxter Building\n0 Windsor Street \nMETROPOLIS\nM10 9KC\nUNITED KINGDOM',
    Type: 'Commercial',
    DataLevel: 'Premise',
    Field1: '',
    Field2: '',
    Field3: '',
    Field4: '',
    Field5: '',
    Field6: '',
    Field7: '',
    Field8: '',
    Field9: '',
    Field10: '',
    Field11: '',
    Field12: '',
    Field13: '',
    Field14: '',
    Field15: '',
    Field16: '',
    Field17: '',
    Field18: '',
    Field19: '',
    Field20: ''
  },
  {
    Id: 'GB|TS|A|1630',
    DomesticId: '',
    Language: 'GBR',
    LanguageAlternatives: 'GBR',
    Department: '',
    Company: 'Test Solutions',
    SubBuilding: '',
    BuildingNumber: '0',
    BuildingName: 'Baxter Building',
    SecondaryStreet: '',
    Street: 'Evergreen Croft',
    Block: '',
    Neighbourhood: '',
    District: '',
    City: 'Gotham City',
    Line1: 'Baxter Building',
    Line2: '0 Evergreen Croft',
    Line3: '',
    Line4: '',
    Line5: '',
    AdminAreaName: 'Cornsaw',
    AdminAreaCode: '',
    Province: 'West Midlands',
    ProvinceName: 'West Midlands',
    ProvinceCode: '',
    PostalCode: 'M16 0RA',
    CountryName: 'United Kingdom',
    CountryIso2: 'GB',
    CountryIso3: 'GBR',
    CountryIsoNumber: 'GBR',
    SortingNumber1: '',
    SortingNumber2: '',
    Barcode: '',
    POBoxNumber: '',
    Label: 'Test Solutions\nBaxter Building\n0 Evergreen Croft \nGOTHAM CITY\nM16 0RA\nUNITED KINGDOM',
    Type: 'Commercial',
    DataLevel: 'Premise',
    Field1: '',
    Field2: '',
    Field3: '',
    Field4: '',
    Field5: '',
    Field6: '',
    Field7: '',
    Field8: '',
    Field9: '',
    Field10: '',
    Field11: '',
    Field12: '',
    Field13: '',
    Field14: '',
    Field15: '',
    Field16: '',
    Field17: '',
    Field18: '',
    Field19: '',
    Field20: ''
  },
  {
    Id: 'GB|TS|A|1632',
    DomesticId: '',
    Language: 'GBR',
    LanguageAlternatives: 'GBR',
    Department: '',
    Company: 'GBG Company',
    SubBuilding: '',
    BuildingNumber: '0',
    BuildingName: 'Baxter Building',
    SecondaryStreet: '',
    Street: 'Privet Street',
    Block: '',
    Neighbourhood: '',
    District: '',
    City: 'Quahog',
    Line1: 'Baxter Building',
    Line2: '0 Privet Street',
    Line3: '',
    Line4: '',
    Line5: '',
    AdminAreaName: 'Covendale',
    AdminAreaCode: '',
    Province: 'Gloucestershire',
    ProvinceName: 'Gloucestershire',
    ProvinceCode: '',
    PostalCode: 'WR53DA',
    CountryName: 'United Kingdom',
    CountryIso2: 'GB',
    CountryIso3: 'GBR',
    CountryIsoNumber: 'GBR',
    SortingNumber1: '',
    SortingNumber2: '',
    Barcode: '',
    POBoxNumber: '',
    Label: 'GBG Company\nBaxter Building\n0 Privet Street \nQUAHOG\nWR53DA\nUNITED KINGDOM',
    Type: 'Commercial',
    DataLevel: 'Premise',
    Field1: '',
    Field2: '',
    Field3: '',
    Field4: '',
    Field5: '',
    Field6: '',
    Field7: '',
    Field8: '',
    Field9: '',
    Field10: '',
    Field11: '',
    Field12: '',
    Field13: '',
    Field14: '',
    Field15: '',
    Field16: '',
    Field17: '',
    Field18: '',
    Field19: '',
    Field20: ''
  },
  {
    Id: 'GB|TS|A|1646',
    DomesticId: '',
    Language: 'GBR',
    LanguageAlternatives: 'GBR',
    Department: '',
    Company: 'Test Company Inc',
    SubBuilding: '',
    BuildingNumber: '0',
    BuildingName: 'Baxter Building',
    SecondaryStreet: '',
    Street: 'Evergreen Grove',
    Block: '',
    Neighbourhood: '',
    District: '',
    City: 'Springfield',
    Line1: 'Baxter Building',
    Line2: '0 Evergreen Grove',
    Line3: '',
    Line4: '',
    Line5: '',
    AdminAreaName: 'Brumersfield',
    AdminAreaCode: '',
    Province: 'Herefordshire',
    ProvinceName: 'Herefordshire',
    ProvinceCode: '',
    PostalCode: 'SW1A 2AA',
    CountryName: 'United Kingdom',
    CountryIso2: 'GB',
    CountryIso3: 'GBR',
    CountryIsoNumber: 'GBR',
    SortingNumber1: '',
    SortingNumber2: '',
    Barcode: '',
    POBoxNumber: '',
    Label: 'Test Company Inc\nBaxter Building\n0 Evergreen Grove \nSPRINGFIELD\nSW1A 2AA\nUNITED KINGDOM',
    Type: 'Commercial',
    DataLevel: 'Premise',
    Field1: '',
    Field2: '',
    Field3: '',
    Field4: '',
    Field5: '',
    Field6: '',
    Field7: '',
    Field8: '',
    Field9: '',
    Field10: '',
    Field11: '',
    Field12: '',
    Field13: '',
    Field14: '',
    Field15: '',
    Field16: '',
    Field17: '',
    Field18: '',
    Field19: '',
    Field20: ''
  },
  {
    Id: 'GB|TS|A|1652',
    DomesticId: '',
    Language: 'GBR',
    LanguageAlternatives: 'GBR',
    Department: '',
    Company: 'GBG Company',
    SubBuilding: '',
    BuildingNumber: '0',
    BuildingName: 'Baxter Building',
    SecondaryStreet: '',
    Street: 'Evergreen Road',
    Block: '',
    Neighbourhood: '',
    District: '',
    City: 'Springfield',
    Line1: 'Baxter Building',
    Line2: '0 Evergreen Road',
    Line3: '',
    Line4: '',
    Line5: '',
    AdminAreaName: 'Nearsbridge',
    AdminAreaCode: '',
    Province: 'Gloucestershire',
    ProvinceName: 'Gloucestershire',
    ProvinceCode: '',
    PostalCode: 'M16 0RA',
    CountryName: 'United Kingdom',
    CountryIso2: 'GB',
    CountryIso3: 'GBR',
    CountryIsoNumber: 'GBR',
    SortingNumber1: '',
    SortingNumber2: '',
    Barcode: '',
    POBoxNumber: '',
    Label: 'GBG Company\nBaxter Building\n0 Evergreen Road \nSPRINGFIELD\nM16 0RA\nUNITED KINGDOM',
    Type: 'Commercial',
    DataLevel: 'Premise',
    Field1: '',
    Field2: '',
    Field3: '',
    Field4: '',
    Field5: '',
    Field6: '',
    Field7: '',
    Field8: '',
    Field9: '',
    Field10: '',
    Field11: '',
    Field12: '',
    Field13: '',
    Field14: '',
    Field15: '',
    Field16: '',
    Field17: '',
    Field18: '',
    Field19: '',
    Field20: ''
  },
  {
    Id: 'GB|TS|A|1673',
    DomesticId: '',
    Language: 'GBR',
    LanguageAlternatives: 'GBR',
    Department: '',
    Company: 'Test Company Inc',
    SubBuilding: '',
    BuildingNumber: '0',
    BuildingName: 'Baxter Building',
    SecondaryStreet: '',
    Street: 'Sulivan Croft',
    Block: '',
    Neighbourhood: '',
    District: '',
    City: 'Calisota',
    Line1: 'Baxter Building',
    Line2: '0 Sulivan Croft',
    Line3: '',
    Line4: '',
    Line5: '',
    AdminAreaName: 'Golten',
    AdminAreaCode: '',
    Province: 'Worcestershire',
    ProvinceName: 'Worcestershire',
    ProvinceCode: '',
    PostalCode: 'WR53DA',
    CountryName: 'United Kingdom',
    CountryIso2: 'GB',
    CountryIso3: 'GBR',
    CountryIsoNumber: 'GBR',
    SortingNumber1: '',
    SortingNumber2: '',
    Barcode: '',
    POBoxNumber: '',
    Label: 'Test Company Inc\nBaxter Building\n0 Sulivan Croft \nCALISOTA\nWR53DA\nUNITED KINGDOM',
    Type: 'Commercial',
    DataLevel: 'Premise',
    Field1: '',
    Field2: '',
    Field3: '',
    Field4: '',
    Field5: '',
    Field6: '',
    Field7: '',
    Field8: '',
    Field9: '',
    Field10: '',
    Field11: '',
    Field12: '',
    Field13: '',
    Field14: '',
    Field15: '',
    Field16: '',
    Field17: '',
    Field18: '',
    Field19: '',
    Field20: ''
  }
];

mock.onGet(/\/addressService\/api\/v1\/search/i).reply(200, searchResults);

mock
  .onGet(`/addressService/api/v1/retrieve?id=GB|TS|A|1605`)
  .reply(200, { geocode: geo_codes[0], address: addresses[0] }),
  mock
    .onGet(`/addressService/api/v1/retrieve?id=GB|TS|A|1610`)
    .reply(200, { geocode: geo_codes[1], address: addresses[1] }),
  mock
    .onGet(`/addressService/api/v1/retrieve?id=GB|TS|A|1616`)
    .reply(200, { geocode: geo_codes[2], address: addresses[2] }),
  mock
    .onGet(`/addressService/api/v1/retrieve?id=GB|TS|A|1619`)
    .reply(200, { geocode: geo_codes[3], address: addresses[3] }),
  mock
    .onGet(`/addressService/api/v1/retrieve?id=GB|TS|A|1625`)
    .reply(200, { geocode: geo_codes[4], address: addresses[4] }),
  mock
    .onGet(`/addressService/api/v1/retrieve?id=GB|TS|A|1630`)
    .reply(200, { geocode: geo_codes[5], address: addresses[5] }),
  mock
    .onGet(`/addressService/api/v1/retrieve?id=GB|TS|A|1632`)
    .reply(200, { geocode: geo_codes[6], address: addresses[6] }),
  mock
    .onGet(`/addressService/api/v1/retrieve?id=GB|TS|A|1646`)
    .reply(200, { geocode: geo_codes[7], address: addresses[7] }),
  mock
    .onGet(`/addressService/api/v1/retrieve?id=GB|TS|A|1652`)
    .reply(200, { geocode: geo_codes[8], address: addresses[8] }),
  mock
    .onGet(`/addressService/api/v1/retrieve?id=GB|TS|A|1673`)
    .reply(200, { geocode: geo_codes[9], address: addresses[9] });

// mock.onGet(/\/addressService\/api\/v1\/retrieve/i, { params: { id: 'GB|TS|A|1605' } }).reply(200, addresses[0]);
