import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { User } from 'oidc-client-ts';

export interface AuthSliceDataState {
  user: Partial<User>;
}

const initialState: AuthSliceDataState = {
  user: {}
};

export const authDataSlice = createSlice({
  name: 'authData',
  initialState: initialState,
  reducers: {
    setAuthData: (state, action: PayloadAction<Partial<User>>) => {
      state.user = action.payload;
    }
  }
});

export const { setAuthData } = authDataSlice.actions;

export default authDataSlice.reducer;
