/**
 * Vigo Custom Event
 *
 * - Send CustomEvents to be consumed by components throughout the application.
 *
 */

export type VigoEventName = string;
export type VigoListener = (event: CustomEvent) => void;

/**
 * Subscribe to an event.
 * - Returns a function to unsubscribe.
 *
 * @param eventName
 * @param listener
 * @returns unsubscribe function
 */
export const subscribe = (eventName: VigoEventName, listener: VigoListener) => {
  document.addEventListener(eventName, listener as (e: Event) => void);

  return () => unsubscribe(eventName, listener);
};

/**
 * Unsubscribe from an event.
 *
 * @param eventName
 * @param listener
 */
export const unsubscribe = (eventName: VigoEventName, listener: VigoListener): void => {
  document.removeEventListener(eventName, listener as (e: Event) => void);
};

/**
 * Publish an event.
 *
 * @param eventName
 * @param data
 */
export const publish = <T>(eventName: VigoEventName, data: T): void => {
  const event = new CustomEvent<T>(eventName, { detail: data });

  document.dispatchEvent(event);
};
