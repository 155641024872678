import { useAuth } from 'react-oidc-context';
import jwt_decode from 'jwt-decode';

export interface AccessToken {
  iss?: string;
  nbf?: number;
  iat?: number;
  exp?: number;
  aud?: string;
  scope?: string[];
  amr?: string[];
  client_id?: string;
  sub?: string;
  auth_time?: number;
  idp?: string;
  tenantId?: string;
  partnerId?: string;
  tenantUserId?: string;
  defaultRole?: string;
  defaultRoleIsAdmin?: boolean;
  subDomain?: string;
  company?: string;
  product?: string;
  allowedProducts?: string | string[];
  permission?: string[];
  sid?: string;
  jti?: string;
}

/**
 * Returns the decoded access token from the user's authentication.
 *
 * @returns {object} The decoded access token object.
 */
const useAccessToken = (): AccessToken => {
  const auth = useAuth();

  const token = auth?.user?.access_token ?? '';

  if (!token) return {};

  const decoded = jwt_decode(token) as AccessToken;

  return decoded ?? {};
};

export default useAccessToken;
