import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper, { PopperProps } from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { SxProps, Theme, useTheme } from '@mui/material';
import isClient from 'src/@core/utils/isClient';
import { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsMutating } from '@tanstack/react-query';
import { useAppSelector } from 'src/store/baseHooks';
import { ModeValue } from 'src/types/uiflow/uiflow-types';

export interface ButtonDefinition {
  name: string;
  action: () => void;
  disabled: boolean;
  hidden?: boolean;
}

interface VigoMultiButtonProps {
  buttons?: ButtonDefinition[];
  variant?: 'text' | 'outlined' | 'contained';
  sx?: SxProps;
  size?: 'small' | 'medium' | 'large';
  enableSelectionPersist?: boolean;
  selectedButtonSx?: SxProps;
  popperProps?: Partial<PopperProps>;
  fireActionFromMenu?: boolean;
  disabled: boolean;
}

export const MULTI_BUTTON_PREFIX = 'vigo-multi-button-';

const trimUrl = (url: string) => {
  // Regular expression to match a GUID in the URL, with an optional trailing slash
  const guidRegex = /\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\/?/;

  // Attempt to split the URL by the GUID regex to separate the base URL
  const parts = url.split(guidRegex);

  // Only append a slash if the GUID was actually found and removed
  if (url !== parts[0]) {
    url = parts[0] + '/';
  } else {
    url = parts[0];
  }

  // Find the index of the start of query parameters
  const queryParamIndex = url.indexOf('?');
  // Trim query parameters if present
  if (queryParamIndex !== -1) {
    url = url.substring(0, queryParamIndex);
  }

  return url;
};

/**
 * Sets the specified value in the local storage.
 *
 * @param value - The value to be stored in the local storage.
 */
const setValueInStorage = (value: number, mode: ModeValue) => {
  if (isClient) {
    const storage = window.localStorage;
    const location = window.location.href ?? '';

    const formattedUrl = trimUrl(location);

    try {
      storage.setItem(`${MULTI_BUTTON_PREFIX}${mode}-${formattedUrl}`, value.toString());
    } catch (e) {
      console.error('Failed to set value in storage');
    }
  }
};

/**
 * Retrieves a value from the local storage based on the current URL.
 * If the value exists, it is parsed as an integer and returned.
 * If the value does not exist or cannot be parsed, 0 is returned.
 *
 * @returns The value from the local storage, or 0 if it does not exist or cannot be parsed.
 */
const getValueFromStorage = (mode: ModeValue) => {
  if (isClient) {
    const storage = window.localStorage;
    const location = window.location.href ?? '';

    const formattedUrl = trimUrl(location);

    const value = storage.getItem(`${MULTI_BUTTON_PREFIX}${mode}-${formattedUrl}`);

    return value ? parseInt(value) : 0;
  }

  return 0;
};

/**
 * VigoMultiButton component represents a group of buttons with a dropdown menu.
 *
 * @component
 * @example
 * ```tsx
 * <VigoMultiButton
 *   buttons={[
 *     { name: 'Button 1', action: () => console.log('Button 1 clicked') },
 *     { name: 'Button 2', action: () => console.log('Button 2 clicked') },
 *     { name: 'Button 3', action: () => console.log('Button 3 clicked') },
 *   ]}
 *   variant="contained"
 *   size="medium"
 *   enableSelectionPersist={false}
 * />
 * ```
 *
 * @param buttons - An array of objects representing the buttons in the group.
 * @param variant - The variant of the buttons (e.g., 'contained', 'outlined', 'text').
 * @param sx - Additional styles to apply to the component.
 * @param size - The size of the buttons (e.g., 'small', 'medium', 'large').
 * @param enableSelectionPersist - Whether to persist the selected button index in local storage.
 * @param selectedButtonSx - Additional styles to apply to the selected button.
 * @param popperProps - Additional props to apply to the Popper component.
 * @param fireActionFromMenu - Whether to fire the action when a menu item is clicked.
 */
const VigoMultiButton = forwardRef<HTMLButtonElement, VigoMultiButtonProps>(
  (
    {
      buttons = [],
      variant,
      sx = {},
      size = 'medium',
      enableSelectionPersist = false,
      selectedButtonSx = {},
      popperProps = {},
      fireActionFromMenu = false,
      disabled
    }: VigoMultiButtonProps,
    ref
  ) => {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLDivElement>(null);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const uiFlowMode = useAppSelector(state => state.uiflow.uiFlowMode);

    const theme = useTheme();
    const { t } = useTranslation();
    const mutationsRunning = useIsMutating();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const selectedName = useMemo(() => t(buttons?.[selectedIndex]?.name ?? ''), [selectedIndex, buttons]);

    /**
     * Handles the click event for the button.
     */
    const handleClick = () => {
      const selectedButton = buttons?.[selectedIndex];

      if (selectedButton && Object.hasOwn(selectedButton, 'action') && typeof selectedButton.action === 'function') {
        buttons?.[selectedIndex]?.action();
      }
    };

    /**
     * Handles the click event of a menu item.
     *
     * @param event - The click event.
     * @param index - The index of the menu item.
     */
    const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
      setSelectedIndex(index);
      setValueInStorage(index, uiFlowMode);
      setOpen(false);

      if (fireActionFromMenu) {
        const selectedButton = buttons?.[index];

        if (selectedButton && Object.hasOwn(selectedButton, 'action') && typeof selectedButton.action === 'function') {
          buttons?.[index]?.action();
        }
      }
    };

    /**
     * Toggles the value of `open`.
     */
    const handleToggle = () => {
      setOpen(prevOpen => !prevOpen);
    };

    /**
     * Handles the close event for the button.
     * @param {Event} event - The event object.
     */
    const handleClose = (event: Event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
        return;
      }

      setOpen(false);
    };

    /**
     * Determines the text color based on the current state of `mutationsRunning` and the theme.
     * If `mutationsRunning` is true, returns '#acacac'.
     * If the theme mode is 'light', returns the value of `theme.palette.vigoColors.primaryLight`.
     * Otherwise, returns '#fff'.
     * If none of the conditions are met, returns '#000' as the default color.
     *
     * @returns The determined text color.
     */
    const determineTextColor = useCallback(() => {
      const color = Boolean(mutationsRunning)
        ? '#acacac'
        : theme.palette.mode === 'light'
        ? theme.palette.vigoColors.primaryLight
        : '#fff';

      return color ?? '#000';
    }, [mutationsRunning, theme.palette.mode, theme.palette.vigoColors.primaryLight]);

    useEffect(() => {
      /**
       * If selection persist is enabled, Retrieve the selected index from the local storage and set it as the selected index.
       */

      if (!enableSelectionPersist) return;

      const savedSelectedIndex = getValueFromStorage(uiFlowMode);
      setSelectedIndex(savedSelectedIndex);
    }, [enableSelectionPersist, uiFlowMode]);

    return (
      <>
        <ButtonGroup
          variant={variant ? variant : theme.palette.mode === 'light' ? 'text' : 'contained'}
          ref={anchorRef}
          disableElevation
          size={size}
          disabled={disabled}
          aria-label="Button group with a nested menu"
          sx={{
            border: '1px solid #d7d7d7',
            '&:focus-within': {
              border: `1px solid ${(theme: Theme) => theme.palette.customColors.primaryGradient} !important`
            },
            '& > button.MuiButtonGroup-grouped': {
              color: determineTextColor(),
              fontWeight: 600,
              fontSize: '1rem',
              padding: '0.3rem 0.6rem',
              '&:hover': { cursor: Boolean(mutationsRunning) ? 'not-allowed' : 'pointer' },
              '&:focus': { border: theme => `1px solid ${theme.palette.customColors.primaryGradient}` }
            },
            '& > button:nth-of-type(2)': { borderLeft: 'none', minWidth: '30px' },
            ...((!variant || variant === 'text' || variant === 'contained') && {
              '& > button.MuiButtonGroup-grouped:not(:last-of-type):not(:focus)': {
                borderRight: '1px solid #d7d7d7 !important'
              }
            }),
            ...sx
          }}>
          <Button onClick={handleClick} ref={ref} sx={selectedButtonSx} disabled={mutationsRunning > 0}>
            {selectedName}
          </Button>
          <Button
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="menu"
            onClick={handleToggle}>
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper
          sx={{
            zIndex: 1
            //   width: anchorRef.current?.clientWidth
          }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          nonce={undefined}
          onResize={undefined}
          onResizeCapture={undefined}
          {...popperProps}>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
              }}>
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" autoFocusItem>
                    {buttons.map((button, index) => (
                      <MenuItem
                        key={button.name}
                        disabled={mutationsRunning > 0 || button.disabled}
                        selected={index === selectedIndex}
                        sx={{ ...(button.name === selectedName && { display: 'none' }) }}
                        onClick={event => handleMenuItemClick(event, index)}>
                        {t(button?.name ?? '')}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>
    );
  }
);

export default VigoMultiButton;
