import { ServiceLocation } from 'src/services/serviceLocationsAxios';

// ** Mock
import mock from 'src/@fake-db/mock';

// ** Types
import { UserDetailsType } from 'src/types/apps/usersTypes';

export const data: { users: UserDetailsType[] } = {
  users: [
    // {
    //   id: '0',
    //   userName: 'gslixby0',
    //   contactNumber: '(479) 232-9151',
    //   roleDescription: ['User'],
    //   company: 'Yotz PVT LTD',
    //   displayName: 'Galen Slixby',
    //   jobTitle: 'Engineer',
    //   image: '',
    //   cat: {
    //     id: '1234'
    //   }
    // },
    // {
    //   id: '1',
    //   userName: 'hredmore1',
    //   contactNumber: '(472) 607-9137',
    //   roleDescription: ['User'],
    //   company: 'Skinder PVT LTD',
    //   displayName: 'Halsey Redmore',
    //   jobTitle: 'Engineer',
    //   image: '',
    //   cat: {
    //     id: '1234'
    //   }
    // },
    // {
    //   id: '2',
    //   userName: 'msicely2',
    //   contactNumber: '(321) 264-4599',
    //   roleDescription: ['Admin'],
    //   company: 'Oozz PVT LTD',
    //   displayName: 'Marjory Sicely',
    //   jobTitle: 'Tester',
    //   image: '',
    //   cat: {
    //     id: '1234'
    //   }
    // },
    // {
    //   id: '3',
    //   userName: 'crisby3',
    //   contactNumber: '(923) 690-6806',
    //   roleDescription: ['User'],
    //   company: 'Oozz PVT LTD',
    //   displayName: 'Cyrill Risby',
    //   jobTitle: 'BusinessAnalyst',
    //   image: '',
    //   cat: {
    //     id: '1234'
    //   }
    // },
    // {
    //   id: '4',
    //   userName: 'mhurran4',
    //   contactNumber: '(669) 914-1078',
    //   roleDescription: ['User'],
    //   company: 'Aimbo PVT LTD',
    //   displayName: 'Maggy Hurran',
    //   jobTitle: 'Engineer',
    //   image: '',
    //   cat: {
    //     id: '1234'
    //   }
    // },
    // {
    //   id: '5',
    //   userName: 'gslixby0',
    //   contactNumber: '(479) 232-9151',
    //   roleDescription: ['User'],
    //   company: 'Yotz PVT LTD',
    //   displayName: 'Galen Slixby',
    //   jobTitle: 'Engineer',
    //   image: '',
    //   cat: {
    //     id: '1234'
    //   }
    // },
    // {
    //   id: '6',
    //   userName: 'hredmore1',
    //   contactNumber: '(472) 607-9137',
    //   roleDescription: ['User'],
    //   company: 'Skinder PVT LTD',
    //   displayName: 'Halsey Redmore',
    //   jobTitle: 'Engineer',
    //   image: '',
    //   cat: {
    //     id: '1234'
    //   }
    // },
    // {
    //   id: '7',
    //   userName: 'msicely2',
    //   contactNumber: '(321) 264-4599',
    //   roleDescription: ['Admin'],
    //   company: 'Oozz PVT LTD',
    //   displayName: 'Marjory Sicely',
    //   jobTitle: 'Tester',
    //   image: '',
    //   cat: {
    //     id: '1234'
    //   }
    // },
    // {
    //   id: '8',
    //   userName: 'crisby3',
    //   contactNumber: '(923) 690-6806',
    //   roleDescription: ['User'],
    //   company: 'Oozz PVT LTD',
    //   displayName: 'Cyrill Risby',
    //   jobTitle: 'BusinessAnalyst',
    //   image: '',
    //   cat: {
    //     id: '1234'
    //   }
    // },
    // {
    //   id: '9',
    //   userName: 'mhurran4',
    //   contactNumber: '(669) 914-1078',
    //   roleDescription: ['User'],
    //   company: 'Aimbo PVT LTD',
    //   displayName: 'Maggy Hurran',
    //   jobTitle: 'Engineer',
    //   image: '',
    //   cat: {
    //     id: '1234'
    //   }
    // },
    // {
    //   id: '10',
    //   userName: 'gslixby0',
    //   contactNumber: '(479) 232-9151',
    //   roleDescription: ['User'],
    //   company: 'Yotz PVT LTD',
    //   displayName: 'Galen Slixby',
    //   jobTitle: 'Engineer',
    //   image: '',
    //   cat: {
    //     id: '1234'
    //   }
    // },
    // {
    //   id: '11',
    //   userName: 'hredmore1',
    //   contactNumber: '(472) 607-9137',
    //   roleDescription: ['User'],
    //   company: 'Skinder PVT LTD',
    //   displayName: 'Halsey Redmore',
    //   jobTitle: 'Engineer',
    //   image: '',
    //   cat: {
    //     id: '1234'
    //   }
    // },
    // {
    //   id: '12',
    //   userName: 'msicely2',
    //   contactNumber: '(321) 264-4599',
    //   roleDescription: ['Admin'],
    //   company: 'Oozz PVT LTD',
    //   displayName: 'Marjory Sicely',
    //   jobTitle: 'Tester',
    //   image: '',
    //   cat: {
    //     id: '1234'
    //   }
    // },
    // {
    //   id: '13',
    //   userName: 'crisby3',
    //   contactNumber: '(923) 690-6806',
    //   roleDescription: ['User'],
    //   company: 'Oozz PVT LTD',
    //   displayName: 'Cyrill Risby',
    //   jobTitle: 'Business Analyst',
    //   image: '',
    //   cat: {
    //     id: '1234'
    //   }
    // },
    // {
    //   id: '14',
    //   userName: 'mhurran4',
    //   contactNumber: '(669) 914-1078',
    //   roleDescription: ['User'],
    //   company: 'Aimbo PVT LTD',
    //   displayName: 'Maggy Hurran',
    //   jobTitle: 'Engineer',
    //   image: '',
    //   cat: {
    //     id: '1234'
    //   }
    // },
    // {
    //   id: '15',
    //   userName: 'mhurran4',
    //   contactNumber: '(669) 914-1078',
    //   roleDescription: ['User'],
    //   company: 'Aimbo PVT LTD',
    //   displayName: 'Maggy Hurran',
    //   jobTitle: 'Engineer',
    //   image: '',
    //   cat: {
    //     id: '1234'
    //   }
    // }
  ]
};

// GET:
mock.onGet(`${ServiceLocation.uiflowdata}/users`).reply(() => {
  // const { q = '' } = config.params ?? '';

  // const queryLowered = q.toLowerCase();

  // const filteredData = data.users.filter(
  //   user =>
  //     user.userName.toLowerCase().includes(queryLowered) ||
  //     user.company.toLowerCase().includes(queryLowered) ||
  //     user.contactNumber.toLowerCase().includes(queryLowered) ||
  //     user.displayName.toLowerCase().includes(queryLowered) ||
  //     user.jobTitle.toLowerCase().includes(queryLowered)
  // );

  // return [
  //   200,
  //   {
  //     allData: data.users,
  //     users: filteredData,
  //     params: config.params,
  //     total: filteredData.length
  //   }
  // ];

  return [200, data.users];
});

mock.onGet(new RegExp(`${ServiceLocation.uiflowdata}/user/*`)).reply(function (config) {
  const { url } = config;
  const regex = /\/uiflow\/api\/v1\/user\/(\d+)/i;
  const match = url?.match(regex) || [0];

  const user = data.users.filter(user => Number(user.id) == Number(match[1]));

  return [200, user];
});

mock.onGet(`${ServiceLocation.widgetData}/userCount`).reply(() => {
  const count = data.users.length;

  return [200, count];
});

mock.onGet(new RegExp(`${ServiceLocation.uiflowdata}/additionalData/user/*`)).reply(config => {
  const { url } = config;
  const regex = /\/uiflow\/api\/v1\/additionalData\/user\/(.*)/i;
  const match = url?.match(regex) || [0];

  const user = data.users.find(user => user.id == match[1]);

  if (!user) {
    return [500];
  }

  return [200, user.additionalData ?? {}];
});

// POST: Add new user
mock.onPost('/apps/SystemUsers/edit-user').reply(config => {
  const editedUser: UserDetailsType = JSON.parse(config.data);
  const user = data.users.filter(user => user.id == editedUser.id)[0];

  if (user !== undefined) {
    EditUser(user, editedUser);

    return [201, { user }];
  } else {
    return [400];
  }
});

function EditUser(user: UserDetailsType, editedUser: UserDetailsType) {
  // user.displayName = editedUser.displayName;
  // user.userName = editedUser.userName;
  // user.image = editedUser.image;
  // user.roleDescription = editedUser.roleDescription;
  console.log('user', user);
  console.log('Edituser', editedUser);
}
