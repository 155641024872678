// ** MUI Imports
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';
import { VigoTypography } from 'src/@vigo/vigo-typography';

const FooterContent = () => {
  // ** Var
  const theme = useTheme();

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between' }}>
      <Box sx={{ display: 'flex' }}>
        {'© ' + new Date().getFullYear() + ','}
        <VigoTypography value={'Made with ❤️ by'} sx={{ mr: 2, ml: 2 }} disableTranslation />
        <Link
          sx={{ ...(theme.palette.mode === 'dark' && { color: theme.palette.customColors.primaryGradient }) }}
          target="_blank"
          href="https://vigosoftware.com/">
          Vigo Software
        </Link>
      </Box>
    </Box>
  );
};

export default FooterContent;
