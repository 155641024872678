import { PayloadAction } from '@reduxjs/toolkit';
import { UIFlowState } from '..';

const setItemHeaderTitle = (state: UIFlowState, action: PayloadAction<string>) => {
  state.flowTitlesState.headerComponentTitles.item = action.payload; //
};

const setFlowTitleForAddMode = (state: UIFlowState, action: PayloadAction<string>) => {
  state.flowTitlesState.flowTitleForAddMode = action.payload;
};

export const flowTitlesReducer = {
  setItemHeaderTitle,
  setFlowTitleForAddMode
};
