import { ServiceLocation } from "src/services/serviceLocationsAxios";
import mock from "src/@fake-db/mock";

export const newsfeedTypeData = [
  {
    id: 1,
    code: 'SELECTCUST',
    description: 'Select Customers'
  },
  {
    id: 2,
    code: 'ALLSTAFF',
    description: 'All Internal Staff'
  },
  {
    id: 3,
    code: 'SELECTSTAFF',
    description: 'Select Internal Staff'
  },
  {
    id: 4,
    code: 'SELECTDEPARTMENT',
    description: 'Select Internal Departments'
  },
  {
    id: 5,
    code: 'FUSTIONCUST',
    description: 'Fusion - Customers (Flow)'
  },
  {
    id: 6,
    code: 'NETWORKCONNECT',
    description: 'Network Connect - Pallet Track'
  }
];

mock.onGet(`${ServiceLocation.newsfeed}/newsfeedType`).reply(() => {
  return [200, newsfeedTypeData];
});
