import { Icon, ListItemIcon, ListItemText, useTheme } from '@mui/material';
import { MenuItem } from './styledComponents';

import { useVigoTranslation } from 'src/@core/hooks/useVigoTranslation';
import router from 'next/router';
import { NoteMultipleOutline } from 'mdi-material-ui';

const ReleaseNotesListItem = () => {
  const theme = useTheme();

  const { t } = useVigoTranslation();

  function displayReleaseNotes(): void {
    router.push('/apps/releasenotes/');
  }

  return (
    <MenuItem sx={{ pb: 0 }} onClick={displayReleaseNotes}>
      <ListItemIcon>
        <Icon>
          <NoteMultipleOutline></NoteMultipleOutline>
        </Icon>
      </ListItemIcon>

      <ListItemText sx={{ '& span:hover': { color: theme.palette.customColors.primaryGradient } }}>
        {t('Latest Release Notes')}
      </ListItemText>
    </MenuItem>
  );
};

export default ReleaseNotesListItem;
