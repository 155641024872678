// ** React Imports
import { ReactNode, ReactElement, useEffect } from 'react';

// ** Next Imports
import { useRouter } from 'next/router';

// ** Hooks Import
import { useAuth } from 'react-oidc-context';

interface GuestGuardProps {
  children: ReactNode;
  fallback: ReactElement | null;
  hideSpinner: boolean;
}

const GuestGuard = (props: GuestGuardProps) => {
  const { children, fallback, hideSpinner } = props;
  const auth = useAuth();
  const router = useRouter();

  useEffect(() => {
    if (!router.isReady) {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.route]);


  if ((auth.isLoading || (!auth.isLoading && auth.user !== null)) && !hideSpinner) {
    return fallback;
  }

  return <div>{children}</div>;
};

export default GuestGuard;
