import { PayloadAction } from '@reduxjs/toolkit';
import { UIFlowState } from '..';

const setActiveTabIndex = (state: UIFlowState, action: PayloadAction<number>) => {
  state.activeTabState.activeTabIndex = action.payload;
};

const resetActiveTabsAndStatuses = (state: UIFlowState) => {
  state.addModeTabsState.addTabsStatuses.forEach((tabStatus, index) => {
    tabStatus.locked = index === 0 ? false : true;
  });
};

const setActiveTabDirtyProperty = (state: UIFlowState, action: PayloadAction<boolean>) => {
  state.activeTabState.activeTabIsDirty = action.payload;
};

const setActiveComponentId = (state: UIFlowState, action: PayloadAction<string>) => {
  state.activeTabState.activeComponentId = action.payload;
};

const setActiveComponentName = (state: UIFlowState, action: PayloadAction<string>) => {
  state.activeTabState.activeComponentName = action.payload;
};

const setActiveTabFunctions = (state: UIFlowState, action: PayloadAction<Record<string, (t?: any) => any>>) => {
  state.activeTabState.activeTabFunctions = action.payload;
};

export const activeTabReducers = {
  setActiveTabIndex,
  resetActiveTabsAndStatuses,
  setActiveTabDirtyProperty,
  setActiveComponentId,
  setActiveComponentName,
  setActiveTabFunctions
};
