// ** Mock Adapter
import mock from 'src/@fake-db/mock';
import { ServiceLocation } from 'src/services/serviceLocationsAxios';

// ** Type import
import { AddOnOffer } from '../../types/apps/addOnTypes';

const offers: AddOnOffer[] = [
  {
    id: 0,
    message: '30 days subscription free!',
    active: true,
    link: 'http://www.example.com'
  },
  {
    id: 1,
    message: '3 half price!',
    active: false,
    link: 'http://www.example.com'
  }
];

mock.onGet(`${ServiceLocation.addOnService}/offers`).reply(() => {
  return [200, offers];
});
