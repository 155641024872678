// ** Mock Adapter
import mock from 'src/@fake-db/mock';

// ** Type import
import { VerticalNavItemsType } from 'src/@core/layouts/types';

const navigation: VerticalNavItemsType = [
  {
    icon: 'HomeOutline',
    title: 'My Workspace',
    path: '/dashboards/Home'
  },

  // {
  //   title: 'CRM',
  //   icon: 'FaceAgent',
  //   children: [
  //     {
  //       title: 'Customers',
  //       icon: 'HelpCircleOutline',
  //       path: '/apps/masterdata/customers'
  //     }
  //   ]
  // },
  // {
  //   title: 'Order Processing',
  //   icon: 'FolderPlusOutline',
  //   children: [
  //     {
  //       title: 'Orders',
  //       icon: 'HelpCircleOutline',
  //       path: '/apps/masterdata/orders'
  //     }
  //   ]
  // },
  // {
  //   title: 'Sub Contracters',
  //   icon: 'AccountSwitch',
  //   children: [
  //     {
  //       title: 'Contractors',
  //       icon: 'HelpCircleOutline',
  //       path: '/apps/masterdata/contractors'
  //     }
  //   ]
  // },
  // {
  //   title: 'Events',
  //   icon: 'CalendarSearch',
  //   children: [
  //     {
  //       title: 'Events',
  //       icon: 'HelpCircleOutline',
  //       path: '/apps/masterdata/events'
  //     }
  //   ]
  // },
  // {
  //   title: 'Planning',
  //   icon: 'EarthArrowRight',
  //   children: [
  //     {
  //       title: 'Route Planning',
  //       icon: 'HelpCircleOutline',
  //       path: '/apps/masterdata/routePlanning'
  //     }
  //   ]
  // },
  // {
  //   title: 'Shop Floor',
  //   icon: 'Factory',
  //   children: [
  //     {
  //       title: 'Configuration',
  //       icon: 'HelpCircleOutline',
  //       path: '/apps/masterdata/shopfloor'
  //     }
  //   ]
  // },
  // {
  //   title: 'Workforce',
  //   icon: 'AccountGroup',
  //   children: [
  //     {
  //       title: 'Configuration',
  //       icon: 'HelpCircleOutline',
  //       path: '/apps/masterdata/worforce'
  //     }
  //   ]
  // },
  // {
  //   title: 'Assets',
  //   icon: 'ApplicationCogOutline',
  //   children: [
  //     {
  //       title: 'Configuration',
  //       icon: 'HelpCircleOutline',
  //       path: '/apps/masterdata/assets'
  //     }
  //   ]
  // },
  // {
  //   title: 'Compliance',
  //   icon: 'Handshake',
  //   children: [
  //     {
  //       title: 'Configuration',
  //       icon: 'HelpCircleOutline',
  //       path: '/apps/masterdata/compliance'
  //     }
  //   ]
  // },
  // {
  //   title: 'Finance',
  //   icon: 'CashMultiple',
  //   children: [
  //     {
  //       title: 'Configuration',
  //       icon: 'HelpCircleOutline',
  //       path: '/apps/masterdata/finance'
  //     }
  //   ]
  // },
  // {
  //   title: 'IT Management',
  //   icon: 'DesktopClassic',
  //   children: [
  //     {
  //       title: 'Configuration',
  //       icon: 'HelpCircleOutline',
  //       path: '/apps/masterdata/itManagement'
  //     }
  //   ]
  // },
  // {
  //   title: 'Reports/Analytics',
  //   icon: 'Poll',
  //   children: [
  //     {
  //       title: 'Configuration',
  //       icon: 'HelpCircleOutline',
  //       path: '/apps/masterdata/analytics'
  //     }
  //   ]
  // },

  //Administraion
  {
    title: 'Administration',
    icon: 'ApplicationCogOutline',
    children: [
      {
        title: 'MasterData',
        icon: 'StorageOutline',
        path: '/apps/masterdata'
      },
      {
        title: 'Registrations',
        icon: 'AppRegistrationOutline',
        path: '/admin/registrations'
      },
      {
        title: 'Marketplace',
        icon: 'StorefrontOutline',
        path: '/apps/marketplace'
      },
      {
        title: 'Order',
        icon: 'InventoryOutline',
        path: '/uiflow?id=eabf3fc3-fcda-4a53-907a-be711b29d5da'
      },
      {
        title: 'Roles',
        icon: 'AccountCircleOutline',
        path: '/uiflow?id=A232EF3C-61C3-4BFF-BBD0-A17FECFCACE2'
      },
      {
        title: 'UI Flow Builder',
        icon: 'AccountCircleOutline',
        path: '/uiflow?id=6a8457fb-ba8e-4f79-a8f1-d44905051491'
      },
      {
        title: 'Menu Builder',
        icon: 'AccountCircleOutline',
        path: '/uiflow?id=EBF2280B-3E27-448B-8742-20980F334D2D'
        //path: '/uiflow?id=EBF2280B-3E27-448B-8742-20980F334D2D'
      },
      {
        title: 'Users',
        icon: 'AccountCircleOutline',
        path: '/uiflow?id=613f8175-4df7-4166-a365-773781e2237d'
        //path: '/uiflow?id=EBF2280B-3E27-448B-8742-20980F334D2D'
      },
      {
        title: 'Workflows',
        icon: 'RobotOutline',
        path: '/workflows'
      }
    ]
  },

  // Vigo Academy
  {
    title: 'Vigo Academy',
    icon: 'SchoolOutline',
    children: [
      {
        icon: 'BookOpenOutline',
        title: 'Knowledge Base',
        path: '/apps/knowledge-base'
      },
      {
        title: 'FAQ',
        path: '/apps/faq',
        icon: 'HelpCircleOutline'
      }
    ]
  }
];

mock.onGet('/api/vertical-nav/data').reply(() => {
  return [200, navigation];
});
