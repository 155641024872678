import { Box, Stack, useTheme } from '@mui/material';
import { VigoTypography } from 'src/@vigo/vigo-typography';
import UserAvatar from './UserAvatar';
import { UserDto } from 'src/models/identity';
import { MouseEventHandler } from 'react';

interface AppbarIconProps {
  userData?: UserDto;
  handleClick?: MouseEventHandler<HTMLDivElement>;
}

const AppbarIcon = ({ userData, handleClick }: AppbarIconProps) => {
  const theme = useTheme();

  /**
   * Generate the text color using the custom dark color from the theme palette.
   *
   * @return {string} - The RGBA value of the text color.
   */
  const textColor = (): string => {
    return `rgba(${theme.palette.customColors.dark}, 0.87)`;
  };

  return (
    <Box id="userbutton" sx={{ borderLeft: '1px solid #000', paddingLeft: '20px', marginLeft: '20px' }}>
      {/* user firstname & avatar */}
      <Stack
        direction="row"
        alignItems="center"
        spacing="12px"
        sx={{ '&:hover': { cursor: 'pointer' } }}
        onClick={handleClick}>
        <VigoTypography
          disableTranslation
          value={userData?.userProfile?.forname}
          sx={{ fontSize: '1.143rem', color: textColor() }}
        />
        <UserAvatar userProfile={userData?.userProfile ?? {}} />
      </Stack>
    </Box>
  );
};

export default AppbarIcon;
