import { useAuth } from 'react-oidc-context';
import { useLoggingContext } from 'src/@core/context/loggingContext';

export const useLogging = () => {
  const logtail = useLoggingContext();

  const auth = useAuth();

  const user = auth.user;
  const logLevel = user?.profile.logLevel as string;

  const logMessage = (type: 'Debug' | 'Error' | 'Info' | 'Warn', message: string, data: any = {}) => {
    data = { ...data, user: user?.profile ?? {} };

    switch (type) {
      // logLevel = 1
      case 'Debug':
        if (logtail && shouldLog(1)) {
          logtail.debug(message, data);
        } else console.debug(message, data);
        break;

      // logLevel = 2
      case 'Info':
        if (logtail && shouldLog(2)) {
          logtail.info(message, data);
        } else console.info(message, data);
        break;

      // logLevel = 3
      case 'Warn':
        if (logtail && shouldLog(3)) {
          logtail.warn(message, data);
        } else console.warn(message, data);
        break;

      // logLevel = 4
      case 'Error':
        if (logtail && shouldLog(4)) {
          logtail.error(message, data);
        } else console.error(message, data);
        break;
      default:
        break;
    }
  };

  const flushLogs = () => {
    if (logtail) {
      logtail.flush();
    }
  };

  const shouldLog = (level: number) => {
    if (!logLevel) {
      return true;
    }

    const numericLogLevel = parseInt(logLevel, 10);
    if (level > numericLogLevel || level) {
      return true;
    }

    return false;
  };

  return { logMessage, flushLogs };
};
