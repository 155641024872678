import {
  IEntity,
  IEntityProperty,
  IEvent,
  IWorkFlowCategory,
  IAction,
  IActionProperty,
  IEventProperty,
  IWorkflowObject
} from 'src/views/apps/workflow/workFlowComponentTypes';

export const categories: IWorkFlowCategory[] = [
  {
    id: '0',
    name: 'Status Change',
    tag_name: 'Status',
    color: '#ded4fc'
  },
  {
    id: '1',
    name: 'Item Change',
    tag_name: 'Item Change',
    color: '#cce5ff'
  },
  {
    id: '2',
    name: 'Create',
    tag_name: 'Create',
    color: '#bbdbc9'
  },
  {
    id: '3',
    name: 'Date',
    tag_name: 'Date',
    color: '#d4dff4'
  },
  {
    id: '4',
    name: 'Move Item',
    tag_name: 'Move Item',
    color: '#ceccdc'
  },
  {
    id: '5',
    name: 'Subitem',
    tag_name: 'Subitem',
    color: '#ccd6de'
  },
  {
    id: '6',
    name: 'Dependency',
    tag_name: 'Dependency',
    color: '#e1e1e1'
  },
  {
    id: '7',
    name: 'Import Data',
    tag_name: 'Import Data',
    color: '#b3d0de'
  }
];

export const exampleWorkflowItems: IWorkflowObject[] = [
  {
    id: '0',
    displayName: ' Example Workflow One',
    description: 'When status changes to something move item to group',
    categories: ['0', '4'],
    entityId: '',
    entityPropertyId: null,
    eventId: '',
    eventPropertyValues: [],
    actionId: '',
    actionPropertyValues: [],
    isActive: true
  },
  {
    id: '1',
    displayName: ' Example Workflow Two',
    description: 'When column changes notify someone and create an update with this text',
    categories: ['1', '2'],
    entityId: '',
    entityPropertyId: null,
    eventId: '',
    eventPropertyValues: [],
    actionId: '',
    actionPropertyValues: [],
    isActive: false
  },
  {
    id: '2',
    displayName: ' Example Workflow Three',
    description: 'When date arrives and only if status is something notify someone',
    categories: ['0', '3'],
    entityId: '',
    entityPropertyId: null,
    eventId: '',
    eventPropertyValues: [],
    actionId: '',
    actionPropertyValues: [],
    isActive: false
  },
  {
    id: '3',
    displayName: ' Example Workflow Four',
    description: 'When status changes to something notify someone',
    categories: ['0'],
    entityId: '',
    entityPropertyId: null,
    eventId: '',
    eventPropertyValues: [],
    actionId: '',
    actionPropertyValues: [],
    isActive: false
  }
];

export const entityArray: IEntity[] = [
  {
    id: '0',
    name: 'job',
    displayName: 'Job',
    properties: ['0']
  },
  {
    id: '1',
    name: 'User',
    displayName: 'User',
    properties: ['1']
  }
];

export const propertyArray: IEntityProperty[] = [
  {
    id: '0',
    name: 'status',
    displayName: 'Status',
    dataType: 'string'
  },
  {
    id: '1',
    name: 'role',
    displayName: 'Role',
    dataType: 'string'
  }
];

export const eventsArray: IEvent[] = [
  {
    id: '0',
    name: 'created',
    displayName: ' has been created',
    entityId: '0',
    entityPropertyId: null,
    properties: [],
    allowableActions: ['0', '1']
  },
  {
    id: '1',
    name: 'deleted',
    displayName: ' has been deleted',
    entityId: '0',
    entityPropertyId: null,
    properties: [],
    allowableActions: ['0', '1']
  },
  {
    id: '2',
    name: 'changed',
    displayName: 'has changed',
    entityId: '0',
    entityPropertyId: '0',
    properties: [],
    allowableActions: ['2']
  },
  {
    id: '3',
    name: 'changesTo',
    displayName: 'is changed to',
    entityId: '0',
    entityPropertyId: '0',
    properties: ['0'],
    allowableActions: ['0', '3']
  },
  {
    id: '4',
    name: 'created',
    displayName: ' has been created',
    entityId: '1',
    entityPropertyId: null,
    properties: [],
    allowableActions: ['0']
  },
  {
    id: '5',
    name: 'deleted',
    displayName: ' has been deleted',
    entityId: '1',
    entityPropertyId: null,
    properties: [],
    allowableActions: ['0']
  },
  {
    id: '6',
    name: 'changed',
    displayName: 'has changed',
    entityId: '1',
    entityPropertyId: '1',
    properties: [],
    allowableActions: ['2']
  },
  {
    id: '7',
    name: 'changesTo',
    displayName: 'is changed to',
    entityId: '1',
    entityPropertyId: '1',
    properties: ['0'],
    allowableActions: ['2', '3']
  }
];

export const eventProperties: IEventProperty[] = [
  {
    id: '0',
    name: 'changeValue',
    displayName: 'Value',
    dataType: '',
    order: 1
  }
];

export const actions: IAction[] = [
  {
    id: '0',
    name: 'sendNotification',
    displayName: 'Send Notification To',
    isActive: true,
    properties: ['0']
  },
  {
    id: '1',
    name: 'changeStatus',
    displayName: 'Change Status To',
    isActive: true,
    properties: ['0']
  },
  {
    id: '2',
    name: 'createItem',
    displayName: 'Create New',
    isActive: true,
    properties: ['0']
  },
  {
    id: '3',
    name: 'moveToGroup',
    displayName: 'Move To Group',
    isActive: true,
    properties: ['0']
  }
];

export const actionProperties: IActionProperty[] = [
  {
    id: '0',
    name: 'changeValue',
    displayName: 'Value',
    dataType: '',
    order: 1
  }
];
