import { UnitCodeProfileInformationDto } from 'src/models/salesorder';
import { CombinedConfig } from './buildFormConfig';

export interface ParsedCustomerConfig {
  unitCodeData: Record<string, any>;
  cutOffData: Record<string, any>;
  hazardousData: Record<string, any>;
  paperworkData: Record<string, any>;
  generalConfigProfileData: Record<string, any>;
  customerData: Record<string, any>;
  rdcInformation: Record<string, any>;
  configurationUpdateAvailable: boolean;
}

const parseCustomerConfig = (custConfig: CombinedConfig): ParsedCustomerConfig => {
  if (!custConfig)
    return {
      unitCodeData: {},
      cutOffData: {},
      hazardousData: {},
      paperworkData: {},
      generalConfigProfileData: {},
      customerData: {},
      rdcInformation: {},
      configurationUpdateAvailable: false
    };

  const { operationalProfileInformation, cutOffTimes, hazardous, rdcInformation, generalConfiguration, customer } =
    custConfig;

  const unitCodeProfiles = operationalProfileInformation?.unitCodeProfiles?.filter(
    unitCodeProfile => unitCodeProfile.profile?.isActive
  );

  const unitCodeData = unitCodeProfiles?.reduce((acc: Record<string, any>, curr: UnitCodeProfileInformationDto) => {
    return {
      ...acc,
      [curr?.profile?.unitCode?.code as string]: {
        profileName: curr?.profile?.name,
        profileId: curr?.profile?.id,
        unitCodeId: curr?.profile?.unitCodeId,
        code: curr?.profile?.unitCode?.code,
        description: curr?.profile?.unitCode?.description,
        nominalId: curr?.profile?.unitCode?.nominalId,
        serviceCodes: curr?.serviceCodeProfiles
          ?.filter(serviceCodeProfile => serviceCodeProfile.profile?.isActive)
          .map(serviceCodeProfile => ({
            profileName: serviceCodeProfile?.profile?.name,
            profileId: serviceCodeProfile?.profile?.id,
            profileDescription: serviceCodeProfile?.profile?.description,
            serviceTypeId: serviceCodeProfile.profile?.serviceTypeId,
            workingDays: serviceCodeProfile.profile?.workingDays,
            maxWorkingDays: serviceCodeProfile.profile?.maxWorkingDays,
            specificDayOfWeek: serviceCodeProfile.profile?.specificDayOfWeek,
            anytimeAvailable: serviceCodeProfile.profile?.anytimeAvailable,
            limitSpaces: serviceCodeProfile.profile?.limitSpaces,
            chargeUsingDeliveryZone: serviceCodeProfile.profile?.serviceType?.chargeUsingDeliveryZone,
            chargeUsingCollectionZone: serviceCodeProfile.profile?.serviceType?.chargeUsingCollectionZone,
            defaultSurchargeIds: [serviceCodeProfile.profile?.serviceType?.defaultSurchargeId].concat(
              serviceCodeProfile.surcharges
                ?.filter(surcharge => surcharge?.isDefault)
                .map(surcharge => surcharge?.surchargeTypeId)
            ),
            maxOs: serviceCodeProfile.profile?.serviceType?.maxOs,
            maxPallets: serviceCodeProfile.profile?.serviceType?.maxPallets,
            code: serviceCodeProfile.profile?.serviceType?.code,
            description: serviceCodeProfile.profile?.serviceType?.description,
            surcharges: serviceCodeProfile.surcharges?.map(surcharge => surcharge.surchargeTypeId)
          }))
      }
    };
  }, {});

  const cutOffData = {
    profileName: cutOffTimes?.profile?.name,
    profileId: cutOffTimes?.profile?.id,
    allowEditAfterCollection: cutOffTimes?.profile?.allowEditAfterCollection,
    allowEditAfterCutOff: cutOffTimes?.profile?.allowEditAfterCutOff,
    disableSalesOrderEdit: cutOffTimes?.profile?.disableSalesOrderEdit,
    cutOffs: (() => {
      const typeMap = new Map();
      cutOffTimes?.cutOffs?.forEach(cutOff => {
        typeMap.set(cutOff.orderTypeId, {
          salesOrderCutOffProfileId: cutOff?.salesOrderCutOffProfileId ?? '',
          orderTypeId: cutOff?.orderTypeId ?? '',
          cutOffTime: cutOff?.cutOffTime ?? '',
          id: cutOff?.id ?? ''
        });
      });

      return typeMap;
    })()
  };

  const hazardousData = {
    name: hazardous?.profile?.name ?? '',
    isDefault: hazardous?.profile?.isDefault ?? false,
    isActive: hazardous?.profile?.isActive ?? false,
    entryEnabled: hazardous?.profile?.entryEnabled ?? false,
    entryPerItem: hazardous?.profile?.entryPerItem ?? false,
    profileId: hazardous?.profile?.id ?? ''
  };

  const paperworkData = {
    paperworkAllowed: generalConfiguration?.profile?.customerPaperworkAllowed ?? false,
    paperworkMandatory: generalConfiguration?.profile?.customerPaperworkMandatory ?? false,
    canEditAfterCollection: generalConfiguration?.profile?.customerPaperworkAllowEditAfterCollectionDate ?? false,
    canEditAfterCutoff: generalConfiguration?.profile?.customerPaperworkAllowEditAfterCutOffTime ?? false
  };

  const generalConfigProfileData = {
    allowEditingColNote1: generalConfiguration?.profile?.allowEditingColNote1 ?? false,
    allowEditingColNote2: generalConfiguration?.profile?.allowEditingColNote2 ?? false,
    allowEditingColNote3: generalConfiguration?.profile?.allowEditingColNote3 ?? false,
    allowEditingColNote4: generalConfiguration?.profile?.allowEditingColNote4 ?? false,
    allowEditingDelNote1: generalConfiguration?.profile?.allowEditingDelNote1 ?? false,
    allowEditingDelNote2: generalConfiguration?.profile?.allowEditingDelNote2 ?? false,
    allowEditingDelNote3: generalConfiguration?.profile?.allowEditingDelNote3 ?? false,
    allowEditingDelNote4: generalConfiguration?.profile?.allowEditingDelNote4 ?? false,
    allowManualAddressEntry: generalConfiguration?.profile?.allowManualAddressEntry ?? false,
    automaticSalesOrderNumbering: generalConfiguration?.profile?.automaticSalesOrderNumbering ?? false,
    activateCollectionTime: generalConfiguration?.profile?.activateCollectionTime ?? false,
    allowMultipleOSTypes: generalConfiguration?.profile?.allowMultipleOstypes
  };

  const customerData = customer;

  return {
    unitCodeData: unitCodeData ?? {},
    cutOffData: cutOffData ?? {},
    hazardousData: hazardousData ?? {},
    paperworkData: paperworkData ?? {},
    generalConfigProfileData: generalConfigProfileData ?? {},
    customerData: customerData ?? {},
    rdcInformation: rdcInformation ?? {},
    configurationUpdateAvailable: false
  };
};

export default parseCustomerConfig;
