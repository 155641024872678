// ** React Imports
import { ReactNode } from 'react';

// ** Next Import
import Link from 'next/link';

// ** MUI Components
import { VigoTypography } from 'src/@vigo/vigo-typography';
import Box from '@mui/material/Box';
import { Button, Stack } from '@mui/material';

// ** Layout Import
import BlankLayout from 'src/@core/layouts/BlankLayout';

const Error401 = () => {
  return (
    <Box className="content-center">
      <Box sx={{ p: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
        <Stack direction="column" gap={6}>
          <VigoTypography value="You are not authorized!" variant="h1" sx={{ mb: 1, fontSize: '1.5rem !important' }} />
          <VigoTypography value="You don't have permission to access this page yet." variant="body2" />
          <Link passHref href="/">
            <Button variant="contained" component="a" sx={{ px: 5.5 }}>
              Back to Home
            </Button>
          </Link>
        </Stack>
      </Box>
    </Box>
  );
};

Error401.getLayout = (page: ReactNode) => <BlankLayout>{page}</BlankLayout>;

export default Error401;
