import { ElementType, MouseEventHandler } from 'react';
import { Typography, SxProps, Theme } from '@mui/material';
import { useVigoTranslation } from 'src/@core/hooks/useVigoTranslation';

export interface ITypography {
  value: string | undefined;
  sx?: SxProps<Theme>;
  className?: string;
  align?: 'center' | 'inherit' | 'justify' | 'left' | 'right';
  gutterBottom?: boolean;
  noWrap?: boolean;
  paragraph?: boolean;
  style?: React.CSSProperties;
  variant?:
  | 'body1'
  | 'body2'
  | 'button'
  | 'caption'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'inherit'
  | 'overline'
  | 'subtitle1'
  | 'subtitle2';
  component?: ElementType;
  ariaOwns?: string | undefined;
  ariaHasPopup?: 'grid' | 'dialog' | 'menu' | 'true' | 'false' | 'listbox' | 'tree' | undefined;
  disableTranslation?: boolean;
  onClick?: MouseEventHandler<HTMLSpanElement>;
  onMouseEnter?: MouseEventHandler<HTMLSpanElement>;
  onMouseLeave?: MouseEventHandler<HTMLSpanElement>;
}

export const VigoTypography = ({
  value,
  sx,
  align,
  gutterBottom,
  noWrap,
  paragraph,
  variant,
  component,
  ariaOwns,
  ariaHasPopup,
  disableTranslation,
  onClick,
  onMouseEnter,
  onMouseLeave,
  style,
  className
}: ITypography) => {
  const { t } = useVigoTranslation();

  return (
    <Typography
      id={value}
      {...(component ? { component: { component } } : {})}
      sx={{ ...sx }}
      style={style}
      align={align}
      gutterBottom={gutterBottom}
      noWrap={noWrap}
      paragraph={paragraph}
      variant={variant}
      aria-owns={ariaOwns}
      className={className}
      aria-haspopup={ariaHasPopup}
      onClick={e => (onClick ? onClick(e) : null)}
      onMouseEnter={e => (onMouseEnter ? onMouseEnter(e) : null)}
      onMouseLeave={e => (onMouseLeave ? onMouseLeave(e) : null)}>
      {disableTranslation ? value : t(value)}
    </Typography>
  );
};
