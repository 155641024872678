interface AccountPlusProps {
  productColor?: string;
}

const AccountPlus = ({ productColor }: AccountPlusProps) => {
  return (
    <svg
      id="MDI_account-plus-outline"
      data-name="MDI / account-plus-outline"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24">
      <g id="Boundary" fill="#626679" stroke="rgba(0,0,0,0)" strokeWidth="1" opacity="0">
        <rect width="24" height="24" stroke="none" />
        <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
      </g>
      <path
        id="Path_account-plus-outline"
        data-name="Path / account-plus-outline"
        d="M15,4a4,4,0,1,0,4,4,4,4,0,0,0-4-4m0,1.9A2.1,2.1,0,1,1,12.9,8,2.1,2.1,0,0,1,15,5.9M4,7v3H1v2H4v3H6V12H9V10H6V7H4m11,6c-2.67,0-8,1.33-8,4v3H23V17c0-2.67-5.33-4-8-4m0,1.9c2.97,0,6.1,1.46,6.1,2.1v1.1H8.9V17C8.9,16.36,12,14.9,15,14.9Z"
        fill="#626679"
      />
      <path
        id="Path_account-plus-outline-2"
        data-name="Path / account-plus-outline"
        d="M4,7v3H1v2H4v3H6V12H9V10H6V7H4"
        fill={productColor ?? '#009fe3'}
      />
    </svg>
  );
};

export default AccountPlus;
