import { ComponentProps } from './dashboardComponentOptions';
import mock from 'src/@fake-db/mock';
import { ServiceLocation } from 'src/services/serviceLocationsAxios';

export type RoleType = 'user' | 'admin';

export interface StaticComponent {
  i: string;
  x: number;
  y: number;
  w: number;
  h: number;
  type: string;
  title: string;
  static: boolean;
  isDraggable: boolean;
  isResizable: boolean;
  isBound: boolean;
  href?: string;
  componentProps?: ComponentProps;
  version?: string;
  dashboardWidgetId?: string;
}

export interface DynamicComponent {
  i: string;
  x: number;
  y: number;
  w: number;
  h: number;
  type: string;
  title: string;
  static: boolean;
  isBound: boolean;
  href?: string;
  componentProps?: ComponentProps;
  version?: string;
  dashboardWidgetId?: string;
}

export interface LayoutDataObject {
  id: string;
  name: string;
  role: RoleType;
  staticComponents: StaticComponent[];
  userComponents: DynamicComponent[];
  version?: string;
}

let layouts: LayoutDataObject[] = [
  {
    id: '638b68d0-8774-4b3e-ad74-18363a02a108',
    name: '',
    role: 'user',
    staticComponents: [],
    userComponents: [
      {
        i: '446785fb-72e9-4949-a56f-aea387620e29',
        x: 0,
        y: 0,
        w: 6,
        h: 6,
        type: 'DashboardNewsFeed',
        title: 'Dashboard Newsfeed',
        isBound: true,
        static: false
      },
      {
        i: 'C960054E-7C60-4CC3-B2EC-266CB63AD1F5',
        x: 6,
        y: 0,
        w: 4,
        h: 3,
        type: 'AverageCostPerPalletSpace',
        title: 'Average Cost Per Pallet Space',
        isBound: true,
        static: false
      },
      {
        i: '84971F09-1045-4D2D-B455-9E13C6FAA5D4',
        x: 6,
        y: 2,
        w: 4,
        h: 3,
        type: 'RevenueWidget',
        title: 'Revenue',
        isBound: true,
        static: false
      }
    ]
  },
  {
    id: '4fcde37a-8755-4228-a5f9-359f788520dc',
    name: '',
    role: 'admin',
    staticComponents: [],
    userComponents: []
  }
];

// GET:
mock.onGet(`${ServiceLocation.dashboardLayout}/user`).reply(() => {
  const userLayout = layouts.find(layout => layout.role === 'user');

  if (!userLayout) {
    return [500];
  }

  return [200, userLayout];
});

mock.onGet(`${ServiceLocation.dashboardLayout}/admin`).reply(() => {
  const adminLayout = layouts.find(layout => layout.role === 'admin');

  if (!adminLayout) {
    return [500];
  }

  return [200, adminLayout];
});

// PUT:
mock.onPut(`${ServiceLocation.dashboardLayout}/user`).reply(config => {
  const { data } = config;
  const dataObject = JSON.parse(data);

  const index = layouts.findIndex(layout => layout.role === 'user');

  if (index < 0) {
    return [500];
  }

  layouts = [...layouts.slice(0, index), dataObject, ...layouts.slice(index + 1)];

  return [200, layouts];
});

mock.onPut(`${ServiceLocation.dashboardLayout}/admin`).reply(config => {
  const { data } = config;
  const dataObject = JSON.parse(data);

  const index = layouts.findIndex(layout => layout.role === 'admin');

  if (index < 0) {
    return [500];
  }

  layouts = [...layouts.slice(0, index), dataObject, ...layouts.slice(index + 1)];

  return [200, layouts];
});

mock.onPut(`${ServiceLocation.dashboardLayout}/userComponent`).reply(config => {
  const { data } = config;
  const dataObject = JSON.parse(data);

  ////console.log({ dataObject });

  let layout = layouts.find(layout => layout.role === 'user');
  const index = layout?.userComponents.findIndex(component => component.i == dataObject.i);

  if (!layout || index === undefined || index < 0) {
    return [500];
  }

  layout = {
    ...layout,
    userComponents: [...layout.userComponents.slice(0, index), dataObject, ...layout.userComponents.slice(index + 1)]
  };

  ////console.log({ layout });

  return [200, layout];
});
