import { GetAxiosInstance } from "../baseService"
import { ServiceLocation } from "../serviceLocationsAxios"

/**
 * Get Short Url Key
 * @param key string
 * @returns string
 */
export const getShortUrlKey = (key: string) => {
  return GetAxiosInstance().get<string>(ServiceLocation.shorturl + `/shorturl/${key}`);
};
