import { ErrorBoundary } from 'react-error-boundary';
import ErrorBoundaryRender, { useErrorBoundaryLog } from './ErrorBoundaryRender';

interface ErrorBoundaryWrapperProps {
  children: React.ReactNode;
}

const ErrorBoundaryWrapper = ({ children }: ErrorBoundaryWrapperProps) => {
  const logError = useErrorBoundaryLog();

  return (
    <ErrorBoundary fallbackRender={ErrorBoundaryRender} onError={logError}>
      {children}
    </ErrorBoundary>
  );
};

export default ErrorBoundaryWrapper;
