import { SxProps, Theme, Tooltip } from '@mui/material';
import { useVigoTranslation } from 'src/@core/hooks/useVigoTranslation';

export interface ITooltip {
  title: string;
  children: any;
  sx?: SxProps<Theme>;
  disableTranslation?: boolean;
  arrowEnabled?: boolean;
  enterDelay?: number;
  disableHoverListener?: boolean;
  showTooltip?: boolean;
}

export const VigoTooltip = ({
  title,
  children,
  disableTranslation,
  arrowEnabled,
  enterDelay,
  disableHoverListener = false,
  showTooltip = true
}: ITooltip) => {
  const { t } = useVigoTranslation();

  return (
    <Tooltip
      title={disableTranslation ? title : t(title)}
      componentsProps={{
        tooltip: {
          sx: {
            fontSize: '1rem',
            bgcolor: 'primary.main',
            color: 'primary.contrastText',
            display: showTooltip ? 'block' : 'none'
          }
        }
      }}
      disableHoverListener={disableHoverListener}
      arrow={arrowEnabled}
      enterDelay={enterDelay}>
      {children}
    </Tooltip>
  );
};
