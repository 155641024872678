import { createContext, ReactNode, Dispatch, useReducer } from 'react';

interface SplitViewProviderProps {
  children: ReactNode;
}

interface Action {
  type: string;
  payload?: any;
}

interface SplitViewState {
  selectedItemId: number | string | null | undefined;
  headerComponentTitles: {
    main: string;
    item: string;
  };
}

interface SplitViewContextValue {
  state: SplitViewState;
  dispatch: Dispatch<Action>;
}

const initialStateObject: SplitViewState = {
  selectedItemId: '',
  headerComponentTitles: {
    main: '',
    item: ''
  }
};

const initialState: SplitViewContextValue = {
  state: initialStateObject,
  dispatch: () => ({ type: '', payload: '' })
};

export const SplitViewContextActionTypes = {
  SET_SELECTED_ITEM_ID: 'setSelectedItemId',
  SET_HEADER_COMPONENT_TITLES: 'setHeaderComponentTitles',
  SET_MAIN_HEADER_TITLE: 'setMainHeaderTitle',
  SET_ITEM_HEADER_TITLE: 'setItemHeaderTitle',
  RESET: 'reset'
};

const splitViewStateReducer = (state: SplitViewState, action: Action) => {
  const { type, payload } = action;

  switch (type) {
    case SplitViewContextActionTypes.SET_SELECTED_ITEM_ID: {
      return { ...state, selectedItemId: payload };
    }
    case SplitViewContextActionTypes.SET_HEADER_COMPONENT_TITLES: {
      return { ...state, headerComponentTitles: { main: payload.main, item: payload.item } };
    }
    case SplitViewContextActionTypes.SET_MAIN_HEADER_TITLE: {
      return { ...state, headerComponentTitles: { ...state.headerComponentTitles, main: payload } };
    }
    case SplitViewContextActionTypes.SET_ITEM_HEADER_TITLE: {
      return { ...state, headerComponentTitles: { ...state.headerComponentTitles, item: payload } };
    }
    case SplitViewContextActionTypes.RESET: {
      return { ...initialStateObject };
    }
    default: {
      return { ...state };
    }
  }
};

export const SplitViewContext = createContext<SplitViewContextValue>(initialState);

export const SplitViewProvider = ({ children }: SplitViewProviderProps) => {
  const [state, dispatch] = useReducer(splitViewStateReducer, initialStateObject);

  const value = { state, dispatch };

  return <SplitViewContext.Provider value={value}>{children}</SplitViewContext.Provider>;
};
