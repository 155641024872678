import mock from 'src/@fake-db/mock'
import { SalesOrderLineAddressDto } from 'src/models/salesorder';

interface TrackingDto {
  id: string;
  orderType: string;
  orderReference: string;
  orderNumber: string;
  customerReference?: string;
  serviceType: string;
  surcharges: string[];
  collectionAddress: SalesOrderLineAddressDto;
  collectionDate: Date;
  deliveryAddress: SalesOrderLineAddressDto;
  deliveryName: string;
  deliveryContactName?: string;
  deliveryEmail?: string;
  deliveryPhone?: string;
  deliveryMobile?: string;
  deliveryDate: Date;
  unitType: string;
  unitQuantity: number;
  trackingCode: string;
};

const data: TrackingDto = {
  id: 'e92bd03f-bb2e-4606-b89a-a330b4f37881',
  orderType: 'Delivery',
  orderReference: 'AG12345',
  orderNumber: 'DAN1234',
  customerReference: 'DAN1234',
  serviceType: 'Next Day',
  surcharges: ['Tail Lift', 'Home Delivery - Kerbside Delivery'],
  collectionAddress: {
    addressTypeId: '15c0f4cd-d92b-47c8-a19f-ebd67b4edfc8',
    buildingName: 'Packing Ltd',
    line1: '10 Vicar Ln',
    line3: 'Chesterfield',
    postalCode: 'S40 1PY'
  },
  collectionDate: new Date('2023-05-10'),
  deliveryAddress: {
    addressTypeId: '52487937-ff4b-4d7f-9574-8c3980dbc8c3',
    buildingName: 'Vigo Software',
    line1: 'Topaz Business Park',
    line3: 'Bromsgrove',
    postalCode: 'B61 0GD'
  },
  deliveryName: 'Chris Dallow',
  deliveryContactName: 'Chris Dallow',
  deliveryEmail: 'chris@vigosoftware.com',
  deliveryPhone: '+44 (0)1527 510 500',
  deliveryMobile: '+44 (0)7800 123 4567',
  deliveryDate: new Date('2023-05-13:12:00:00+00:00'),
  unitType: 'Pallets',
  unitQuantity: 1,
  trackingCode: '1315550678608',
};

mock.onGet('/tracking?id=e92bd03f-bb2e-4606-b89a-a330b4f37881').reply(() => [200, data]);
