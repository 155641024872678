interface LogoutIconProps {
  productColor?: string;
}

const LogoutIcon = ({ productColor }: LogoutIconProps) => {
  return (
    <svg
      id="Logout_Icon"
      data-name="Logout Icon"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24">
      <rect id="Rectangle_660" data-name="Rectangle 660" width="24" height="24" fill="#626679" opacity="0" />
      <path
        id="Path_3059"
        data-name="Path 3059"
        d="M5,5h7V3H5A2.006,2.006,0,0,0,3,5V19a2.006,2.006,0,0,0,2,2h7V19H5Z"
        fill="#626679"
      />
      <path
        id="Path_3058"
        data-name="Path 3058"
        d="M17,8,15.59,9.41,17.17,11H9v2h8.17l-1.58,1.58L17,16l4-4Z"
        fill={productColor ?? '#009fe3'}
      />
    </svg>
  );
};

export default LogoutIcon;
