import { Box, Grid, IconButton, Stack, useTheme } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { VigoButton } from '../vigo-button';
import PrintIcon from '@mui/icons-material/Print';
import EmailIcon from '@mui/icons-material/Email';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DownloadIcon from '@mui/icons-material/Download';
import { VigoTextInput } from '../vigo-textinput';
import SendIcon from '@mui/icons-material/Send';
import CancelIcon from '@mui/icons-material/Cancel';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { VigoTypography } from '../vigo-typography';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import styles from './VigoPdfViewer.module.css';
import { useSettings } from 'src/@core/hooks/useSettings';
import VigoSpinner from '../vigo-spinner';
import { AttachmentDto } from 'src/models/attachment';
import { useMutation } from '@tanstack/react-query';
import { EmailMessageModel, MessagingServiceDto } from 'src/models/messaging';
import { sendEmailMessage } from 'src/services/messaging';
import { useVigoToast } from 'src/@core/hooks/useVigoToast';
import { useVigoTranslation } from 'src/@core/hooks/useVigoTranslation';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

export interface VigoPdfViewerProps {
  fileBase64: string;
  file: AttachmentDto;
  fileName?: string;
  showDownload?: boolean;
  showPrint?: boolean;
  showEmail?: boolean;
  fullScreen?: boolean;
  pageWidth?: number;
  messagingService?: MessagingServiceDto | undefined;
}

export const VigoPdfViewer = ({
  fileBase64,
  file,
  fileName,
  showDownload,
  showPrint,
  showEmail,
  fullScreen,
  pageWidth,
  messagingService
}: VigoPdfViewerProps) => {
  // State
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [loaded, setLoaded] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');
  const [sendingEmail, setSendingEmail] = useState(false);
  const [scale, setScale] = useState(!fullScreen ? 1.15 : 2.5);
  const [rotation, setRotation] = useState(0);
  const [modalScale, setModalScale] = useState(!fullScreen ? 1.5 : 4.5);
  const [modalPageNumber, setModalPageNumber] = useState(1);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { settings } = useSettings();
  const pageRef = useRef<HTMLDivElement>(document.createElement('div'));
  const theme = useTheme();

  const ele = pageRef.current;

  ele.scrollTop = 0;
  ele.scrollLeft = 150;

  let pos = { top: 0, left: 0, x: 0, y: 0 };

  // Hooks
  const { vigoToast } = useVigoToast({});
  const { t } = useVigoTranslation();

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
    setPageNumber(1);
    setLoaded(true);
  }

  function changePage(offset: number) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  async function printFile() {
    const print = await import('print-js');
    print.default({ printable: fileBase64, type: 'pdf', base64: true });
  }

  function downloadFile() {
    const linkSource = 'data:application/pdf;base64, ' + fileBase64;
    const downloadLink = document.createElement('a');
    const name = `${fileName}.pdf` as string;
    downloadLink.href = linkSource;
    downloadLink.download = name;
    downloadLink.click();
    downloadLink.remove();
  }

  function emailFile() {
    setSendEmail(true);
    setSendingEmail(true);
  }

  function cancelEmail() {
    setSendEmail(false);
    setSendingEmail(false);
  }

  function sendPdfEmail() {
    if (!emailAddress) {
      return vigoToast.error('You must specify an email address');
    }

    const email: EmailMessageModel = {
      messagingServiceId: messagingService?.id,
      subject: `Invoice ${file.name}`,
      messageBody: `${file.name} has been attached`,
      to: [emailAddress],
      purpose: 'email invoice',
      attachments: [
        {
          content: fileBase64,
          fileType: file.fileType ?? '',
          name: file.name ?? '',
          size: file.size ?? 0
        }
      ]
    };

    return vigoToast.promise(
      t('Sending email'),
      t('Email sent'),
      t('Error sending email'),
      true,
      sendEmailMutation.mutateAsync(email, {
        onSuccess: () => {
          setSendEmail(false);
          setSendingEmail(false);
        }
      })
    );
  }

  function zoomOut() {
    setScale(scale - 0.1);
  }

  function zoomIn() {
    setScale(scale + 0.1);
  }

  function rotateLeft() {
    setRotation(rotation - 90);
  }

  function rotateRight() {
    setRotation(rotation + 90);
  }

  function onPageClickDown(event: any) {
    const ele = pageRef.current;

    ele.style.cursor = 'grabbing';
    ele.style.userSelect = 'none';

    pos = {
      // The current scroll
      left: ele.scrollLeft,
      top: ele.scrollTop,
      // Get the current mouse position
      x: event.clientX,
      y: event.clientY
    };

    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
  }

  function mouseMoveHandler(e: any) {
    // How far the mouse has been moved
    const dx = e.clientX - pos.x;
    const dy = e.clientY - pos.y;

    ele.scrollTop = pos.top - dy;
    ele.scrollLeft = pos.left - dx;
  }

  function mouseUpHandler() {
    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('mouseup', mouseUpHandler);

    ele.style.cursor = 'grab';
    ele.style.removeProperty('user-select');
  }

  const sendEmailMutation = useMutation({
    mutationFn: async (obj: EmailMessageModel) => {
      await sendEmailMessage(obj);
    }
  });

  useEffect(() => {
    const element = pageRef.current;
    element?.addEventListener('mousedown', onPageClickDown);

    return () => {
      element?.removeEventListener('mousedown', onPageClickDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageRef.current]);

  return (
    <>
      <Box>
        <Grid container>
          <Grid item xs>
            <Stack direction={'row'} spacing={3}>
              {showDownload && (
                <IconButton aria-label="Download" disabled={!loaded || sendingEmail} onClick={downloadFile}>
                  <DownloadIcon></DownloadIcon>
                </IconButton>
              )}
              {showEmail && (
                <IconButton aria-label="Email" disabled={!loaded} onClick={emailFile}>
                  <EmailIcon></EmailIcon>
                </IconButton>
              )}
              {showPrint && (
                <IconButton aria-label="Print" disabled={!loaded || sendingEmail} onClick={printFile}>
                  <PrintIcon></PrintIcon>
                </IconButton>
              )}
            </Stack>
          </Grid>

          <Grid item xs={6} sx={{ paddingLeft: 15, display: 'flex', flex: 1, justifyContent: 'center' }}>
            {numPages && numPages > 0 && (
              <IconButton value="Previous" disabled={pageNumber <= 1} onClick={previousPage}>
                <ChevronLeftIcon></ChevronLeftIcon>
              </IconButton>
            )}

            <VigoTypography
              sx={{ paddingTop: 2, paddingLeft: 12, width: 150 }}
              value={numPages && numPages > 0 ? `Page ${pageNumber} of ${numPages}` : ''}
              disableTranslation></VigoTypography>

            {numPages && numPages > 0 && (
              <IconButton value="Next" disabled={pageNumber >= (numPages as number)} onClick={nextPage}>
                <ChevronRightIcon></ChevronRightIcon>
              </IconButton>
            )}
          </Grid>

          <Grid item xs sx={{ paddingRight: 10, display: 'flex', flex: 1, justifyContent: 'right' }}>
            <Box
              sx={{
                zIndex: 100,
                display: 'flex'
              }}>
              <IconButton aria-label="zoomout" onClick={zoomOut}>
                <ZoomOutIcon></ZoomOutIcon>
              </IconButton>
              <IconButton aria-label="zoomin" onClick={zoomIn}>
                <ZoomInIcon></ZoomInIcon>
              </IconButton>
              {!fullScreen && (
                <IconButton aria-label="fullscreen" onClick={handleOpen}>
                  <FullscreenIcon></FullscreenIcon>
                </IconButton>
              )}
              <IconButton aria-label="rotateLeft" onClick={rotateLeft}>
                <RotateLeftIcon></RotateLeftIcon>
              </IconButton>
              <IconButton aria-label="rotateRight" onClick={rotateRight}>
                <RotateRightIcon></RotateRightIcon>
              </IconButton>
            </Box>
          </Grid>
        </Grid>
        {sendEmail ? (
          <>
            <Grid item xs={12} className={styles.viewerControls__email_field}>
              <VigoTextInput
                name="EmailAddress"
                label="Email Address"
                value={''}
                sx={{ height: '40px' }}
                fullWidth
                InputProps={{ sx: { height: '40px' } }}
                onChange={e => setEmailAddress(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} className={styles.viewerControls__email_Buttons}>
              <Stack
                direction={'row'}
                className={styles.viewerControls__email_Buttons_stack}
                sx={{ marginBottom: '10px' }}>
                <VigoButton
                  name="cancel"
                  value="Cancel"
                  variant="outlined"
                  sx={
                    theme.palette.mode === 'dark'
                      ? { marginRight: '10px', color: '#dedede', borderColor: '#dedede !important' }
                      : { marginRight: '10px' }
                  }
                  endIcon={<CancelIcon></CancelIcon>}
                  onClick={cancelEmail}></VigoButton>
                <VigoButton
                  name="send"
                  value="Send"
                  variant="outlined"
                  sx={
                    theme.palette.mode === 'dark'
                      ? emailAddress.length > 6
                        ? { color: '#dedede', borderColor: '#dedede !important' }
                        : {}
                      : {}
                  }
                  endIcon={<SendIcon></SendIcon>}
                  disabled={emailAddress.length <= 6}
                  onClick={sendPdfEmail}></VigoButton>
              </Stack>
            </Grid>
          </>
        ) : (
          <></>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',

          width: '100%',
          height: '90%'
        }}>
        <Document
          file={'data:application/pdf;base64, ' + fileBase64}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={e => console.log(e)}
          loading={<VigoSpinner name="pdfDocumentSpinner" />}
          className={`${fileBase64 ? 'pdf-document' : ''}`}
          inputRef={pageRef}>
          <Page
            pageNumber={pageNumber}
            renderTextLayer={false}
            scale={scale}
            renderInteractiveForms={false}
            renderAnnotationLayer={false}
            rotate={rotation}
            loading={<VigoSpinner name="pdfPageSpinner" />}
          />
        </Document>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <>
          <Box
            sx={{
              backgroundColor: '#efefef',
              display: 'flex',
              height: '92%',
              top: '50px',
              justifyContent: 'center',
              position: 'absolute',
              left: `${
                settings.navCollapsed ? (settings.navHidden ? '60px' : '20px') : settings.navHidden ? '0px' : '260px'
              }`,
              width: `calc(100% - ${
                settings.navCollapsed ? (settings.navHidden ? '80px' : '40px') : settings.navHidden ? '0px' : '280px'
              })`
            }}>
            <Box>
              <Stack
                direction={'row'}
                sx={{ display: 'flex', justifyContent: 'space-between', paddingBottom: 3, paddingTop: 3 }}>
                <IconButton
                  aria-label="zoomout"
                  sx={{ color: '#212121' }}
                  onClick={() => {
                    setModalScale(modalScale - 0.1);
                  }}
                  className={styles.fullscreen_toolBar_iconButton}>
                  <ZoomOutIcon></ZoomOutIcon>
                </IconButton>
                <IconButton
                  aria-label="zoomin"
                  sx={{ color: '#212121' }}
                  onClick={() => {
                    setModalScale(modalScale + 0.1);
                  }}
                  className={styles.fullscreen_toolBar_iconButton}>
                  <ZoomInIcon></ZoomInIcon>
                </IconButton>
                <IconButton
                  sx={{ color: '#212121' }}
                  value="Previous"
                  disabled={modalPageNumber <= 1}
                  onClick={() => setModalPageNumber(prev => prev - 1)}>
                  <ChevronLeftIcon></ChevronLeftIcon>
                </IconButton>
                <VigoTypography
                  disableTranslation
                  sx={{ marginTop: 2, color: '#212121' }}
                  value={numPages && numPages > 0 ? `Page ${modalPageNumber} of ${numPages}` : ''}></VigoTypography>
                <IconButton
                  value="Next"
                  sx={{ color: '#212121' }}
                  disabled={modalPageNumber >= (numPages as number)}
                  onClick={() => setModalPageNumber(prev => prev + 1)}>
                  <ChevronRightIcon></ChevronRightIcon>
                </IconButton>
                <IconButton
                  aria-label="Download"
                  sx={{ color: '#212121' }}
                  disabled={!loaded || sendingEmail}
                  onClick={downloadFile}
                  className={styles.fullscreen_toolBar_iconButton}>
                  <DownloadIcon></DownloadIcon>
                </IconButton>
                <IconButton
                  aria-label="Print"
                  disabled={!loaded || sendingEmail}
                  sx={{ color: '#212121' }}
                  onClick={printFile}
                  className={styles.fullscreen_toolBar_iconButton}>
                  <PrintIcon></PrintIcon>
                </IconButton>
                <IconButton
                  aria-label="Print"
                  sx={{ position: 'absolute', right: '0px', color: '#212121' }}
                  onClick={handleClose}>
                  <CloseIcon></CloseIcon>
                </IconButton>
              </Stack>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: `calc(100% - ${
                settings.navCollapsed ? (settings.navHidden ? '80px' : '40px') : settings.navHidden ? '0px' : '280px'
              })`,
              height: '85%',
              top: '105px',
              left: `${
                settings.navCollapsed ? (settings.navHidden ? '60px' : '20px') : settings.navHidden ? '0px' : '260px'
              }`,
              position: 'fixed'
            }}>
            <>
              <Document
                file={'data:application/pdf;base64, ' + fileBase64}
                onLoadSuccess={onDocumentLoadSuccess}
                className={`${fileBase64 ? 'pdf-document' : ''}`}
                onLoadError={e => console.log(e)}
                loading={<VigoSpinner name="pdfDocumentSpinner 2" />}>
                <Page
                  pageNumber={modalPageNumber}
                  renderTextLayer={false}
                  scale={modalScale}
                  renderInteractiveForms={false}
                  renderAnnotationLayer={false}
                  loading={<VigoSpinner name="pdfPageSpinner 2" />}
                  {...(pageWidth && { width: pageWidth })}
                />
              </Document>
            </>
          </Box>
        </>
      </Modal>
    </>
  );
};
