import { useState, useEffect, ChangeEvent } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { useVigoTranslation } from 'src/@core/hooks/useVigoTranslation';
import React from 'react';
import { Close } from 'mdi-material-ui';
import { VigoTypography } from '../vigo-typography';
import { ISelectInput, VigoSelectOption } from './types';

const InputContainer = styled('div')({
  display: 'flex',
  alignItems: 'flex-start'
});

export const VigoSelectBox = React.forwardRef(
  (
    {
      label,
      placeholder,
      datasource,
      disabled,
      fullWidth,
      displayName,
      sx,
      name,
      helperText,
      required,
      error,
      errormessage,
      value,
      onChange,
      inputProps,
      variant = 'outlined',
      InputLabelProps = {},
      defaultValue = undefined,
      InputProps,
      customMenuOptionSx,
      iconSx
    }: ISelectInput,
    ref
  ) => {
    const [selectdatasource, setSelectdatasource] = useState<VigoSelectOption[]>([]);
    const [selectValue, setSelectValue] = useState<string>('');

    const { t } = useVigoTranslation();

    function getFieldValue(source: any, column: any): string {
      if (!source) {
        return '';
      }

      let returnval = source[column];
      if (returnval === null || returnval === undefined) {
        returnval = '';
      }

      return returnval;
    }

    if (!displayName || displayName == '') {
      displayName = 'description';
    }

    function buildDropdown(datasource: any[]) {
      let code = 0;
      const newOpts: VigoSelectOption[] = [];

      if (datasource?.length > 0) {
        if (required) {
          if (datasource.length == 1) {
            setSelectValue(String(datasource[0].id));
            newOpts.push({
              id: datasource[0].id,
              key: code,
              value: getFieldValue(datasource[0], displayName),
              disabled: false
            });
          } else {
            for (let index = 0; index < datasource.length; index++) {
              newOpts.push({
                id: datasource[index].id,
                key: code++,
                value: getFieldValue(datasource[index], displayName),
                disabled: datasource[index].disabled
              });
            }
          }
        } else {
          for (let index = 0; index < datasource.length; index++) {
            newOpts.push({
              id: datasource[index].id,
              key: code++,
              value: getFieldValue(datasource[index], displayName),
              disabled: datasource[index].disabled
            });
          }
        }
      }

      return newOpts;
    }

    useEffect(() => {
      setSelectValue(value ?? '');
    }, [value]);

    useEffect(() => {
      setSelectdatasource(buildDropdown(datasource));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [datasource]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      onChange?.(e);
      setSelectValue(e.target.value);
    };

    return (
      <InputContainer sx={sx}>
        <TextField
          inputProps={{
            className: 'vigo-hintstep-' + name + ' ',
            ...inputProps
          }}
          defaultValue={defaultValue}
          placeholder={placeholder}
          required={required}
          onKeyUp={event => {
            if (event.key == 'Delete') {
              const element = event as unknown as ChangeEvent<HTMLInputElement>;
              //@ts-ignore
              event.target.value = -1;
              setSelectValue('');
              onChange?.(element);
            }
          }}
          label={t(label)}
          select
          inputRef={ref}
          autoComplete="off"
          error={error}
          value={selectValue}
          variant={variant}
          disabled={disabled}
          helperText={error ? t(errormessage) : t(helperText)}
          onChange={handleChange}
          fullWidth={fullWidth}
          size="small"
          name={name}
          sx={{ width: '100%', padding: '0px', ...customMenuOptionSx }}
          InputProps={{
            ...(Boolean(!disabled) && {
              ...(Boolean(selectValue) && {
                endAdornment: !required && (
                  <IconButton
                    onClick={event => {
                      const element = event as unknown as ChangeEvent<HTMLInputElement>;
                      //@ts-ignore
                      event.target.value = '';
                      setSelectValue('');
                      onChange?.(element);
                    }}
                    sx={{ mr: '10px', ...iconSx }}>
                    <Close />
                  </IconButton>
                )
              })
            }),
            ...InputProps
          }}
          InputLabelProps={InputLabelProps}>
          {selectdatasource?.map(item => (
            <MenuItem key={item.key} value={item.id} disabled={item.disabled}>
              <VigoTypography value={item.value} disableTranslation sx={customMenuOptionSx} />
            </MenuItem>
          ))}
        </TextField>
      </InputContainer>
    );
  }
);
