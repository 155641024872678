import { useAuth } from 'react-oidc-context';

export const useGetProductLevel = () => {
  const auth = useAuth();

  const user = auth.user;
  const loggedInUserId = user?.profile.tenantUserId as string;

  let product = user?.profile.product;

  // check if we are already impersonating a tenant
  const fromSession = typeof window !== 'undefined' && window.sessionStorage.getItem(`impersonate-${loggedInUserId}`);
  if (fromSession) {
    product = JSON.parse(fromSession).productLevel;
    if (product === '3') {
      product = 'myportal';
    }
    if (product === '2') {
      product = 'flow';
    }
  }

  if (!user) {
    return 0;
  }

  switch (product) {
    case 'quantum':
      return Products.Quantum;

    case 'fusion':
      return Products.Fusion;

    case 'flow':
      return Products.Flow;

    case 'myportal':
      return Products.MyPortal;

    default:
      return Products.Unknown;
  }
};

export enum Products {
  Quantum = '0',
  Fusion = '1',
  Flow = '2',
  MyPortal = '3',
  Unknown = '9'
}
