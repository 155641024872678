// ** Type Imports
import { PaletteMode } from '@mui/material';
import { Skin, ThemeColor } from 'src/@core/layouts/types';
import { Products, useGetProductLevel } from 'src/@core/utils/getProductLevel';

const DefaultPalette = (mode: PaletteMode, skin: Skin, themeColor: ThemeColor) => {
  // ** Vars
  const lightColor = '0, 0, 0';
  const darkColor = '231, 227, 252';
  const mainColor = mode === 'light' ? lightColor : darkColor;
  const prod = useGetProductLevel();

  const primaryGradient = () => {
    if (themeColor === 'primary') {
      switch (prod) {
        case Products.Fusion:
          //#32cd32
          return '#C04CFD';

        case Products.Flow:
          return '#009fe3';

        // #32cd32
        case Products.MyPortal:
          return '#21D0B2';

        default:
          return '#32cd32';
      }
    } else if (themeColor === 'secondary') {
      return '#9C9FA4';
    } else if (themeColor === 'success') {
      return '#93DD5C';
    } else if (themeColor === 'error') {
      return '#FF8C90';
    } else if (themeColor === 'warning') {
      return '#FFCF5C';
    } else {
      return '#6ACDFF';
    }
  };

  const primaryLightBackground = () => {
    if (themeColor === 'primary') {
      switch (prod) {
        case Products.Fusion:
          return '#EBFAEB';

        case Products.Flow:
          return '#E5F5FC';

        // #32cd32
        case Products.MyPortal:
          return '#21D0B27A';

        default:
          return '#ff0066';
      }
    } else if (themeColor === 'secondary') {
      return '#9C9FA4';
    } else if (themeColor === 'success') {
      return '#93DD5C';
    } else if (themeColor === 'error') {
      return '#FF8C90';
    } else if (themeColor === 'warning') {
      return '#FFCF5C';
    } else {
      return '#6ACDFF';
    }
  };

  const defaultBgColor = () => {
    if (mode === 'light') {
      return '#ECECF0';
    } else return '#121212';
  };

  return {
    vigoColors: {
      light: '#E30613',
      primaryLight: mode === 'light' ? '#2B2D42' : '#1E1E1E',
      primaryDark: '#141421',
      secondaryLight: 'rgba(0,122,156,0.1)',
      secondaryDark: '#007a9c',
      gray: '#acacac'
    },
    customColors: {
      dark: darkColor,
      main: mainColor,
      light: lightColor,
      darkBg: '#141C23',
      lightBg: '#141C23',
      primaryGradient: primaryGradient(),
      bodyBg: mode === 'light' ? '#F4F5FA' : '#141C23',
      tableHeaderBg: mode === 'light' ? '#F9FAFC' : '#1E1E1E',
      primaryLightBackground: primaryLightBackground(),
      ligherBackground: mode === 'light' ? 'eff1f9' : '#1E1E1E'
    },
    common: {
      black: '#000',
      white: '#FFF'
    },
    mode: mode,
    primary: {
      light: mode === 'light' ? '#686C8D' : '#70C993',
      main: mode === 'light' ? '#2B2D42' : '#1E1E1E',
      dark: mode === 'light' ? '#1F2138' : primaryGradient(),
      contrastText: '#FFF'
    },
    secondary: {
      light: mode === 'light' ? '#979EC5' : '#C4CACC',
      main: mode === 'light' ? '#6E759F' : '#a2a8ab',
      dark: mode === 'light' ? '#505788' : '#768793',
      contrastText: '#FFF'
    },
    success: {
      light: '#60CA5D',
      main: '#2fa836',
      dark: '#229032',
      contrastText: '#FFF'
    },
    error: {
      light: '#F03C4A',
      main: '#e70028',
      dark: '#C60035',
      contrastText: '#FFF'
    },
    warning: {
      light: '#FFC152',
      main: '#ffa319',
      dark: '#DB8212',
      contrastText: '#FFF'
    },
    info: {
      light: '#3BC4EE',
      main: '#009fe3',
      dark: '#007BC3',
      contrastText: '#FFF'
    },
    grey: {
      50: '#FAFAFA',
      100: '#F5F5F5',
      200: '#EEEEEE',
      300: '#E0E0E0',
      400: '#BDBDBD',
      500: '#9E9E9E',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
      A100: '#D5D5D5',
      A200: '#AAAAAA',
      A400: '#616161',
      A700: '#303030'
    },
    text: {
      primary: `rgba(${mainColor}, 0.87)`,
      secondary: `rgba(${mainColor}, 0.68)`,
      disabled: `rgba(${mainColor}, 0.38)`
    },
    divider: `rgba(${mainColor}, 0.12)`,
    background: {
      paper: mode === 'light' ? '#FFF' : '#373737',
      default: defaultBgColor()
    },
    action: {
      active: `rgba(${mainColor}, 0.54)`,
      hover: `rgba(${mainColor}, 0.04)`,
      selected: `rgba(${mainColor}, 0.08)`,
      disabled: `rgba(${mainColor}, 0.3)`,
      disabledBackground: `rgba(${mainColor}, 0.18)`,
      focus: `rgba(${mainColor}, 0.12)`
    }
  };
};

export default DefaultPalette;
