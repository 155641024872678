import { Box, Typography } from '@mui/material';
import { NotePlusOutline } from 'mdi-material-ui';
import { VigoTypography } from 'src/@vigo/vigo-typography';
import styles from '../../vigofiledrop.module.css';

interface DropZoneBackgroundProps {
  required: boolean | undefined;
  dropzoneTextColor: string;
  description: string;
  maxFileSize?: number;
  acceptedFileTypes?: Record<string, string[]>;
}

const DropZoneBackground = ({
  required,
  dropzoneTextColor,
  description,
  maxFileSize,
  acceptedFileTypes
}: DropZoneBackgroundProps) => {
  return (
    <>
      {required && (
        <VigoTypography
          value="* required"
          sx={{
            position: 'absolute',
            left: '5px',
            top: '5px',
            color: theme => (theme.palette.mode === 'light' ? '#626679' : '#fff')
          }}
        />
      )}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          flex: 1,
          padding: '30px 20px 0px 20px'
        }}>
        <NotePlusOutline sx={{ color: dropzoneTextColor, width: '1.429rem' }} className={styles.addFilesIcon} />
        <VigoTypography value={description} sx={{ color: dropzoneTextColor, fontSize: '1.143rem' }} />
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          width: '100%',
          padding: '15px 20px 15px 20px'
        }}>
        {maxFileSize && (
          <Typography
            sx={{
              color: theme => (theme.palette.mode === 'light' ? '#626679' : '#fff'),
              fontSize: '0.857rem'
            }}>
            <strong>Max file size:</strong> {maxFileSize}MB.
          </Typography>
        )}
        {acceptedFileTypes && (
          <Typography
            sx={{
              color: theme => (theme.palette.mode === 'light' ? '#626679' : '#fff'),
              fontSize: '0.857rem'
            }}>
            <strong>Accepted file types: </strong> {Object.values(acceptedFileTypes).flat().join(', ')}
          </Typography>
        )}
      </Box>
    </>
  );
};

export default DropZoneBackground;
