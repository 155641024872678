// ** MUI Components
import { styled } from '@mui/material/styles';
import { VigoTypography } from 'src/@vigo/vigo-typography';
import Box from '@mui/material/Box';
import { Button, Stack } from '@mui/material';
import { useLogging } from '../hooks/useLogging';

const Img = styled('img')(({ theme }) => ({
  marginBottom: theme.spacing(10),
  [theme.breakpoints.down('lg')]: {
    height: 450,
    marginTop: theme.spacing(10)
  },
  [theme.breakpoints.down('md')]: {
    height: 400
  },
  [theme.breakpoints.up('lg')]: {
    marginTop: theme.spacing(13)
  }
}));

const ErrorBoundaryRender = ({ resetErrorBoundary }: { error: Error; resetErrorBoundary: () => any }) => {
  return (
    <Box sx={{ height: '100%' }}>
      <Stack direction="column" sx={{ p: 5, alignItems: 'center', textAlign: 'left' }}>
        <Img height="338" alt="error-illustration" src="/images/pages/404.svg" />
        <Stack direction="column">
          <VigoTypography value="Whoops" variant="h2" sx={{ fontWeight: 600 }} />
          <VigoTypography
            value="An internal error occurred"
            variant="h5"
            disableTranslation
            sx={{ fontWeight: 500, mb: 1, fontSize: '1.5rem !important' }}
          />

          <Button variant="contained" sx={{ marginTop: '20px' }} onClick={resetErrorBoundary}>
            Try again
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export const useErrorBoundaryLog = () => {
  const { logMessage, flushLogs } = useLogging();

  const logError = (error: Error, info: { componentStack: string }) => {
    logMessage('Error', error.message, {
      error: error,
      info: info
    });

    flushLogs();
  };

  return logError;
};

export default ErrorBoundaryRender;
