import { PayloadAction } from '@reduxjs/toolkit';
import { UIFlowState } from '..';

const pushNavigationStack = (state: UIFlowState, action: PayloadAction<string>) => {
  state.flowNavigationState.flowNavigationStack.push(action.payload);
};

const popNavigationStack = (state: UIFlowState) => {
  state.flowNavigationState.flowNavigationStack.pop();
};

const setFlowDataIdArray = (state: UIFlowState, action: PayloadAction<string[]>) => {
  state.flowNavigationState.flowDataIdArray = action.payload;
};

export const flowNavigationReducer = {
  pushNavigationStack,
  popNavigationStack,
  setFlowDataIdArray
};
