import { AxiosRequestConfig } from 'axios';
import {
  AccountApiKeyModel,
  AccountDetailDto,
  AccountDto,
  AccountDtoApiResponse,
  AccountHolderModel,
  AccountImageDto,
  AccountImageDtoApiResponse,
  AccountImageModel,
  AccountImageTypeDto,
  AccountMetricDto,
  AccountModel,
  AccountPermissionDto,
  AccountPermissionsModel,
  AccountSubscriptionsDto,
  AccountSubscriptionsModel,
  BaseModel,
  ConfigurationDto,
  DashboardComponentDto,
  DashboardComponentModel,
  DashboardDto,
  DashboardObjectDto,
  DashboardWidgetDto,
  DeleteMenuItemQueryParams,
  DepartmentDto,
  DistributionGroupDto,
  DistributionGroupExternalMemberDto,
  DistributionGroupExternalMemberDtoApiResponseListApiResponse,
  DistributionGroupExternalMemberModelBulkItemModel,
  DistributionGroupInternalMemberDto,
  DistributionGroupInternalMemberDtoApiResponseListApiResponse,
  DistributionGroupInternalMemberModelBulkItemModel,
  DistributionGroupNewsArticleDto,
  DistributionGroupNewsArticleDtoApiResponseListApiResponse,
  DistributionGroupNewsArticleModelBulkItemModel,
  GetAccountByidOnboardedQueryParams,
  GetAccountByidQueryParams,
  GetAccountByidSubscriptionsBysubscriptionIdKeyQueryParams,
  GetAccountByidSubscriptionsQueryParams,
  GetAccountCompanyLogoQueryParams,
  GetAccountDetailByidQueryParams,
  GetAccountDetailQueryParams,
  GetAccountImageQueryParams,
  GetAccountImageTypeQueryParams,
  GetAccountMetricsQueryParams,
  GetAccountQueryParams,
  GetDashboardObjectBydashboardIdQueryParams,
  GetDashboardQueryParams,
  GetDashboardWidgetQueryParams,
  GetDepartmentQueryParams,
  GetDistributionGroupExternalMemberQueryParams,
  GetDistributionGroupInternalMemberQueryParams,
  GetDistributionGroupNewsArticleQueryParams,
  GetDistributionGroupQueryParams,
  GetMenuByidQueryParams,
  GetMenuItemByidQueryParams,
  GetMenuItemComponentByidQueryParams,
  GetMenuItemComponentQueryParams,
  GetMenuQueryParams,
  GetMyAccountDetailsSubscriptionsBysubscriptionIdKeyQueryParams,
  GetMyAccountDetailsSubscriptionsQueryParams,
  GetNetworkQueryParams,
  GetNewsArticleByidQueryParams,
  GetNewsArticleQueryParams,
  GetNewsArticleTemplateByidQueryParams,
  GetNewsArticleTemplateQueryParams,
  GetProductLicenceScopeQueryParams,
  GetProductLicenceTypeQueryParams,
  GetProductQueryParams,
  GetRoleByidPermissionsQueryParams,
  GetRoleByidQueryParams,
  GetRoleQueryParams,
  GetSubAccountByidDetailsQueryParams,
  GetSubAccountByidOnboardedQueryParams,
  GetSubAccountByidQueryParams,
  GetSubAccountByidSubscriptionsQueryParams,
  GetSubscriptionByidQueryParams,
  GetSubscriptionQueryParams,
  MenuDto,
  MenuDtoApiResponse,
  MenuItemComponentDto,
  MenuItemDto,
  MenuItemDtoApiResponse,
  MenuItemDtoApiResponseListApiResponse,
  MenuItemModel,
  MenuItemModelBulkItemModel,
  MenuModel,
  NetworkDto,
  NewsArticleDto,
  NewsArticleDtoApiResponse,
  NewsArticleListItemDto,
  NewsArticleModel,
  NewsArticleTemplateDto,
  NewsArticleTemplateDtoApiResponse,
  NewsArticleTemplateModel,
  NewsFeedDto,
  PermissionDto,
  PermissionModel,
  ProductDto,
  ProductLicenceScopeDto,
  ProductLicenceTypeDto,
  ProductPermissionDto,
  PutMenuItemQueryParams,
  RoleDto,
  RoleDtoApiResponse,
  RoleModel,
  RolePermissionsDtoApiResponse,
  SubscriptionAppKeyDto,
  SubscriptionDto
} from 'src/models/account';
import { GetAxiosInstance } from '../baseService';
import { ServiceLocation } from '../serviceLocationsAxios';
import { PutPermissionModel } from 'src/types/uiflow/roleTypes';

export const getAccountConfiguration = async (roleId: any, options: Record<string, any> = {}) => {
  return GetAxiosInstance().get<ConfigurationDto>(ServiceLocation.account + '/Configuration' + '/' + roleId, options);
};

/**
 * Get Accounts
 * @param partnerId: CustomerId
 * @param params GetAccountQueryParams
 * @returns AccountDto[]
 */
export const getAccounts = async (partnerId?: string, params?: GetAccountQueryParams) => {
  return GetAxiosInstance(undefined, undefined, partnerId).get<AccountDto[]>(ServiceLocation.account + '/Account', {
    params
  });
};

/**
 * Get Account by Idg
 * @param id Id of the Account
 * @param params GetAccountByidQueryParams
 * @returns AccountDto
 */
export const getAccountById = async (
  id: string,
  params?: GetAccountByidQueryParams,
  partnerId?: string,
  tenantId?: string
) => {
  return GetAxiosInstance(undefined, tenantId, partnerId).get<AccountDto>(ServiceLocation.account + '/Account/' + id, {
    params
  });
};

/**
 * Get Account by Id
 * @param id Id of the Account
 * @param params GetAccountByidQueryParams
 * @returns AccountDto
 */
export const getMyAccountById = async () => {
  return GetAxiosInstance().get<AccountDto>(ServiceLocation.account + '/MyAccount/');
};

/**
 * Post Account
 * @param model AccountModel
 * @returns AccountDtoApiResponse
 */
export const postAccount = async (model: AccountModel) => {
  return GetAxiosInstance().post<AccountDtoApiResponse>(ServiceLocation.account + '/Account', model);
};

/**
 * Patch Account
 * @param model AccountModel
 * @returns AccountDtoApiResponse
 */
export const patchAccount = async (model: AccountModel, accountId: string) => {
  return GetAxiosInstance().patch<AccountDtoApiResponse>(
    ServiceLocation.account + '/Account/' + accountId + '/accountholder',
    model
  );
};

/**
 * Seed defaults for account Account
 */
export const patchDefaults = async (accountId: string) => {
  return GetAxiosInstance(undefined, accountId).patch<boolean>(ServiceLocation.account + '/Account/seedptkdefaults');
};

/**
 * Get account images
 * @param params GetAccountImageQueryParams
 * @returns AccountImageDto[]
 */
export const getAccountImages = async (params?: GetAccountImageQueryParams) => {
  return GetAxiosInstance().get<AccountImageDto[]>(ServiceLocation.account + '/AccountImage', { params });
};

/**
 * Get account image types
 * @param params GetAccountImageTypeQueryParams
 * @returns AccountImageTypeDto[]
 */
export const getAccountImageTypes = async (params?: GetAccountImageTypeQueryParams) => {
  return GetAxiosInstance().get<AccountImageTypeDto[]>(ServiceLocation.account + '/AccountImageType', { params });
};

/**
 * Get Subscriptions
 * @param partnerId CustomerId
 * @param params GetSubscriptionQueryParams
 * @returns SubscriptionDto[]
 */
export const getSubscriptions = async (partnerId?: string, params?: GetSubscriptionQueryParams) => {
  return GetAxiosInstance(undefined, undefined, partnerId).get<SubscriptionDto[]>(
    ServiceLocation.account + '/Subscription',
    { params }
  );
};

/**
 * Get Account Subscription by Id
 * @param id Id of the Account
 * @param params GetSubscriptionByidQueryParams
 * @returns SubscriptionDto
 */
export const getAccountSubscriptionById = async (id: string, params?: GetSubscriptionByidQueryParams) => {
  return GetAxiosInstance().get<SubscriptionDto>(ServiceLocation.account + '/Subscription/' + id, { params });
};

export const getAccountMetrics = async (id: string, params?: GetAccountMetricsQueryParams) => {
  return GetAxiosInstance().get<AccountMetricDto>(ServiceLocation.account + '/Account/' + id + '/metrics', { params });
};

/**
 * Get Account Products
 * @param params GetProductQueryParams
 * @returns ProductDto[]
 */
export const getAccountProducts = async (params?: GetProductQueryParams) => {
  return GetAxiosInstance().get<ProductDto[]>(ServiceLocation.account + '/Product', { params });
};

//Get Account Details
export const getAccountDetails = async (params?: GetAccountDetailQueryParams) => {
  return GetAxiosInstance().get<AccountDetailDto[]>(ServiceLocation.account + '/AccountDetail', { params });
};

//Get Account Details
export const getMyAccountDetails = async (params?: GetAccountDetailQueryParams) => {
  return GetAxiosInstance().get<AccountDetailDto[]>(ServiceLocation.account + '/MyAccountDetail', { params });
};

// Get Account Api Key
export const getAccountSubscriptionKey = async (
  id: string,
  subscriptionId: string,
  params?: GetAccountByidSubscriptionsBysubscriptionIdKeyQueryParams,
  partnerId?: string
) => {
  return GetAxiosInstance(undefined, undefined, partnerId).get<SubscriptionAppKeyDto>(
    ServiceLocation.account + '/Account/' + id + '/subscriptions/' + subscriptionId + '/key',
    { params }
  );
};

/**
 * Get Account Detail by Id
 * @param id If of the Account
 * @param partnerId CustomerId
 * @param params GetAccountDetailByidQueryParams
 * @returns AccountDetailDto
 */
export const getAccountDetailById = async (
  id: string,
  partnerId?: string,
  params?: GetAccountDetailByidQueryParams
) => {
  return GetAxiosInstance(undefined, undefined, partnerId).get<AccountDetailDto>(
    ServiceLocation.account + '/AccountDetail/' + id,
    { params }
  );
};

/**
 * Get Account Detail by Id
 * @param id If of the Account
 * @param partnerId CustomerId
 * @param params GetAccountDetailByidQueryParams
 * @returns AccountDetailDto
 */
export const getMyAccountDetail = async () => {
  return GetAxiosInstance().get<AccountDetailDto>(ServiceLocation.account + '/MyAccountDetails/');
};

//Put Account Detail
export const putAccountDetail = async (params: AccountModel, accountId: string) => {
  return GetAxiosInstance().put(ServiceLocation.account + '/AccountDetail/' + accountId, params);
};

//Get Menu
export const getMenus = async (params?: GetMenuQueryParams) => {
  return GetAxiosInstance().get<MenuDto[]>(ServiceLocation.account + '/Menu', { params });
};

//Get Menu By Id
export const getMenuById = async (id: string, params?: GetMenuByidQueryParams) => {
  return GetAxiosInstance().get<MenuDto>(ServiceLocation.account + '/Menu/' + id, { params });
};

//Update Menu
export const putMenuById = async (id: string, model: MenuModel) => {
  return GetAxiosInstance().put<MenuDtoApiResponse>(ServiceLocation.account + '/Menu/' + id, model);
};

//Post Menu
export const postMenu = async (model: MenuModel) => {
  return GetAxiosInstance().post<MenuDtoApiResponse>(ServiceLocation.account + '/Menu', model);
};

//Delete Menu
export const deleteMenuById = async (id: string, model: { remarks: string; version: string }) => {
  return GetAxiosInstance().delete<MenuDtoApiResponse>(ServiceLocation.account + '/Menu/' + id, {
    data: model as AxiosRequestConfig
  });
};

//Get Menu Items
export const getMenuItems = async (params: GetMenuQueryParams) => {
  return GetAxiosInstance().get<MenuItemDto[]>(ServiceLocation.account + '/MenuItem', { params });
};

//Get Menu Item by Id
export const getMenuItemById = async (id: string, params?: GetMenuItemByidQueryParams) => {
  return GetAxiosInstance().get<MenuItemDto>(ServiceLocation.account + '/MenuItem/' + id, { params });
};

//Post Menu Item
export const postMenuItem = async (model: MenuItemModel) => {
  return GetAxiosInstance().post<MenuItemDtoApiResponse>(ServiceLocation.account + '/MenuItem', model);
};

//Bulk Put/Post Menu Items
export const bulkPutMenuItems = async (models: MenuItemModelBulkItemModel[], params?: PutMenuItemQueryParams) => {
  return GetAxiosInstance().put<MenuItemDtoApiResponseListApiResponse>(ServiceLocation.account + '/MenuItem', models, {
    params
  });
};

//Put Menu Item
export const putMenuItemById = (id: string, model: MenuItemModel) => {
  return GetAxiosInstance().put<MenuItemDtoApiResponse>(ServiceLocation.account + '/MenuItem/' + id, model);
};

//Bulk Delete Menu Items
export const bulkDeleteMenuItems = async (models: MenuItemModelBulkItemModel[], params?: DeleteMenuItemQueryParams) => {
  const reqBody: AxiosRequestConfig<MenuItemModelBulkItemModel[]> = {
    data: models,
    params: params
  };

  return GetAxiosInstance().delete<MenuItemDtoApiResponseListApiResponse>(
    ServiceLocation.account + '/MenuItem',
    reqBody
  );
};

//Delete Menu Item by Id
export const deleteMenuItemById = async (id: string, model: { remarks: string; version: string }) => {
  return GetAxiosInstance().delete(ServiceLocation.account + '/MenuItem/' + id, {
    data: model as AxiosRequestConfig
  });
};

// get MenuItemComponents
export const getMenuItemComponents = async (params?: GetMenuItemComponentQueryParams) => {
  return GetAxiosInstance().get<MenuItemComponentDto[]>(ServiceLocation.account + '/MenuItemComponent', { params });
};

// get MenuItemComponent by id
export const getMenuItemComponentById = async (id: string, params?: GetMenuItemComponentByidQueryParams) => {
  return GetAxiosInstance(
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    true
  ).get<MenuItemComponentDto>(ServiceLocation.account + '/MenuItemComponent/' + id, { params });
};

/**
 * Get Roles
 * @param partnerId CustomerId
 * @param params GetRoleQueryParams
 * @returns RoleDto[]
 */
export const getRoles = async (params?: GetRoleQueryParams, tenantId?: string, partnerId?: string) => {
  return GetAxiosInstance(undefined, tenantId, partnerId).get<RoleDto[]>(ServiceLocation.account + '/Role', {
    params
  });
};

/**
 * Get Role By Id
 * @param roleId Role Id
 * @param params GetRoleByidQueryParams
 * @returns RoleDto
 */
export const getRoleById = async (roleId: string, params?: GetRoleByidQueryParams) => {
  return GetAxiosInstance().get<RoleDto>(ServiceLocation.account + '/Role/' + roleId, { params });
};

//Get Default Dashboard for product
export const getDefaultDashboardForProduct = async (product: string) => {
  return GetAxiosInstance().get<DashboardDto>(ServiceLocation.account + '/Dashboard/default?product=' + product);
};

/**
 * Get Role Menu By Menu Id
 * @param menuId Menu Id
 * @param params GetRoleMenubymenuIdQueryParams
 * @returns RoleMenuDto
 */
export const getRoleMenuByMenuId = async (menuId: string, params?: any) => {
  return GetAxiosInstance().get<any>(ServiceLocation.account + '/RoleMenu/' + menuId, { params });
};

/**
 * Post Role
 * @param params RoleDetailsModel
 * @returns RoleDtoApiResponse
 */
export const postRole = async (params: RoleModel) => {
  return GetAxiosInstance().post<RoleDtoApiResponse>(ServiceLocation.account + '/Role', params);
};

/**
 * Put Role
 * @param params RoleDetailsModel
 * @returns RoleDtoApiResponse
 */
export const putRole = async (params: RoleModel, id: string) => {
  return GetAxiosInstance().put<RoleDtoApiResponse>(ServiceLocation.account + '/Role/' + id, params);
};

/**
 * Get Role Component Permissions
 * @param params GetPermissionQueryParams
 * @returns PermissionDto[]
 */
export const getRoleComponentPermissions = async (params?: any) => {
  return GetAxiosInstance().get<PermissionDto[]>(ServiceLocation.account + '/Permission', { params });
};

/**
 * Post Role Component Permission
 * @param params PermissionModel
 * @returns
 */
export const postRoleComponentPermission = async (params: PermissionModel) => {
  return GetAxiosInstance().post(ServiceLocation.account + '/Permission', params);
};

/**
 * Put Role Component Permission
 * @param params PutPermissionModel[]
 * @returns
 */
export const putRoleComponentPermission = async (params: PutPermissionModel[]) => {
  return GetAxiosInstance().put(ServiceLocation.account + '/Permission', params);
};

/**
 * Get Departments Lookup
 * @returns DepartmentDto[]
 */
export const getDepartments = async (params?: GetDepartmentQueryParams) => {
  return GetAxiosInstance().get<DepartmentDto[]>(ServiceLocation.account + '/Department', { params });
};

/**
 * Get all dashboards
 *
 * @param params GetDashboardQueryParams
 * @returns DashboardDto[]
 */
export const getDashboards = async (params?: GetDashboardQueryParams) => {
  return GetAxiosInstance().get<DashboardDto[]>(ServiceLocation.account + '/Dashboard', { params });
};

/**
 * Get dashboard by id
 *
 * @param id string
 * @param params GetDashboardQueryParams
 * @returns DashboardDto
 */
export const getDashboardById = (id: string, params?: GetDashboardQueryParams) => {
  return GetAxiosInstance().get<DashboardDto>(ServiceLocation.account + '/Dashboard/' + id, { params });
};

/**
 * Get dashboard object by id
 *
 * @param id string
 * @param params GetDashboardObjectBydashboardIdQueryParams
 * @returns
 */
export const getDashboardObjectById = (id: string, params?: GetDashboardObjectBydashboardIdQueryParams) => {
  return GetAxiosInstance().get<DashboardObjectDto>(ServiceLocation.account + '/DashboardObject/' + id, { params });
};

/**
 * Get dashboard widgets
 *
 * @param params GetDashboardWidgetQueryParams
 * @returns
 */
export const getDashboardWidgets = async (params?: GetDashboardWidgetQueryParams) => {
  return GetAxiosInstance(undefined, undefined, undefined, undefined, undefined, undefined, true).get<
    DashboardWidgetDto[]
  >(ServiceLocation.account + '/DashboardWidget', { params });
};

/**
 * Update dashboard by id
 *
 * @param id
 * @param params
 * @returns
 */
export const updateDashboardById = (id: string, params: { data: DashboardDto }) => {
  return GetAxiosInstance().put(ServiceLocation.account + '/Dashboard/' + id, params.data);
};

/**
 * Update a dashboard component by id
 *
 * @param id
 * @param params
 * @returns
 */
export const updateDashboardComponentById = (id: string, params: { data: DashboardComponentDto }) => {
  return GetAxiosInstance().put(ServiceLocation.account + '/DashboardComponent/' + id, params.data);
};

/**
 * Save a new dashboard
 *
 * @param params
 * @returns
 */
export const postDashboard = (params: { data: DashboardDto }) => {
  return GetAxiosInstance().post(ServiceLocation.account + '/Dashboard', params.data);
};

/**
 * Save new dashboard component
 *
 * @param params
 * @returns
 */
export const postDashboardComponent = (params: { data: DashboardComponentModel }) => {
  return GetAxiosInstance().post(ServiceLocation.account + '/DashboardComponent', params.data);
};

/**
 * Delete dashboard component by id
 *
 * @param id
 * @param params
 * @returns
 */
export const deleteDashboardComponentById = (id: string, params: { remarks: string; version: string }) => {
  return GetAxiosInstance().delete(ServiceLocation.account + '/DashboardComponent/' + id, {
    data: params as AxiosRequestConfig
  });
};

/**
 * Get Newsfeed for Dashboard
 * @returns NewsFeedDto[]
 */
export const getNewsfeed = () => {
  return GetAxiosInstance().get<NewsFeedDto[]>(ServiceLocation.account + '/NewsFeed');
};

/**
 * Get Newsfeed Articles
 * @param params GetNewsArticleQueryParams
 * @returns NewsArticleListItemDto[]
 */
export const getNewsfeedArticles = (params?: GetNewsArticleQueryParams) => {
  return GetAxiosInstance().get<NewsArticleListItemDto[]>(ServiceLocation.account + '/NewsArticle', {
    params
  });
};

/**
 * Delete Newsfeed Article
 * @param id Id of the Newsfeed Article
 * @param model Delete Model
 * @returns NewsArticleDtoApiResponse
 */
export const deleteNewsfeedArticle = (id: string, model: any) => {
  const reqBody: AxiosRequestConfig<any> = {
    data: model
  };

  return GetAxiosInstance().delete<NewsArticleDtoApiResponse>(ServiceLocation.account + '/NewsArticle/' + id, reqBody);
};

/**
 * Get News Article by Id
 * @param newsArticleId Id of the Newsfeed Article
 * @param params GetNewsArticleByidQueryParams
 * @returns NewsArticleDto
 */
export const getNewsArticleById = async (newsArticleId: string, params?: GetNewsArticleByidQueryParams) => {
  return GetAxiosInstance().get<NewsArticleDto>(ServiceLocation.account + '/NewsArticle/' + newsArticleId, { params });
};

/**
 * Post News Article
 * @param params NewsArticleModel
 * @returns NewsArticleDtoApiResponse
 */
export const postNewsArticle = async (params: NewsArticleModel) => {
  return GetAxiosInstance().post<NewsArticleDtoApiResponse>(ServiceLocation.account + '/NewsArticle', params);
};

/**
 * Put News Article
 * @param params NewsArticleModel
 * @param newsArticleId Id of the Newsfeed Article
 * @returns NewsArticleDtoApiResponse
 */
export const putNewsArticle = async (params: NewsArticleModel, newsArticleId: string) => {
  return GetAxiosInstance().put<NewsArticleDtoApiResponse>(
    ServiceLocation.account + '/NewsArticle/' + newsArticleId,
    params
  );
};

/**
 * Get Distribution Groups
 * @param params GetDistributionGroupQueryParams
 * @returns DistributionGroupDto[]
 */
export const getDistributionGroups = async (params?: GetDistributionGroupQueryParams) => {
  return GetAxiosInstance().get<DistributionGroupDto[]>(ServiceLocation.account + '/DistributionGroup', { params });
};

/**
 * Get Distribution Group External Members
 * @param params GetDistributionGroupExternalMemberQueryParams
 * @returns DistributionGroupExternalMemberDto[]
 */
export const getDistributionGroupExternalMembers = async (params?: GetDistributionGroupExternalMemberQueryParams) => {
  return GetAxiosInstance().get<DistributionGroupExternalMemberDto[]>(
    ServiceLocation.account + '/DistributionGroupExternalMember',
    { params }
  );
};

/**
 * Bulk Put Distribution Group External Members
 * @param models DistributionGroupExternalMemberModelBulkItemModel[]
 * @returns DistributionGroupExternalMemberDtoApiResponseListApiResponse
 */
export const bulkPutDistributionGroupExternalMembers = async (
  models?: DistributionGroupExternalMemberModelBulkItemModel[]
) => {
  return GetAxiosInstance().put<DistributionGroupExternalMemberDtoApiResponseListApiResponse>(
    ServiceLocation.account + '/DistributionGroupExternalMember',
    models
  );
};

/**
 * Bulk Delete Distribution Group External Members
 * @param models DistributionGroupExternalMemberModelBulkItemModel[]
 * @returns DistributionGroupExternalMemberDtoApiResponseListApiResponse
 */
export const bulkDeleteDistributionGroupExternalMembers = async (
  models?: DistributionGroupExternalMemberModelBulkItemModel[]
) => {
  const reqBody: AxiosRequestConfig<DistributionGroupExternalMemberModelBulkItemModel[]> = {
    data: models
  };

  return GetAxiosInstance().delete<DistributionGroupExternalMemberDtoApiResponseListApiResponse>(
    ServiceLocation.account + '/DistributionGroupExternalMember',
    reqBody
  );
};

/**
 * Get Distribution Group Internal Members
 * @param params GetDistributionGroupInternalMemberQueryParams
 * @returns DistributionGroupInternalMemberDto[]
 */
export const getDistributionGroupInternalMembers = async (params?: GetDistributionGroupInternalMemberQueryParams) => {
  return GetAxiosInstance().get<DistributionGroupInternalMemberDto[]>(
    ServiceLocation.account + '/DistributionGroupInternalMember',
    { params }
  );
};

/**
 * Bulk Put Distribution Group Internal Members
 * @param models DistributionGroupInternalMemberModelBulkItemModel[]
 * @returns DistributionGroupInternalMemberDtoApiResponseListApiResponse
 */
export const bulkPutDistributionGroupInternalMembers = async (
  models: DistributionGroupInternalMemberModelBulkItemModel[]
) => {
  return GetAxiosInstance().put<DistributionGroupInternalMemberDtoApiResponseListApiResponse>(
    ServiceLocation.account + '/DistributionGroupInternalMember',
    models
  );
};

/**
 * Bulk Delete Distribution Group Internal Members
 * @param models DistributionGroupInternalMemberModelBulkItemModel[]
 * @returns DistributionGroupInternalMemberDtoApiResponseListApiResponse
 */
export const bulkDeleteDistributionGroupInternalMembers = async (
  models: DistributionGroupInternalMemberModelBulkItemModel[]
) => {
  const reqBody: AxiosRequestConfig<DistributionGroupInternalMemberDtoApiResponseListApiResponse[]> = {
    data: models
  };

  return GetAxiosInstance().delete<DistributionGroupExternalMemberDtoApiResponseListApiResponse>(
    ServiceLocation.account + '/DistributionGroupInternalMember',
    reqBody
  );
};

/**
 * Get Distribution Group News Articles
 * @param params GetDistributionGroupNewsArticleQueryParams
 * @returns DistributionGroupNewsArticleDto[]
 */
export const getDistributionGroupNewsArticles = async (params?: GetDistributionGroupNewsArticleQueryParams) => {
  return GetAxiosInstance().get<DistributionGroupNewsArticleDto[]>(
    ServiceLocation.account + '/DistributionGroupNewsArticle',
    { params }
  );
};

/**
 * Bulk Put Distribution Group News Articles
 * @param models DistributionGroupNewsArticleModelBulkItemModel[]
 * @returns DistributionGroupNewsArticleDtoApiResponseListApiResponse
 */
export const bulkPutDistributionGroupNewsArticles = async (
  models: DistributionGroupNewsArticleModelBulkItemModel[]
) => {
  return GetAxiosInstance().put<DistributionGroupNewsArticleDtoApiResponseListApiResponse>(
    ServiceLocation.account + '/DistributionGroupNewsArticle',
    models
  );
};

/**
 * Bulk Delete Distribution Group News Articles
 * @param models DistributionGroupNewsArticleModelBulkItemModel[]
 * @returns DistributionGroupNewsArticleDtoApiResponseListApiResponse
 */
export const bulkDeleteDistributionGroupNewsArticles = async (
  models: DistributionGroupNewsArticleModelBulkItemModel[]
) => {
  const reqBody: AxiosRequestConfig<DistributionGroupNewsArticleModelBulkItemModel[]> = {
    data: models
  };

  return GetAxiosInstance().delete<DistributionGroupNewsArticleDtoApiResponseListApiResponse>(
    ServiceLocation.account + '/DistributionGroupNewsArticle',
    reqBody
  );
};

/**
 * Resend onboarding invite
 * @param id Id of the account being onboarded
 * @param tenantId Same as Id
 * @param partnerId Same as Id
 * @returns
 */
export const putResendInvite = async (id: string) => {
  return GetAxiosInstance(undefined).put<any>(ServiceLocation.account + '/Account/' + id + '/resendinvite');
};

/**
 * Continue provisioning (used if onboarding has potentially got stuck)
 * @param id Id of the account being onboarded
 * @returns
 */
export const putContinueProvisioning = async (id: string) => {
  return GetAxiosInstance(undefined).put<boolean>(ServiceLocation.account + '/Account/' + id + '/continue');
};

export const postAccountImage = async (model: AccountImageModel) => {
  return GetAxiosInstance().post<AccountImageDtoApiResponse>(ServiceLocation.account + '/AccountImage', model);
};

export const putAccountImage = async (id: string, model: AccountImageModel) => {
  return GetAxiosInstance().put<AccountImageDtoApiResponse>(ServiceLocation.account + '/AccountImage/' + id, model);
};

/**
 * Get News Article Templates
 * @param params GetNewsArticleTemplateQueryParams
 * @returns NewsArticleTemplateDto[]
 */
export const getNewsArticleTemplates = async (params?: GetNewsArticleTemplateQueryParams) => {
  return GetAxiosInstance().get<NewsArticleTemplateDto[]>(ServiceLocation.account + '/NewsArticleTemplate', { params });
};

/**
 * Get News Article Template by Id
 * @param id Id of the News Article Template
 * @param params GetNewsArticleTemplateByidQueryParams
 * @returns NewsArticleTemplateDto
 */
export const getNewsArticleTemplateById = async (id: string, params?: GetNewsArticleTemplateByidQueryParams) => {
  return GetAxiosInstance().get<NewsArticleTemplateDto>(ServiceLocation.account + '/NewsArticleTemplate/' + id, {
    params
  });
};

/**
 * Post News Article Template
 * @param model NewsArticleTemplateModel
 * @returns NewsArticleTemplateDtoApiResponse
 */
export const postNewsArticleTemplate = async (model: NewsArticleTemplateModel) => {
  return GetAxiosInstance().post<NewsArticleTemplateDtoApiResponse>(
    ServiceLocation.account + '/NewsArticleTemplate',
    model
  );
};

/**
 * Put News Article Template
 * @param id Id of the News Article Template
 * @param model NewsArticleTemplateModel
 * @returns NewsArticleTemplateDtoApiResponse
 */
export const putNewsArticleTemplate = async (id: string, model: NewsArticleTemplateModel) => {
  return GetAxiosInstance().put<NewsArticleTemplateDtoApiResponse>(
    ServiceLocation.account + '/NewsArticleTemplate/' + id,
    model
  );
};

/**
 * Update Account Subscriptions
 * @param id Id of the Account
 * @param model AccountSubscriptionsModel
 * @returns AccountSubscriptionsDto[]
 */
export const updateSubscriptions = async (id: string, model: AccountSubscriptionsModel) => {
  return GetAxiosInstance().put<AccountSubscriptionsDto[]>(
    ServiceLocation.account + '/Account/' + id + '/subscriptions',
    model
  );
};

//Get Account Permissions
export const getAccountPermissions = async (id: string, partnerId?: string, params?: GetAccountDetailQueryParams) => {
  return GetAxiosInstance(undefined, undefined, partnerId).get<AccountPermissionDto[]>(
    ServiceLocation.account + '/AccountDetail/' + id + '/permissions',
    { params }
  );
};

/**
 * Get Account Subscriptions
 * @param id Id of the Account
 * @param model AccountSubscriptionsModel
 * @returns AccountSubscriptionsDto[]
 */
export const getAccountSubscriptions = async (params?: any) => {
  return GetAxiosInstance().get<SubscriptionDto[]>(ServiceLocation.account + '/Subscription', { params });
};

/**
 * Get Account Products Permissions
 * @param params GetProductQueryParams
 * @returns ProductDto[]
 */
export const getProductPermissions = async (id: string, params?: GetProductQueryParams) => {
  return GetAxiosInstance().get<ProductPermissionDto>(ServiceLocation.account + '/Product/' + id + '/permissions', {
    params
  });
};

/**
 * Get Account Products Permissions
 * @param params GetProductQueryParams
 * @returns ProductDto[]
 */
export const getAccountOnBoarded = async (id: string) => {
  return GetAxiosInstance().get<boolean>(ServiceLocation.account + '/Account/' + id + '/onboarded', {});
};

/**
 * Bulk Put Account Permissions
 * @param models AccountPermissionsModel[]
 * @returns DistributionGroupNewsArticleDtoApiResponseListApiResponse
 */
export const bulkPutAccountPermissions = async (
  id: string,
  models: AccountPermissionsModel,
  tenantId?: string,
  partnerId?: string
) => {
  return GetAxiosInstance(undefined, tenantId, partnerId).put<AccountPermissionsModel>(
    ServiceLocation.account + '/AccountDetail/' + id + '/permissions',
    models
  );
};

/**
 * Get Account Subscriptions by Account Id
 * @param id Id of the Account
 * @param params GetAccountByidSubscriptionsQueryParams
 * @returns SubscriptionDto[]
 */
export const getAccountSubscriptionsByAccountId = async (
  id: string,
  params?: GetAccountByidSubscriptionsQueryParams,
  tenantId?: string,
  partnerId?: string
) => {
  return GetAxiosInstance(undefined, tenantId, partnerId).get<SubscriptionDto[]>(
    ServiceLocation.account + '/Account/' + id + '/subscriptions',
    {
      params
    }
  );
};

/**
 * Get Networks
 * @param params GetNetworkQueryParams
 * @returns NetworkDto[]
 */
export const getNetworks = async (params?: GetNetworkQueryParams) => {
  return GetAxiosInstance().get<NetworkDto[]>(ServiceLocation.account + '/Network', { params });
};

/**
 * Get Account Onboarded
 * @param id Id of the Account
 * @param params GetAccountByidOnboardedQueryParams
 * @returns boolean
 */
export const getAccountOnboarded = async (id: string, params?: GetAccountByidOnboardedQueryParams) => {
  return GetAxiosInstance().get<boolean>(ServiceLocation.account + '/Account/' + id + '/onboarded', { params });
};

/**
 *
 * @param id Id of the role
 * @param params GetRoleByidPermissionsQueryParams
 * @returns PermissionDto[]
 */
export const getRoleByIdPermissions = (
  id: string,
  params?: GetRoleByidPermissionsQueryParams,
  tenantId?: string,
  partnerId?: string
) => {
  return GetAxiosInstance(undefined, tenantId, partnerId).get<PermissionDto[]>(
    ServiceLocation.account + '/Role/' + id + '/permissions',
    { params }
  );
};

/**
 *
 * @param id Id of the role
 * @param data PermissionDto[]
 * @returns
 */
export const putRoleByIdPermissions = (id: string, data: { permissions: PermissionModel[] }) => {
  return GetAxiosInstance().put<RolePermissionsDtoApiResponse>(
    ServiceLocation.account + '/Role/' + id + '/permissions',
    data
  );
};

/**
 * Get Sub Account Onboarded
 * @param id Id of the Sub Account
 * @param params GetSubAccountByidOnboardedQueryParams
 * @returns boolean
 */
export const getSubAccountOnboarded = (id: string, params?: GetSubAccountByidOnboardedQueryParams) => {
  return GetAxiosInstance().get<boolean>(ServiceLocation.account + '/SubAccount/' + id + '/onboarded', { params });
};

/**
 * Get Sub Account Subscriptions
 * @param id Id of the Sub Account
 * @param params GetSubAccountByidSubscriptionsQueryParams
 * @returns SubscriptionDto[]
 */
export const getSubAccountSubscriptions = (id: string, params?: GetSubAccountByidSubscriptionsQueryParams) => {
  return GetAxiosInstance().get<SubscriptionDto[]>(ServiceLocation.account + '/SubAccount/' + id + '/subscriptions', {
    params
  });
};

/**
 * Get Sub Account by Id
 * @param id Id of the Sub Account
 * @param params GetSubAccountByidQueryParams
 * @returns AccountDto
 */
export const getSubAccountInformation = (id: string, params?: GetSubAccountByidQueryParams) => {
  return GetAxiosInstance().get<AccountDto>(ServiceLocation.account + '/SubAccount/' + id, { params });
};

/**
 * Get Sub Account Details
 * @param id Id of the Sub Account
 * @param params GetSubAccountByidDetailsQueryParams
 * @returns AccountDetailDto
 */
export const getSubAccountDetails = (id: string, params?: GetSubAccountByidDetailsQueryParams) => {
  return GetAxiosInstance().get<AccountDetailDto>(ServiceLocation.account + '/SubAccount/' + id + '/details', {
    params
  });
};

/**
 * Get Product Licence Types
 * @param params GetProductLicenceTypeQueryParams
 * @returns ProductLicenceTypeDto[]
 */
export const getProductLicenceTypes = (params?: GetProductLicenceTypeQueryParams) => {
  return GetAxiosInstance().get<ProductLicenceTypeDto[]>(ServiceLocation.account + '/ProductLicenceType', { params });
};

/**
 * Get Product Licence Scopes
 * @param params GetProductLicenceScopeQueryParams
 * @returns ProductLicenceScopeDto[]
 */
export const getProductLicenceScopes = (params?: GetProductLicenceScopeQueryParams) => {
  return GetAxiosInstance().get<ProductLicenceScopeDto[]>(ServiceLocation.account + '/ProductLicenceScope', { params });
};

/**
 * Get My Account Details Subscriptions
 * @param params GetMyAccountDetailsSubscriptionsQueryParams
 * @returns SubscriptionDto[]
 */
export const getMyAccountDetailsSubscriptions = (params?: GetMyAccountDetailsSubscriptionsQueryParams) => {
  return GetAxiosInstance().get<SubscriptionDto[]>(ServiceLocation.account + '/MyAccountDetails/subscriptions', {
    params
  });
};

/**
 * Get My Account Details Subscriptions by Subscription Id Key
 * @param subscriptionId Id of the Subscription
 * @param params GetMyAccountDetailsSubscriptionsBysubscriptionIdKeyQueryParams
 * @returns SubscriptionAppKeyDto
 */
export const getMyAccountDetailsSubscriptionKey = (
  subscriptionId: string,
  params?: GetMyAccountDetailsSubscriptionsBysubscriptionIdKeyQueryParams,
  partnerId?: string
) => {
  return GetAxiosInstance(undefined, undefined, partnerId).get<SubscriptionAppKeyDto>(
    ServiceLocation.account + '/MyAccountDetails/subscriptions/' + subscriptionId + '/key',
    { params }
  );
};

/**
 * Get Company Logo from Subdomain
 * @param params GetAccountCompanyLogoQueryParams
 * @returns string
 */
export const getCompanyLogoFromSubdomain = (params: GetAccountCompanyLogoQueryParams) => {
  return GetAxiosInstance().get<string>(ServiceLocation.account + '/Account/CompanyLogo', { params });
};

/**
 * Patch account holder (update account holder details)
 * @param id Id of the account
 * @param model AccountHolderModel
 * @returns
 */
export const patchAccountHolder = (id: string, model: AccountHolderModel) => {
  return GetAxiosInstance().patch<AccountDto>(ServiceLocation.account + '/Account/' + id + '/accountholder', model);
};

/**
 * Get Product Installer
 * @param product Product Name e.g implant
 * @param file Installer File Name e.g Setup.msi
 * @param params GetProductQueryParams
 * @returns
 */
export const getProductInstaller = (product: string, file: string, params?: GetProductQueryParams) => {
  return GetAxiosInstance(undefined, undefined, undefined, undefined, 'blob').get(
    `${ServiceLocation.account}/Product/installers/${product}/${file}`,
    { params }
  );
};

/**
 * Patch Accept Subscription Terms
 * @param subscriptionId Id of the Subscription
 * @param accountId Id of the Account
 * @param model BaseModel
 * @returns SubscriptionDto
 */
export const patchAcceptSubscriptionTerms = (subscriptionId: string, accountId: string, model?: BaseModel) => {
  return GetAxiosInstance().patch<SubscriptionDto>(
    ServiceLocation.account + '/Account/' + accountId + '/subscriptions/' + subscriptionId + '/accept',
    model
  );
};

/**
 * Put Subscription by Id
 *
 * @param id
 * @param model
 * @returns
 */
export const putSubscriptionById = (id: string, model: SubscriptionDto) => {
  return GetAxiosInstance().put<SubscriptionDto>(ServiceLocation.account + '/Subscription/' + id, model);
};

/**
 * Patch Account Subscription Key
 *
 * @param accountId Id of the account
 * @param subscriptionId Id of the subscription
 * @param model AccountApiKeyModel
 * @returns SubscriptionAppKeyDto
 */
export const patchAccountSubscriptionKey = (accountId: string, subscriptionId: string, model: AccountApiKeyModel) => {
  return GetAxiosInstance().patch<SubscriptionAppKeyDto>(
    ServiceLocation.account + '/Account/' + accountId + '/subscriptions/' + subscriptionId + '/key',
    model
  );
};
