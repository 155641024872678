import { ServiceLocation } from 'src/services/serviceLocationsAxios';
import mock from '../mock';
import {
  categories,
  entityArray,
  eventsArray,
  exampleWorkflowItems,
  propertyArray,
  eventProperties,
  actions,
  actionProperties
} from './data';

mock.onGet(`${ServiceLocation.workFlowData}`).reply(() => {
  return [200, { workFlowItems: exampleWorkflowItems, categories }];
});

mock.onGet(`${ServiceLocation.workFlowData}/entityEventData`).reply(() => {
  return [
    200,
    {
      entities: entityArray,
      entityProperties: propertyArray,
      events: eventsArray,
      eventProperties,
      eventActions: actions,
      actionProperties
    }
  ];
});

mock.onPost(`${ServiceLocation.workFlowData}/save`).reply(function (config) {
  exampleWorkflowItems.push(JSON.parse(config.data));

  return [200, exampleWorkflowItems];
});

mock.onPut(new RegExp(`${ServiceLocation.workFlowData}/updateWorkFlow/*`)).reply(function (config) {
  const { url, data } = config;
  const regex = /\/workFlowData\/api\/v1\/updateWorkFlow\/(.*)/i;
  const match = url?.match(regex) || [0];

  const index = exampleWorkflowItems.findIndex(item => item.id === match[1]);
  const workFlow = exampleWorkflowItems[index];

  const dataKeys = Object.keys(JSON.parse(data));

  if (dataKeys.includes('displayName')) {
    workFlow.displayName = JSON.parse(data).displayName;
  }

  if (dataKeys.includes('categories')) {
    workFlow.categories = JSON.parse(data).categories;
  }

  index >= 0 && exampleWorkflowItems.splice(index, 1, workFlow);

  return [200, exampleWorkflowItems];
});

mock.onPut(new RegExp(`${ServiceLocation.workFlowData}/update/*`)).reply(function (config) {
  const { url, data } = config;
  const regex = /\/workFlowData\/api\/v1\/update\/(.*)/i;
  const match = url?.match(regex) || [0];

  const index = exampleWorkflowItems.findIndex(item => item.id === match[1]);

  index >= 0 && exampleWorkflowItems.splice(index, 1, JSON.parse(data));

  return [200, exampleWorkflowItems];
});

mock.onDelete(new RegExp(`${ServiceLocation.workFlowData}/delete/*`)).reply(function (config) {
  const { url } = config;
  const regex = /\/workFlowData\/api\/v1\/delete\/(.*)/i;
  const match = url?.match(regex) || [0];

  const index = exampleWorkflowItems.findIndex(item => item.id === match[1]);

  index >= 0 && exampleWorkflowItems.splice(index, 1);

  return [200, exampleWorkflowItems];
});
