// ** React Imports
import { ReactNode } from 'react';

// ** MUI Imports
import { PaletteMode, Direction } from '@mui/material';

// ** Icon Import
import MenuIcon from 'mdi-material-ui/Menu';

// ** Types
import { Skin, AppBar, Footer, ContentWidth, VerticalNavToggle, HorizontalMenuToggle } from 'src/@core/layouts/types';

type ThemeConfig = {
  skin: Skin;
  appBar: AppBar;
  footer: Footer;
  mode: PaletteMode;
  navHidden: boolean;
  appBarBlur: boolean;
  direction: Direction;
  templateName: string;
  navCollapsed: boolean;
  routingLoader: boolean;
  disableRipple: boolean;
  navigationSize: number;
  menuTextTruncate: boolean;
  navSubItemIcon: ReactNode;
  contentWidth: ContentWidth;
  disableCustomizer: boolean;
  responsiveFontSizes: boolean;
  collapsedNavigationSize: number;
  horizontalMenuAnimation: boolean;
  appShowDeleted: boolean;
  developerMode: boolean;
  layout: 'vertical' | 'horizontal';
  verticalNavToggleType: VerticalNavToggle;
  horizontalMenuToggle: HorizontalMenuToggle;
  toastPosition: 'top-left' | 'top-center' | 'top-right' | 'bottom-left' | 'bottom-center' | 'bottom-right';
  unlockDashboardLayout: boolean;
  compactType: 'vertical' | 'horizontal' | 'none';
  preventCollisions: boolean;
  allowOverlap: boolean;
  showPageHints: boolean;
  showUATRecords: boolean;
};

const themeConfig: ThemeConfig = {
  // ** Layout Configs
  templateName: 'VIGO' /* App Name */,
  layout: 'vertical' /* vertical | horizontal */,
  mode: 'light' /* light | dark */,
  direction: 'ltr' /* ltr | rtl */,
  skin: 'semi-dark' /* default | bordered | semi-dark /*! Note: semi-dark value will only work for Vertical Layout */,
  contentWidth: 'full' /* full | boxed */,
  footer: 'hidden' /* fixed | static | hidden */,

  // ** Routing Configs
  routingLoader: true /* true | false */,

  // ** Navigation (Menu) Configs
  navHidden: false /* true | false */,
  menuTextTruncate: true /* true | false */,
  navSubItemIcon: MenuIcon /* Icon Element */,
  verticalNavToggleType: 'accordion' /* accordion | collapse /*! Note: This is for Vertical navigation menu only */,
  navCollapsed: false /* true | false /*! Note: This is for Vertical navigation menu only */,
  navigationSize: 260 /* Number in PX(Pixels) /*! Note: This is for Vertical navigation menu only */,
  collapsedNavigationSize: 58 /* Number in PX(Pixels) /*! Note: This is for Vertical navigation menu only */,
  horizontalMenuToggle: 'hover' /* click | hover /*! Note: This is for Horizontal navigation menu only */,
  horizontalMenuAnimation: true /* true | false */,
  appShowDeleted: false /* show deleted records */,
  showUATRecords: false /* show UAT records */,
  developerMode: false /* show developer specific options */,

  // ** AppBar Configs
  appBar: 'fixed' /* fixed | static | hidden /*! Note: hidden value will only work for Vertical Layout */,
  appBarBlur: true /* true | false */,

  // ** Other Configs
  responsiveFontSizes: true /* true | false */,
  disableRipple: false /* true | false */,
  disableCustomizer: false /* true | false */,
  toastPosition: 'top-right' /* top-left | top-center | top-right | bottom-left | bottom-center | bottom-right */,

  // ** Dashboard Configs
  unlockDashboardLayout: false /* true | false */,
  compactType: 'vertical' /* vertical | horizontal | none */,
  preventCollisions: false /* true | false */,
  allowOverlap: false /* true | false */,

  // ** Intro JS
  showPageHints: false /* true | false */
};

export default themeConfig;
