import { createContext, Dispatch, ReactNode, useReducer } from 'react';

interface BreadcrumbsProviderProps {
  children: ReactNode;
}

interface Action {
  type: string;
  payload?: any;
}

export interface Breadcrumb {
  title: string;
  path: string;
}

interface BreadcrumbsContextValue {
  state: BreadcrumbsState;
  dispatch: Dispatch<Action>;
}

interface BreadcrumbsState {
  stack: Breadcrumb[];
}

const initialStateObject: BreadcrumbsState = {
  stack: []
};

const initialState: BreadcrumbsContextValue = {
  state: initialStateObject,
  dispatch: () => ({ type: '', payload: '' })
};

export const BreadcrumbsActionTypes = {
  ADD_TO_STACK: 'addToStack',
  ADD_MULTIPLE_TO_STACK: 'addMultipleToStack',
  OVERWRITE_BREADCRUMBS: 'overwriteBreadcrumbs',
  POP_FROM_STACK: 'popFromStack',
  REMOVE_BREADCRUMB_BY_TITLE: 'removeBreadcrumbByTitle',
  RENAME_BREADCRUMB_BY_TITLE: 'renameBreadcrumbByTitle'
};

const breadcrumbsReducer = (state: BreadcrumbsState, action: Action) => {
  const { type, payload } = action;

  switch (type) {
    case BreadcrumbsActionTypes.ADD_TO_STACK: {
      return { ...state, stack: [...state.stack, payload] };
    }
    case BreadcrumbsActionTypes.ADD_MULTIPLE_TO_STACK: {
      return { ...state, stack: [...state.stack, ...payload] };
    }
    case BreadcrumbsActionTypes.OVERWRITE_BREADCRUMBS: {
      return { ...state, stack: payload };
    }
    case BreadcrumbsActionTypes.POP_FROM_STACK: {
      const stackCopy = [...state.stack];
      stackCopy.pop();

      return { ...state, stack: stackCopy };
    }
    case BreadcrumbsActionTypes.REMOVE_BREADCRUMB_BY_TITLE: {
      const index = state.stack.findIndex(
        breadcrumb => breadcrumb.title.toLocaleLowerCase() === payload.toLocaleLowerCase()
      );

      if (index < 0) {
        return { ...state };
      }

      return { ...state, stack: [...state.stack.slice(0, index), ...state.stack.slice(index + 1)] };
    }
    case BreadcrumbsActionTypes.RENAME_BREADCRUMB_BY_TITLE: {
      const index = state.stack.findIndex(
        breadcrumb => breadcrumb.title.toLocaleLowerCase() === payload.oldTitle.toLocaleLowerCase()
      );

      if (index >= 0) {
        const stackCopy = [...state.stack];
        stackCopy[index].title = payload.newTitle;

        return { ...state, stack: stackCopy };
      }

      return state;
    }
    default: {
      return { ...state };
    }
  }
};

export const BreadcrumbsContext = createContext<BreadcrumbsContextValue>(initialState);

export const BreadcrumbsProvider = ({ children }: BreadcrumbsProviderProps) => {
  const [state, dispatch] = useReducer(breadcrumbsReducer, initialStateObject);
  const value = { state, dispatch };

  return <BreadcrumbsContext.Provider value={value}>{children}</BreadcrumbsContext.Provider>;
};
