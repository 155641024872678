import { PayloadAction } from '@reduxjs/toolkit';
import { UIFlowState } from '..';

const setAddTabStatuses = (state: UIFlowState, action: PayloadAction<any[]>) => {
  state.addModeTabsState.addTabsStatuses = action.payload;
};

const incrementAddTabStatuses = (state: UIFlowState) => {
  state.addModeTabsState.addTabsStatuses[state.activeTabState.activeTabIndex + 1].locked = false;
  state.activeTabState.activeTabIndex += 1;
};

export const addModeTabReducer = {
  setAddTabStatuses,
  incrementAddTabStatuses
};
