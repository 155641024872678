// ** React Imports
import { useState } from 'react';

// ** Third Party Components
import PerfectScrollbar from 'react-perfect-scrollbar';

// ** MUI Imports
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Box, { BoxProps } from '@mui/material/Box';
import MuiDrawer, { DrawerProps } from '@mui/material/Drawer';

// ** Icons Imports
import Close from 'mdi-material-ui/Close';
import CogOutline from 'mdi-material-ui/CogOutline';

// ** Type Import
import { Settings } from 'src/@core/context/settingsContext';

// ** Hook Import
import { useSettings } from 'src/@core/hooks/useSettings';
import { VigoTypography } from 'src/@vigo/vigo-typography';
import { Products, useGetProductLevel } from 'src/@core/utils/getProductLevel';

const Toggler = styled(Box)<BoxProps>(({ theme }) => ({
  right: 0,
  top: '50%',
  display: 'flex',
  cursor: 'pointer',
  position: 'fixed',
  padding: theme.spacing(2),
  zIndex: theme.zIndex.modal,
  transform: 'translateY(-50%)',
  backgroundColor: theme.palette.primary.main,
  borderTopLeftRadius: theme.shape.borderRadius,
  borderBottomLeftRadius: theme.shape.borderRadius
}));

const Drawer = styled(MuiDrawer)<DrawerProps>(({ theme }) => ({
  width: 400,
  zIndex: theme.zIndex.modal,
  '& .MuiFormControlLabel-root': {
    marginRight: '0.6875rem'
  },
  '& .MuiDrawer-paper': {
    border: 0,
    width: 400,
    zIndex: theme.zIndex.modal,
    boxShadow: theme.shadows[9]
  }
}));

const CustomizerSpacing = styled('div')(({ theme }) => ({
  padding: theme.spacing(5, 6)
}));

const Customizer = () => {
  // ** State
  const [open, setOpen] = useState<boolean>(false);

  const prod = useGetProductLevel();

  // ** Hook
  const { settings, saveSettings } = useSettings();

  // ** Vars
  const { appShowDeleted, showUATRecords } = settings;

  const handleChange = (field: keyof Settings, value: Settings[keyof Settings]): void => {
    saveSettings({ ...settings, [field]: value });
  };

  return (
    <div className="customizer">
      <Toggler className="customizer-toggler" onClick={() => setOpen(true)}>
        <CogOutline sx={{ height: 20, width: 20, color: 'common.white' }} />
      </Toggler>
      <Drawer open={open} hideBackdrop anchor="right" variant="persistent">
        <Box
          className="customizer-header"
          sx={{
            position: 'relative',
            p: theme => theme.spacing(3.5, 5),
            borderBottom: theme => `1px solid ${theme.palette.divider}`
          }}>
          <VigoTypography value="Configuration" variant="h6" sx={{ fontWeight: 600, textTransform: 'uppercase' }} />
          <VigoTypography value="Customise your experience" sx={{ color: 'text.secondary' }} />
          <IconButton
            onClick={() => setOpen(false)}
            sx={{
              right: 20,
              top: '50%',
              position: 'absolute',
              color: 'text.secondary',
              transform: 'translateY(-50%)'
            }}>
            <Close fontSize="small" />
          </IconButton>
        </Box>
        <PerfectScrollbar options={{ wheelPropagation: false }}>
          <CustomizerSpacing className="customizer-body">
            <VigoTypography
              value="Application Settings"
              component="p"
              variant="caption"
              sx={{ mb: 4, color: 'text.disabled', textTransform: 'uppercase' }}
            />

            <Box sx={{ mb: 4, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <VigoTypography value="Show UAT Records" />
              <Switch
                name="showUATRecords"
                checked={showUATRecords}
                onChange={e => handleChange('showUATRecords', e.target.checked)}
              />
            </Box>

            {prod != Products.MyPortal && (
              <Box sx={{ mb: 4, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <VigoTypography value="Show Deleted Records" />
                <Switch
                  name="appShowDeleted"
                  checked={appShowDeleted}
                  onChange={e => handleChange('appShowDeleted', e.target.checked)}
                />
              </Box>
            )}
          </CustomizerSpacing>
        </PerfectScrollbar>
      </Drawer>
    </div>
  );
};

export default Customizer;
