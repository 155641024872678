import { FlowDataEntry } from './../../../types/uiflow/uiflow-types';
import { ServiceLocation } from 'src/services/serviceLocationsAxios';

// ** Mock
import mock from 'src/@fake-db/mock';

export interface Company extends FlowDataEntry {
  companyName: string;
  site: string;
  address: {
    [key: string]: string;
  };
  telephone: string;
  licenceName: string;
  social: {
    [key: string]: string;
  };
  companyDetails: {
    [key: string]: string;
  };
  primaryContact: {
    name: string;
    title: string;
    telephone: string;
    email: string;
  };
}

export const defaultCompanyObject = {
  id: '',
  companyName: '',
  site: '',
  address: {
    company: '',
    department: '',
    line1: '',
    line2: '',
    line3: '',
    line4: '',
    city: '',
    postalCode: '',
    countryName: ''
  },
  telephone: '',
  licenceName: '',
  social: {
    website: '',
    linkedin: '',
    facebook: '',
    twitter: ''
  },
  companyDetails: {
    companyId: '',
    territory: '',
    market: '',
    numberVehicles: '',
    companyRegNumber: '',
    annualTurnover: '',
    netWorth: '',
    sicCode: '',
    NumberOfTrailers: '',
    competitor: '',
    tier1Network: '',
    tier2Network: '',
    tier3Network: '',
    networkType: '',
    vigoIndustryGroup: '',
    tmsPrimarySystem: '',
    wmsPrimary: '',
    wmsSize: '',
    status: '',
    dateAquired: ''
  },
  primaryContact: {
    name: '',
    title: '',
    telephone: '',
    email: ''
  }
};

export const data: { companies: Company[] } = {
  companies: [
    {
      id: '0',
      companyName: 'Acumen Automotive Logistics',
      site: 'Main',
      address: {
        company: 'Acumen Automotive Logistics',
        department: '',
        line1: 'Rowley Road',
        line2: '',
        line3: '',
        line4: '',
        city: 'Coventry',
        postalCode: 'CV3 4PY',
        countryName: 'United Kingdom'
      },
      telephone: '02476516840',
      licenceName: 'Test123',
      social: {
        website: 'www.acumen.com',
        linkedin: 'linked.in/acumen',
        facebook: 'facebook.com/acumen',
        twitter: 'twitter.com/acumen'
      },
      companyDetails: {
        companyId: 'ax12344',
        territory: 'vigoLive',
        market: 'southLondon',
        numberVehicles: '45',
        companyRegNumber: 'oi8988',
        annualTurnover: '4500000',
        netWorth: '4546537',
        sicCode: '56465',
        NumberOfTrailers: '25',
        competitor: 'abc',
        tier1Network: 'palletLine',
        tier2Network: 'palletTrack',
        tier3Network: 'tpn',
        networkType: 'own',
        vigoIndustryGroup: 'transport&warehousing',
        tmsPrimarySystem: 'abc',
        wmsPrimary: 'kjhdd',
        wmsSize: '65465163',
        status: 'active',
        dateAquired: 'Thu Aug 11 2022 00:00:00 GMT+0100 (British Summer Time)'
      },
      primaryContact: {
        name: 'Everett Clifton',
        title: 'Mr',
        telephone: '0124544454',
        email: 'test@email.com'
      },
      additionalData: {
        companyTest: 'Company Test One'
      }
    },
    {
      id: '1',
      companyName: 'Kirkpatrick Carriers',
      site: 'Main',
      address: {
        company: 'Kirkpatrick Carriers',
        department: '',
        line1: 'Arthur Street',
        line2: '',
        line3: '',
        line4: 'Lakeside',
        city: 'Redditch',
        postalCode: 'B98 4JY',
        countryName: 'United Kingdom'
      },
      telephone: '01527 523845',
      licenceName: 'Test123',
      social: {
        website: 'www.kirkpatrick.com',
        linkedin: 'linked.in/kirkpatrick',
        facebook: 'facebook.com/kirkpatrick',
        twitter: 'twitter.com/kirkpatrick'
      },
      companyDetails: {
        companyId: 'xm5567',
        territory: 'vigoLive',
        market: 'southLondon',
        numberVehicles: '45',
        companyRegNumber: 'hj77655',
        annualTurnover: '4500000',
        netWorth: '4546537',
        sicCode: '56465',
        NumberOfTrailers: '25',
        competitor: 'abc',
        tier1Network: 'palletLine',
        tier2Network: 'palletTrack',
        tier3Network: 'tpn',
        networkType: 'own',
        vigoIndustryGroup: 'transport&warehousing',
        tmsPrimarySystem: 'abc',
        wmsPrimary: 'kjhdd',
        wmsSize: '65465163',
        status: 'active',
        dateAquired: 'Thu Aug 11 2022 00:00:00 GMT+0100 (British Summer Time)'
      },
      primaryContact: {
        name: 'Owen Dixon',
        title: 'Mr',
        telephone: '01245489798',
        email: 'test2@email.com'
      },
      additionalData: {
        companyTest: 'Company Test Two'
      }
    },
    {
      id: '2',
      companyName: 'Avon Freight Group',
      site: 'Main',
      address: {
        company: 'Avon Freight Group',
        department: '',
        line1: 'Hemming Road',
        line2: '',
        line3: '',
        line4: 'Washford',
        city: 'Redditch',
        postalCode: 'B98 0DN',
        countryName: 'United Kingdom'
      },
      telephone: '01527838500',
      licenceName: 'Test123',
      social: {
        website: 'www.avonfreight.com',
        linkedin: 'linked.in/avonfreight',
        facebook: 'facebook.com/avonfreight',
        twitter: 'twitter.com/avonfreight'
      },
      companyDetails: {
        companyId: 'zz1234',
        territory: 'vigoLive',
        market: 'southLondon',
        numberVehicles: '45',
        companyRegNumber: 'f767th',
        annualTurnover: '4500000',
        netWorth: '4546537',
        sicCode: '56465',
        NumberOfTrailers: '25',
        competitor: 'abc',
        tier1Network: 'palletLine',
        tier2Network: 'palletTrack',
        tier3Network: 'tpn',
        networkType: 'own',
        vigoIndustryGroup: 'transport&warehousing',
        tmsPrimarySystem: 'abc',
        wmsPrimary: 'kjhdd',
        wmsSize: '65465163',
        status: 'active',
        dateAquired: 'Thu Aug 11 2022 00:00:00 GMT+0100 (British Summer Time)'
      },
      primaryContact: {
        name: 'Gardenia Bourne',
        title: 'Miss',
        telephone: '012487989798',
        email: 'test3@email.com'
      },
      additionalData: {
        companyTest: 'Company Test Three'
      }
    }
  ]
};

// Get:
mock.onGet(`${ServiceLocation.uiflowdata}/company`).reply(() => {
  return [200, data.companies];
});

mock.onGet(new RegExp(`${ServiceLocation.uiflowdata}/company/*`)).reply(function (config) {
  const { url } = config;
  const regex = /\/uiflow\/api\/v1\/company\/(.*)/i;
  const match = url?.match(regex) || [0];

  const company = data.companies.filter(company => company.id == match[1]);

  return [200, company];
});

mock.onGet(`${ServiceLocation.widgetData}/companyCount`).reply(() => {
  const count = data.companies.length;

  return [200, count];
});

mock.onGet(new RegExp(`${ServiceLocation.uiflowdata}/additionalData/company/*`)).reply(config => {
  const { url } = config;
  const regex = /\/uiflow\/api\/v1\/additionalData\/company\/(.*)/i;
  const match = url?.match(regex) || [0];

  const company = data.companies.find(company => company.id == match[1]);

  if (!company) {
    return [500];
  }

  return [200, company.additionalData ?? {}];
});

// Post:
mock.onPost(`${ServiceLocation.uiflowdata}/company/save`).reply(function (config) {
  const { data: configData } = config;
  const dataObject = JSON.parse(configData);

  if (data.companies.some((company: Company) => company.id == dataObject.id)) {
    return [500, { message: 'company with ID already exists' }];
  }

  const newCompany = { ...defaultCompanyObject, ...dataObject };

  data.companies.push(newCompany);

  return [200, data.companies];
});

// Put:
mock.onPut(`${ServiceLocation.uiflowdata}/company/update`).reply(function (config) {
  const { data: configData } = config;
  const dataObject = JSON.parse(configData);

  const index = data.companies.findIndex((company: Company) => company.id == dataObject.id);

  if (index < 0) {
    return [500, { message: 'Cannot find company' }];
  }

  data.companies[index] = { ...data.companies[index], ...dataObject };

  return [200, data.companies];
});

// Delete:
mock.onDelete(new RegExp(`${ServiceLocation.uiflowdata}/company/delete/*`)).reply(function (config) {
  const { url } = config;
  const regex = /\/uiflow\/api\/v1\/company\/delete\/(.*)/i;
  const match = url?.match(regex) || [0];

  const index = data.companies.findIndex(item => item.id === match[1]);

  index >= 0 && data.companies.splice(index, 1);

  return [200, data.companies];
});
