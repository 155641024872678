import { publish, subscribe, VigoListener } from './vigoCustomEvent';

/**
 * Functions to handle panel events.
 *
 * Can be used within any component which is a panel and you need to call custom functionality after an event.
 * - Currently hanlding open & close events.
 * - Uses vigoCustomEvents.
 *
 */

export interface PanelEventPayload {
  panelName: string /* The component name of your panel. E.g 'AttachmentsPanel', 'StatusPanel' */;
  data?: any;
}

export const GLOBAL_PANEL_EVENT_NAME = 'global_panel_event';

export const subscribeToPanelEvent = (listener: VigoListener) => {
  return subscribe(GLOBAL_PANEL_EVENT_NAME, listener);
};

export const onPanelOpen = (payload: PanelEventPayload) => {
  publish(GLOBAL_PANEL_EVENT_NAME, { open: true, ...payload });
};

export const onPanelClose = (payload: PanelEventPayload) => {
  publish(GLOBAL_PANEL_EVENT_NAME, { open: false, ...payload });
};
