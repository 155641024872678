interface AccountKeyProps {
  productColor?: string;
}

const AccountKey = ({ productColor }: AccountKeyProps) => {
  return (
    <svg
      id="MDI_account-key-outline"
      data-name="MDI / account-key-outline"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24">
      <g id="Boundary" fill="#626679" stroke="rgba(0,0,0,0)" strokeWidth="1" opacity="0">
        <rect width="24" height="24" stroke="none" />
        <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
      </g>
      <path
        id="Path_account-key-outline"
        data-name="Path / account-key-outline"
        d="M5.83,10a3,3,0,1,0,0,2H8v2h2V12h1V10H5.83M3,12a1,1,0,1,1,1-1,1,1,0,0,1-1,1M16,4a4,4,0,1,0,4,4,4,4,0,0,0-4-4m0,6.1A2.1,2.1,0,1,1,18.1,8,2.1,2.1,0,0,1,16,10.1M16,13c-2.67,0-8,1.33-8,4v3H24V17c0-2.67-5.33-4-8-4m6.1,5.1H9.9V17c0-.64,3.1-2.1,6.1-2.1,2.97,0,6.1,1.46,6.1,2.1Z"
        fill="#626679"
      />
      <path
        id="Path_account-key-outline-2"
        data-name="Path / account-key-outline"
        d="M5.83,10a3,3,0,1,0,0,2H8v2h2V12h1V10H5.83M3,12a1,1,0,1,1,1-1,1,1,0,0,1-1,1"
        fill={productColor ?? '#009fe3'}
      />
    </svg>
  );
};

export default AccountKey;
