import { Box, useTheme } from '@mui/material';
import VigoLogo from 'src/@vigo/vigo-logo';

const ProfileFooter = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        paddingLeft: 4,
        paddingRight: 4,
        paddingTop: 3,
        paddingBottom: 3,
        backgroundColor: theme.palette.mode === 'dark' ? '#373737' : '#eee'
      }}>
      <VigoLogo displayVersion />
    </Box>
  );
};

export default ProfileFooter;
