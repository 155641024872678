import { useEffect } from 'react';

/**
 * Puts the Vigo graphic inside browser dev tools console.
 */
export const useConsoleMessage = () => {
  useEffect(() => {
    console.log(
      '%c\r\n  _   ___             ____     _____                     \r\n | | / (_)__ ____    / __/__  / _/ /__    _____ ________ \r\n | |/ / / _ `/ _ \\  _\\ \\/ _ \\/ _/ __/ |/|/ / _ `/ __/ -_)\r\n |___/_/\\_, /\\___/ /___/\\___/_/ \\__/|__,__/\\_,_/_/  \\__/ \r\n       /___/      MAKING LOGISTICS TECHNOLOGY. BETTER.                                       \r\n\r\n',
      'color: #ff0229;'
    );
  }, []);
};
