import React, { createContext } from 'react';
import { useContext } from 'react';

const LoggingContext = createContext<any>(null);

const LoggingContextProvider = ({ logger, children }: { logger: any; children: React.ReactNode }) => {
  return <LoggingContext.Provider value={logger}>{children}</LoggingContext.Provider>;
};

const useLoggingContext = () => {
  const logger = useContext(LoggingContext);

  return logger;
};

export { LoggingContextProvider, useLoggingContext };
