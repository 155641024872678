interface DarkModeIconProps {
  color?: string;
}

const DarkModeIcon = ({ color }: DarkModeIconProps) => {
  return (
    <svg
      id="Dark_Mode_Icon"
      data-name="Dark Mode Icon"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_659" data-name="Rectangle 659" width="24" height="24" fill="#626679" />
        </clipPath>
      </defs>
      <g id="Group_3021" data-name="Group 3021" clipPath="url(#clip-path)">
        <path
          id="Path_3057"
          data-name="Path 3057"
          d="M9.37,5.51a7.408,7.408,0,0,0,9.12,9.12A7,7,0,1,1,9.37,5.51M12,3a9,9,0,1,0,9,9,10.122,10.122,0,0,0-.1-1.36A5.4,5.4,0,1,1,13.36,3.1,10.122,10.122,0,0,0,12,3"
          fill={color ?? '#626679'}
        />
      </g>
    </svg>
  );
};

export default DarkModeIcon;
