import { useVigoLogout } from 'src/@core/hooks/useVigoLogout';
import { LogoutEmitData } from 'src/pages/api/closeSession';
import useAccessToken from 'src/@core/hooks/useAccessToken';
import debug from 'debug';

export const LOGOUT_EVENT = 'logout';

const debugLog = debug('socket:logoutHandler');

interface UserToken {
  sid: string;
  sub: string;
}

/**
 * Log handler hook, returns a function which checks for a matching session id and logs out the user.
 * @returns
 */
const useLogoutHandler = () => {
  const { handleLogout } = useVigoLogout();
  const token = useAccessToken();

  const logoutHandler = (data: LogoutEmitData) => {
    const { sessionId, userId } = data;
    const { sid, sub } = token as UserToken;

    debugLog('logoutHandler: ', 'sid: ', sid, 'sub: ', sub, 'sessionId: ', sessionId, 'userId: ', userId);

    if (sid === sessionId && sub === userId) {
      handleLogout();
    }
  };

  return { logoutHandler };
};

export default useLogoutHandler;
