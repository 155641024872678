import { createContext, useContext, useEffect, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import useAccessToken from 'src/@core/hooks/useAccessToken';
import { useAuth } from 'react-oidc-context';
import debug from 'debug';
import useLogoutHandler, { LOGOUT_EVENT } from 'src/socketHandlers/clientHandlers/useLogoutHandler';
import useConfigUpdateHandler, { CONFIG_UPDATE_EVENT } from 'src/socketHandlers/clientHandlers/useConfigUpdateHandler';
import useImportFailuresHandler, {
  IMPORTFAILURE_EVENT
} from 'src/socketHandlers/clientHandlers/useImportFailuresHandler';

const debugLog = debug('socket:SocketClient');

/** Socket Context */
const SocketContext = createContext<Socket | null>(null);

/** Hook to use the socket context */
export const useSocket = () => useContext(SocketContext);

/**
 * Socket Client component
 * @param children
 */
const SocketClient = ({ children }: { children: React.ReactNode }) => {
  const [socket, setSocket] = useState<Socket | null>(null);

  const auth = useAuth();
  const decodedToken = useAccessToken();
  const { logoutHandler } = useLogoutHandler();
  const { configUpdateHandlerFlagOnly } = useConfigUpdateHandler();
  const { importFailuresHandler } = useImportFailuresHandler();
  // const { logMessage, flushLogs } = useLogging();

  const token = auth?.user?.access_token ?? '';

  const MAX_ATTEMPTS = 10;
  let attempts = 0;

  useEffect(() => {
    fetch('/api/socket');

    const socketio = io(``, {
      transports: ['websocket']
    });

    setSocket(socketio);

    socketio.on('connect', () => {
      /**
       * Apply any logic required when the socket connects here.
       */
      debugLog('SocketIO: connected');

      socketio.emit('authentication', {
        token,
        tenantUserId: (decodedToken as any).tenantUserId,
        partnerId: (decodedToken as any).partnerId
      });

      socketio.on('authenticated', () => {
        /**
         * Apply any logic required when the socket is authenticated here.
         */
        debugLog('SocketIO: authenticated');
      });
    });

    socketio.on('disconnect', () => {
      /**
       * Apply any logic required when the socket disconnects here.
       */
      debugLog('SocketIO: disconnected');
    });

    socketio.on('connect_error', async err => {
      /**
       * SocketIO connection error handling.
       */
      debugLog(`SocketIO: connect_error due to ${err.message}`);

      if (attempts < MAX_ATTEMPTS) {
        attempts++;
        await fetch(`/api/socket`);
      } else {
        /** Attempts to connect now exceeded so log the error */
        debugLog('Error', 'SocketIO: connect_error MAX_ATTEMPTS exceeded', {
          error: err
        });
        // flushLogs();
      }
    });

    socketio.on(LOGOUT_EVENT, logoutHandler);
    socketio.on(CONFIG_UPDATE_EVENT, configUpdateHandlerFlagOnly);
    socketio.on(IMPORTFAILURE_EVENT, importFailuresHandler);

    return () => {
      socketio.disconnect();
      socketio.off(LOGOUT_EVENT, logoutHandler);
      socketio.off(CONFIG_UPDATE_EVENT, configUpdateHandlerFlagOnly);
      socketio.off(IMPORTFAILURE_EVENT, importFailuresHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>;
};

export default SocketClient;
