// ** Mock Adapter
import mock from 'src/@fake-db/mock';
import { ServiceLocation } from 'src/services/serviceLocationsAxios';

// ** Type import
import { AddOnModule } from '../../types/apps/addOnTypes';

const sampleIcons: string[] = [
  'https://download.odoocdn.com/icons/website/static/description/icon.png',
  'https://download.odoocdn.com/icons/website_sale/static/description/icon.png',
  'https://download.odoocdn.com/icons/website_blog/static/description/icon.png',
  'https://download.odoocdn.com/icons/website_forum/static/description/icon.png',
  'https://download.odoocdn.com/icons/website_slides/static/description/icon.png',
  'https://download.odoocdn.com/icons/website_livechat/static/description/icon.png',
  'https://download.odoocdn.com/icons/crm/static/description/icon.png',
  'https://download.odoocdn.com/icons/sale_management/static/description/icon.png',
  'https://download.odoocdn.com/icons/account/static/description/icon.png',
  'https://download.odoocdn.com/icons/point_of_sale/static/description/icon.png',
  'https://download.odoocdn.com/icons/sign/static/description/icon.png',
  'https://download.odoocdn.com/icons/sale_subscription/static/description/icon.png',
  'https://download.odoocdn.com/icons/sale_renting/static/description/icon.png',
  'https://download.odoocdn.com/icons/industry_fsm/static/description/icon.png',
  'https://download.odoocdn.com/icons/account_accountant/static/description/icon.png',
  'https://download.odoocdn.com/icons/account_consolidation/static/description/icon.png',
  'https://download.odoocdn.com/icons/project/static/description/icon.png',
  'https://download.odoocdn.com/icons/timesheet_grid/static/description/icon.png',
  'https://download.odoocdn.com/icons/helpdesk/static/description/icon.png',
  'https://download.odoocdn.com/icons/stock/static/description/icon.png',
  'https://download.odoocdn.com/icons/purchase/static/description/icon.png',
  'https://download.odoocdn.com/icons/documents/static/description/icon.png',
  'https://download.odoocdn.com/icons/mrp/static/description/icon.png',
  'https://download.odoocdn.com/icons/mrp_plm/static/description/icon.png',
  'https://download.odoocdn.com/icons/maintenance/static/description/icon.png',
  'https://download.odoocdn.com/icons/quality_control/static/description/icon.png',
  'https://download.odoocdn.com/icons/repair/static/description/icon.png',
  'https://download.odoocdn.com/icons/mass_mailing/static/description/icon.png',
  'https://download.odoocdn.com/icons/mass_mailing_sms/static/description/icon.png',
  'https://download.odoocdn.com/icons/marketing_automation/static/description/icon.png',
  'https://download.odoocdn.com/icons/website_event/static/description/icon.png',
  'https://download.odoocdn.com/icons/survey/static/description/icon.png',
  'https://download.odoocdn.com/icons/social/static/description/icon.png',
  'https://download.odoocdn.com/icons/appointment/static/description/icon.png',
  'https://download.odoocdn.com/icons/website_mass_mailing/static/description/icon.png',
  'https://download.odoocdn.com/icons/hr/static/description/icon.png',
  'https://download.odoocdn.com/icons/hr_recruitment/static/description/icon.png',
  'https://download.odoocdn.com/icons/hr_referral/static/description/icon.png',
  'https://download.odoocdn.com/icons/hr_holidays/static/description/icon.png',
  'https://download.odoocdn.com/icons/hr_expense/static/description/icon.png',
  'https://download.odoocdn.com/icons/planning/static/description/icon.png',
  'https://download.odoocdn.com/icons/hr_appraisal/static/description/icon.png',
  'https://download.odoocdn.com/icons/fleet/static/description/icon.png',
  'https://download.odoocdn.com/icons/approvals/static/description/icon.png',
  'https://download.odoocdn.com/icons/lunch/static/description/icon.png',
  'https://download.odoocdn.com/icons/web_studio/static/description/icon.png',
  'https://odoocdn.com/openerp_website/static/src/img/2020/testimonials/Wen_Hsin_Yang_ru.jpg',
  'https://odoocdn.com/openerp_website/static/src/img/2020/testimonials/coppernic.png',
  'https://odoocdn.com/openerp_website/static/src/img/2020/testimonials/engginium.png',
  'https://odoocdn.com/openerp_website/static/src/img/2020/testimonials/geely.png',
  'https://odoocdn.com/openerp_website/static/src/img/2020/testimonials/sodexo.png',
  'https://odoocdn.com/openerp_website/static/src/img/2020/testimonials/David_fowler_ru.jpg',
  'https://odoocdn.com/openerp_website/static/src/img/2020/testimonials/nur_ru.jpg',
  'https://odoocdn.com/openerp_website/static/src/img/2020/testimonials/portugal_mde.png',
  'https://odoocdn.com/openerp_website/static/src/img/2020/testimonials/sap_logo.png',
  'https://odoocdn.com/openerp_website/static/src/img/2020/testimonials/DrDilkhush.jpeg',
  'https://odoocdn.com/openerp_website/static/src/img/2020/testimonials/Enis_Hyseni.jpeg',
  'https://odoocdn.com/openerp_website/static/src/img/2020/testimonials/newvision.png',
  'https://odoocdn.com/openerp_website/static/src/img/2020/testimonials/XavierSchmitz.jpeg',
  'https://odoocdn.com/openerp_website/static/src/img/2016/live-support/info.gif'
];

const sampleTitles: string[] = [
  'Website',
  'eCommerce',
  'Blog',
  'Forum',
  'eLearning',
  'Live Chat',
  'Sales',
  'CRM',
  'Sales',
  'Invoicing',
  'Point of Sale',
  'Sign',
  'Subscriptions',
  'Rental',
  'Field Service',
  'Operations',
  'Accounting',
  'Consolidation',
  'Project',
  'Timesheets',
  'Helpdesk',
  'Inventory',
  'Purchase',
  'Documents',
  'Manufacturing',
  'Manufacturing',
  'PLM',
  'Maintenance',
  'Quality',
  'Repair',
  'Marketing',
  'Email Marketing',
  'SMS Marketing',
  'Marketing Automation',
  'Events',
  'Survey',
  'Social Marketing',
  'Appointments',
  'Newsletter Subscribe Button',
  'Human Resources',
  'Employees',
  'Recruitment',
  'Employee Referral',
  'Time Off',
  'Expenses',
  'Planning',
  'Appraisals',
  'Fleet',
  'Approvals',
  'Lunch',
  'Customizations',
  'Studio'
];

const sampleDescription =
  'Lorem ipsum dolor sit amet. Hic optio atque id sint aliquid est incidunt debitis et corrupti inventore sit totam exercitationem. Ut dolorem doloremque qui illum neque repellendus ipsa. Culpa dolor et perferendis velit et enim similique. Eum ullam odio aut sapiente nisi sit exercitationem quidem ut aliquid temporibus eum accusamus atque et velit tempora in quia voluptatem. Aut praesentium alias qui quas nemo sed deleniti incidunt.';

const tags = ['TMS', 'WMS', 'HUB'];

/**
 * Module generation
 */
const modulesToCreate = 45;
let idCount = 0;

const modules: AddOnModule[] = Array<any>(modulesToCreate)
  .fill('')
  .map((mod, index) => {
    let start: number = Math.floor(Math.random() * tags.length);
    const count: number = Math.floor(Math.random() * tags.length);
    const tagArray: string[] = [];

    do {
      tagArray.push(tags[start]);
      start++;
    } while (start <= count);

    return {
      id: idCount++,
      icon: sampleIcons[index],
      title: sampleTitles[index],
      description: sampleDescription.slice(0, Math.floor(Math.random() * sampleDescription.length) + 75),
      price: Math.floor(Math.random() * 200) + 55,
      tags: tagArray,
      selected: false,
      dependencies: index === 0 ? [2, 3, 5] : index === 5 ? [7] : index === 7 ? [10] : [],
      hasError: false
    };
  });

mock.onGet(`${ServiceLocation.addOnService}/modules`).reply(() => {
  return [200, { modules, tags }];
});
