import { ListItemIcon, ListItemText, SvgIcon, useTheme } from '@mui/material';
import { MenuItem } from './styledComponents';
import LogoutIcon from './icons/LogoutIcon';
import { useVigoLogout } from 'src/@core/hooks/useVigoLogout';

const UserLogout = () => {
  const { handleLogout } = useVigoLogout();

  const theme = useTheme();

  return (
    <MenuItem onClick={handleLogout}>
      <ListItemIcon>
        <SvgIcon>
          <LogoutIcon productColor={theme.palette.customColors.primaryGradient} />
        </SvgIcon>
      </ListItemIcon>
      <ListItemText sx={{ '& span:hover': { color: theme.palette.customColors.primaryGradient } }}>Logout</ListItemText>
    </MenuItem>
  );
};

export default UserLogout;
