import { findFormatByCode } from './countryFormats';
import { ServiceLocation } from 'src/services/serviceLocationsAxios';

// Mock Adapter
import mock from 'src/@fake-db/mock';

mock.onGet(`${ServiceLocation.addressService}/format?code=GB`).reply(200, {
  addressFormat: findFormatByCode('GB')
});

mock.onGet(`${ServiceLocation.addressService}/format?code=DE`).reply(200, {
  addressFormat: findFormatByCode('DE')
});

mock.onGet(`${ServiceLocation.addressService}/format?code=FR`).reply(200, {
  addressFormat: findFormatByCode('FR')
});

mock.onGet(`${ServiceLocation.addressService}/format?code=ES`).reply(200, {
  addressFormat: findFormatByCode('ES')
});

mock.onGet(`${ServiceLocation.addressService}/format?code=IT`).reply(200, {
  addressFormat: findFormatByCode('IT')
});
