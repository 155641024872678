interface AccountCogProps {
  productColor?: string;
}

const AccountCog = ({ productColor }: AccountCogProps) => {
  return (
    <svg
      id="MDI_account-cog-outline"
      data-name="MDI / account-cog-outline"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24">
      <g id="Boundary" fill="#626679" stroke="rgba(0,0,0,0)" strokeWidth="1" opacity="0">
        <rect width="24" height="24" stroke="none" />
        <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
      </g>
      <path
        id="Path_account-cog-outline"
        data-name="Path / account-cog-outline"
        d="M10,4a4,4,0,1,0,4,4,4,4,0,0,0-4-4m0,2A2,2,0,1,1,8,8a2,2,0,0,1,2-2m7,6a.212.212,0,0,0-.24.24L16.5,13.5c-.22.18-.54.34-.78.5l-1.28-.5a.409.409,0,0,0-.32.1l-.96,1.76a.2.2,0,0,0,.08.32l1.04.82v1l-1.04.82c-.08.08-.16.24-.08.32l.96,1.76a.409.409,0,0,0,.32.1l1.28-.5c.24.16.56.32.78.5l.26,1.26A.212.212,0,0,0,17,22h2a.255.255,0,0,0,.24-.24l.16-1.26c.32-.18.64-.34.88-.5l1.22.5c.14,0,.3,0,.3-.1l1.04-1.76c.08-.08,0-.24-.08-.32l-1.04-.82v-1l1.04-.82c.08-.08.16-.24.08-.32L21.8,13.6c0-.1-.16-.1-.3-.1l-1.22.5c-.24-.16-.56-.32-.88-.5l-.16-1.26A.255.255,0,0,0,19,12H17m-7,1c-2.67,0-8,1.33-8,4v3h9.67a6.764,6.764,0,0,1-.58-1.9H3.9V17c0-.64,3.13-2.1,6.1-2.1a9.483,9.483,0,0,1,1.3.1,7.465,7.465,0,0,1,.82-1.79A13.512,13.512,0,0,0,10,13m8.04,2.5a1.487,1.487,0,0,1,1.46,1.54,1.468,1.468,0,0,1-1.46,1.46,1.487,1.487,0,0,1-1.54-1.46A1.5,1.5,0,0,1,18.04,15.5Z"
        transform="translate(0 -1)"
        fill="#626679"
      />
      <path
        id="Path_account-cog-outline-2"
        data-name="Path / account-cog-outline"
        d="M16.76,12.24,16.5,13.5c-.22.18-.54.34-.78.5l-1.28-.5a.409.409,0,0,0-.32.1l-.96,1.76a.2.2,0,0,0,.08.32l1.04.82v1l-1.04.82c-.08.08-.16.24-.08.32l.96,1.76a.409.409,0,0,0,.32.1l1.28-.5c.24.16.56.32.78.5l.26,1.26A.212.212,0,0,0,17,22h2a.255.255,0,0,0,.24-.24l.16-1.26c.32-.18.64-.34.88-.5l1.22.5c.14,0,.3,0,.3-.1l1.04-1.76c.08-.08,0-.24-.08-.32l-1.04-.82v-1l1.04-.82c.08-.08.16-.24.08-.32L21.8,13.6c0-.1-.16-.1-.3-.1l-1.22.5c-.24-.16-.56-.32-.88-.5l-.16-1.26A.255.255,0,0,0,19,12H17m1.04,3.5a1.487,1.487,0,0,1,1.46,1.54,1.468,1.468,0,0,1-1.46,1.46,1.487,1.487,0,0,1-1.54-1.46A1.5,1.5,0,0,1,18.04,15.5Z"
        transform="translate(0 -1)"
        fill={productColor ?? '#009fe3'}
      />
    </svg>
  );
};

export default AccountCog;
