import { Theme } from '@mui/material/styles';

const Paper = (theme: Theme) => {
  return {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1e1e1e',
          backgroundImage: 'none'
        }
      }
    }
  };
};

export default Paper;
