import { useContext, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/store/baseHooks';
import { AppBarContext } from '../context/appBarContext';
import {
  useGetAllowedAttachmentTypesQuery,
  useGetDtoByIdQuery,
  useGetAttachmentTypesQuery
} from 'src/queries/uiflow/attachments';
import { setSelectedEntityId, setSelectedEntityIds } from 'src/store/slices/attachmentsDataSlice';

export const useAttachmentPanel = () => {
  const [attachmentsAllowed, setAttachmentsAllowed] = useState<boolean>(false);

  const { attachmentCount, dispatch } = useContext(AppBarContext);

  let attachmentDtoId = useAppSelector(state => state.attachmentsData.attachmentDtoId);
  const reduxDispatch = useAppDispatch();

  // we dont have am attachent Dto ID we can default to order as this is currently our only attachment DTO
  if (!attachmentDtoId) {
    attachmentDtoId = '8af48d21-1212-4df6-be73-32d1cf7c5988';
  }

  /**
   * Attachments Allowed Query
   */
  const {} = useGetDtoByIdQuery(attachmentDtoId, {
    queryKeyOverride: ['attachmentsAllowed', attachmentDtoId],
    enabled: Boolean(attachmentDtoId),
    initialData: false,
    onSuccess: data => {
      setAttachmentsAllowed(data.allowAttachments ?? false);
    }
  });

  /**
   * Get allowed attachment types
   */
  const { data: allowedAttachmentTypes = [] } = useGetAllowedAttachmentTypesQuery(attachmentDtoId, {
    initialData: [],
    enabled: attachmentDtoId !== undefined && attachmentDtoId !== null && attachmentDtoId !== '' && attachmentsAllowed,
    refetchOnWindowFocus: false
  });

  /**
   * Get attachment types
   */
  const { data: attachmentTypes = [] } = useGetAttachmentTypesQuery({
    initialData: [],
    enabled: attachmentDtoId !== undefined && attachmentDtoId !== null && attachmentDtoId !== '' && attachmentsAllowed,
    refetchOnWindowFocus: false
  });

  /**
   * Reset the attachment count.
   * - Will set attachment count to null so the number badge is hidden.
   * - Will reset the selected entity id so that the attachment panel is disabled.
   */
  const resetAttachmentCount = () => {
    dispatch({ type: 'setAttachmentCount', payload: null });
    reduxDispatch(setSelectedEntityId(''));
    reduxDispatch(setSelectedEntityIds([]));
  };

  return {
    allowedAttachmentTypes,
    attachmentTypes,
    attachmentCount,
    attachmentsAllowed,
    setAttacmentCount: dispatch,
    resetAttachmentCount
  };
};
