import { createContext, useState } from 'react';

interface AppBarContextValue {
  attachmentCount: number;
  dispatch: (action: any) => void;
}

const AppBarContextValue: AppBarContextValue = {
  attachmentCount: 0,
  dispatch: (action: any) => {
    {
      action.type === 'setAttachmentCount' && action.payload;
    }
  }
};

export const AppBarContext = createContext(AppBarContextValue);

export const AppBarContextProvider = ({ children }: any) => {
  const [attachmentCount, setAttachmentCount] = useState(0);

  const dispatch = (action: any): void => {
    switch (action.type) {
      case 'setAttachmentCount':
        setAttachmentCount(action.payload);
        break;
      default:
        break;
    }
  };

  return (
    <AppBarContext.Provider
      value={{
        attachmentCount,
        dispatch
      }}>
      {children}
    </AppBarContext.Provider>
  );
};
