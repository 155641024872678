type formatLookup = { [key: string]: string };

export const countryFormats: formatLookup = {
  AF: '{{Company}\\n}{{District}, }{Street}\\n{House No. {BuildingNumber}\\n}{P.O. Box {PoBoxNumber}\\n}{{City!}\\n}{{PostalCode!}\\n}{CountryName!}',
  AX: '{{Company}\\n}{Street}{ {BuildingNumber}}\\n{AX-{PostalCode!} }{City!}\\nÅLAND\\nFINLAND',
  AL: '{{Company}\\n}{{Street}\\n}{{BuildingNumber}}{, {SubBuildling}}{, {BuildingName}}\\n{{PostalCode!}\\n}{{City!}\\n}{CountryName!}',
  DZ: '{{Company}\\n}{{BuildingNumber}, }{Street}\\n{{PostalCode!} }{City!}\\n{CountryName!}',
  AS: '{{Company}\\n}{{BuildingNumber} }{Street}\\n{City!}{ {Province!}}{ {PostalCode!}}\\n{CountryName!}',
  AD: '{{Company}\\n}{Street}{, {BuildingNumber}}{ {SubBuilding}}{ {BuildingName}}\\n{{PostalCode!} }{Province!}\\n{CountryName!}',
  AO: '{{Company}\\n}{Street}{ {BuildingNumber}}{\\nBP {PoBoxNumber}}\\n{{City!}\\n}{CountryName!}',
  AI: '{{Company}\\n}{P.O. Box {PoBoxNumber}\\n}{{City!}\\n}{CountryName!}',
  AG: '{{Company}\\n}{P.O. Box {PoBoxNumber}\\n}{{City!}\\n}{CountryName!}',
  AR: '{{Company}\\n}{Street}{ {BuildingNumber}}\\n{{PostalCode!} }{City!}\\n{CountryName!}',
  AM: '{{Company}\\n}{Street}{ {BuildingNumber}}{ {SubBuilding}}{ {BuildingName}}\\n{{PostalCode!} }{City!}\\n{CountryName!}',
  AW: '{{Company}\\n}{Street}{ # {BuildingNumber}}{\\n{SubBuilding}}\\n{{City!}\\n}{CountryName!}',
  AU: '{{Company}\\n}{{Line1}\\n}{{Line2}\\n}{{Line3}\\n}{{Line4}\\n}{{Line5}\\n}{{City!} }{{Province!} }{PostalCode!}\\n{CountryName!}',
  AT: '{{Company}\\n}{Street}{ {BuildingNumber}}\\n{{PostalCode!} }{City!}\\n{CountryName!}',
  AZ: '{{Company}\\n}{Street}{ {BuildingNumber}}\\n{{PostalCode!} }{City!}\\n{CountryName!}',
  BS: '{{Company}\\n}{{BuildingName}\\n}{{Street}\\n}{P.O. Box N {PoBoxNumber}\\n}{City!}{ BS {PostalCode!}}\\n{CountryName!}',
  BH: '{{Company}\\n}{Street}{ {BuildingNumber}}\\n{P.O. Box {PoBoxNumber}\\n}{City!}{ {PostalCode!}}\\n{CountryName!}',
  BD: '{{Company}\\n}{{BuildingName}\\n}{Street}{ {BuildingNumber}}\\n{City!}{ - {PostalCode!}}\\n{CountryName!}',
  BB: '{{Company}\\n}{{BuildingName}\\n}{{Street}\\n}{{City!}\\n}{CountryName!}',
  BY: '{{Company}\\n}{Street}{ {BuildingNumber}}{ {BuildingName}}{ {SubBuilding}}\\n{{PostalCode!} }{City!}\\n{{District}\\n}{{Province}\\n}{CountryName!}',
  BE: '{{Company}\\n}{Street}{ {BuildingNumber}}\\n{{PostalCode!} }{City!}\\n{CountryName!}',
  BZ: '{{Company}\\n}{{BuildingNumber} }{Street}\\n{{City!}\\n}{CountryName!}',
  BJ: '{{Company}\\n}{BP {PoBoxNumber}\\n}{{City!}\\n}{CountryName!}',
  BM: '{{Company}\\n}{{BuildingName}\\n}{{BuildingNumber} }{Street}\\n{City!}{ {PostalCode!}}\\n{CountryName!}',
  BT: '{{Company}\\n}{{BuildingName}\\n}{House No. {BuildingNumber}\\n}{P.O Box {PoBoxNumber}\\n}{{District}\\n}{City}{ {PostalCode!}}\\n{CountryName!}',
  BO: '{{Company}\\n}{Street}{ {BuildingNumber}}\\n{PO BOX {PoBoxNumber}\\n}{{City!}\\n}{CountryName!}',
  BQ: '{{Company}\\n}{Street}{ {BuildingNumber}}\\n{City!}{ {Province!}}\\n{CountryName!}',
  BA: '{{Company}\\n}{Street}{ {BuildingNumber}}\\n{{PostalCode!} }{City!}\\n{CountryName!}',
  BW: '{{Company}\\n}{P.O. Box {PoBoxNumber}\\n}{{City!}\\n}{CountryName!}',
  BR: '{{Company}\\n}{Street}{, {BuildingNumber}}\\n{City!}{ - {Province!}}\\n{{PostalCode!}\\n}{CountryName!}',
  IO: '{{Company}\\n}{SubBuilding}\\n{BuildingName}\\n{{BuildingNumber} }{Street}\\n{{District}\\n}{{City!}\\n}{{PostalCode!}\\n}{CountryName!}',
  VG: '{{Company}\\n}{{Line1}\\n}{{Line2}\\n}{{Line3}\\n}{{Line4}\\n}{{Line5}\\n}{{City!}\\n}{{PostalCode!}\\n}{CountryName!}',
  BN: '{{Company}\\n}{{BuildingNumber} }{{SubBuilding} }{BuildingName}, {Street}\\n{{City!}\\n}{Province!}{ {PostalCode!}}\\n{CountryName!}',
  BG: '{{Company}\\n}{Street}{ {BuildingNumber}} {SubBuilding}\\n{{PostalCode!} }{City!}\\n{CountryName!}',
  BF: '{{Company}\\n}{BP {PoBoxNumber}\\n}{City!}{ {PostalCode!}\\n}{CountryName!}',
  MM: '{{Company}\\n}{No. {BuildingNumber} }{Street}\\n{{District}\\n}{City!}{, {PostalCode!}}\\n{CountryName!}',
  BI: '{{Company}\\n}{BP {PoBoxNumber}\\n}{{City!}\\n}{CountryName!}',
  KH: '{{Company}\\n}{{BuildingNumber} }{Street}\\n{City!}{ {Province!}}{ {PostalCode!}}\\n{CountryName!}',
  CM: '{{Company}\\n}{BP {PoBoxNumber}\\n}{{City!}\\n}{CountryName!}',
  CA: '{{Company}\\n}{{Line1}\\n}{{Line2}\\n}{City!}{ {Province!}}{ {PostalCode!}}\\n{CountryName!}',
  CV: '{{Company}\\n}{C.P. {PoBoxNumber}\\n}{Street}{ {BuildingNumber}}\\n{{PostalCode!} }{City!}\\n{CountryName!}',
  KY: '{{Company}\\n}{P.O. Box {PoBoxNumber}\\n}{Province}{  {PostalCode!}}\\n{CountryName!}',
  CF: '{{Company}\\n}{BP {PoBoxNumber}\\n}{{City!}\\n}{CountryName!}',
  TD: '{{Company}\\n}{BP {PoBoxNumber}\\n}{{City!}\\n}{CountryName!}',
  CL: '{{Company}\\n}{Street}{ {BuildingNumber}}\\n{{PostalCode!} }{City!}\\n{{Province!}\\n}{CountryName!}',
  CN: '{{Company}\\n}{{BuildingNumber} }{Street}{, {District}}\\n{{PostalCode!} }{City!}\\n{CountryName!}',
  CX: '{{Company}\\n}{{BuildingNumber} }{Street}\\n{City!}{ {Province!}}{ {PostalCode!}}\\n{CountryName!}',
  CC: '{{Company}\\n}{{BuildingNumber} }{Street}\\n{City!}{ {Province!}}{ {PostalCode!}}\\n{CountryName!}',
  CO: '{{Company}\\n}{Street}{ {BuildingNumber}}\\n{City!}{-{Province!}}{ {PostalCode!}}\\n{CountryName!}',
  KM: '{{Company}\\n}{BP {PoBoxNumber}\\n}{{City!}\\n}{CountryName!}',
  CG: '{{Company}\\n}{BP {PoBoxNumber}\\n}{{BuildingNumber}, }{Street}\\n{{City!}\\n}{CountryName!}',
  CD: '{{Company}\\n}{B.P. {PoBoxNumber}\\n}{{City!}\\n}{CountryName!}',
  CK: '{{Company}\\n}{{BuildingNumber} }{Street}\\n{{District}\\n}{City!}{ {PostalCode!}}\\n{CountryName!}',
  CR: '{{Company}\\n}{Street}{ # {BuildingNumber}}\\n{{District}, }{City}{, {Province}}\\n{{PostalCode!}\\n}{CountryName!}',
  HR: '{{Company}\\n}{Street}{ {BuildingNumber}}\\n{P.P. {PoBoxNumber}\\n}{HR-{PostalCode!} }{City!}\\n{CountryName!}',
  CU: '{{Company}\\n}{Street}{ {BuildingNumber}}\\n{{District}\\n}{CP {PostalCode!} }{City!}{ {Province!}}\\n{CountryName!}',
  CW: '{{Company}\\n}{Street}{ {BuildingNumber}}\\n{City!}{ {Province!}}\\n{CountryName!}',
  CY: '{{Company}\\n}{P.O. Box {PoBoxNumber}\\n}{Street}{ {BuildingNumber}}\\n{CY-{PostalCode!}}{ {City}}\\n{CountryName!}',
  CZ: '{{Company}\\n}{{BuildingName}\\n}{Street}{ {BuildingNumber}}\\n{{PostalCode!} }{City!}\\n{CountryName!}',
  DK: '{{Company}\\n}{Postboks {PoBoxNumber}\\n}{Street}{ {BuildingNumber}}{, {District}}\\n{{PostalCode!} }{City!}\\n{CountryName!}',
  DJ: '{{Company}\\n}{BP {PoBoxNumber}\\n}{{City!}\\n}{CountryName!}',
  DM: '{{Company}\\n}{{BuildingName}\\n}{{BuildingNumber} }{Street}\\n{{City!}\\n}{CountryName!}',
  DO: '{{Company}\\n}{Street}{ # {BuildingNumber}}{ {SubBuilding}}\\n{{District}\\n}{{PostalCode!} }{City!}\\n{CountryName!}',
  TL: '{{Company}\\n}{{BuildingNumber} }{Street}\\n{City!}{ {PostalCode!}}\\n{CountryName!}',
  EC: '{{Company}\\n}{Street}{ {BuildingNumber}}\\n{{PostalCode!}\\n}{{City!}\\n}{CountryName!}',
  EG: '{{Company}\\n}{{BuildingNumber}, }{Street}\\n{{District}\\n}{{City!}\\n}{PostalCode!}\\n{CountryName!}',
  SV: '{{Company}\\n}{Street}{ #{BuildingNumber}}\\n{CP {PostalCode!} - }{City!}\\n{{Province!}\\n}{CountryName!}',
  GQ: '{{Company}\\n}{Street}{ {BuildingNumber}}\\n{{City!}\\n}{CountryName!}',
  ER: '{{Company}\\n}{PO Box {PoBoxNumber}\\n}{Street}{ {BuildingNumber}}\\n{{City!}\\n}{CountryName!}',
  EE: '{{Company}\\n}{{BuildingName}\\n}{Street}{ {BuildingNumber}}\\n{{District}\\n}{{PostalCode!} }{City!}\\n{CountryName!}',
  SZ: '{{Company}\\n}{P.O. Box {PoBoxNumber}\\n}{{City!}\\n}{{PostalCode!}\\n}{CountryName!}',
  ET: '{{Company}\\n}{P.O. Box {PoBoxNumber}\\n}{{BuildingName}\\n}{Street}{ {BuildingNumber}}\\n{{PostalCode!} }{City!}\\n{CountryName!}',
  FK: '{{Company}\\n}{{BuildingName}\\n}{{BuildingNumber} }{Street}\\n{{City!}\\n}{PostalCode!}\\n{CountryName!}',
  FO: '{{Company}\\n}{Street}{ {BuildingNumber}}\\n{PO{PostalCode!} }{City!}\\n{CountryName!}',
  FJ: '{{Company!}\\n}{PO BOX {PoBoxNumber}\\n}{{BuildingNumber} }{Street!}\\n{{City!}\\n}{CountryName!}',
  FI: '{{Company}\\n}{PL {PoBoxNumber}\\n}{Street}{ {BuildingNumber}}\\n{FI-{PostalCode!} }{City!}\\n{CountryName!}',
  FR: '{{Company}\\n}{{SubBuilding}\\n}{{BuildingName}\\n}{{BuildingNumber} }{Street!}\\n{{District!}\\n}{BP {PoBoxNumber}\\n}{{PostalCode!} }{City!}\\n{CountryName!}',
  GF: '{{Company}\\n}{{SubBuilding}\\n}{{BuildingName}\\n}{{BuildingNumber} }{Street!}\\n{{District!}\\n}{BP {PoBoxNumber}\\n}{{PostalCode!} }{City!}\\n{CountryName!}',
  PF: '{{Company}\\n}{{SubBuilding}\\n}{{BuildingName}\\n}{{BuildingNumber} }{Street!}\\n{{District!}\\n}{BP {PoBoxNumber}\\n}{{PostalCode!} }{City!}\\n{CountryName!}',
  TF: '{{Company}\\n}{{SubBuilding}\\n}{{BuildingName}\\n}{{BuildingNumber} }{Street!}\\n{{District!}\\n}{BP {PoBoxNumber}\\n}{{PostalCode!} }{City!}\\n{CountryName!}',
  GA: '{{Company}\\n}{BP {PoBoxNumber}\\n}{{PostalCode!} }{City!}\\n{CountryName!}',
  GM: '{{Company}\\n}{{BuildingNumber} }{Street}\\n{{City!}\\n}{CountryName!}',
  GE: '{{Company}\\n}{Street}{ {BuildingNumber}}\\n{PostalCode!}{ {City!}}\\n{CountryName!}',
  DE: '{{Company}\\n}{{PoBoxNumber}\\n}{Street}{ {BuildingNumber}}{ // {SubBuilding}}\\n{{PostalCode!} }{City}\\n{CountryName!}',
  GH: '{{Company}\\n}{P.O. Box {PoBoxNumber}\\n}{City!}{ - {Province!}}\\n{CountryName!}',
  GI: '{{Company}\\n}{{SubBuilding}\\n}{{BuildingName}\\n}{{BuildingNumber} }{Street}\\n{PO Box {PoBoxNumber}\\n}Gibraltar\\nGX11 1AA\\n{CountryName!}',
  GR: '{{Company}\\n}{{BuildingNumber} }{Street}\\n{P.O. Box {PoBoxNumber}\\n}{{PostalCode!} }{City!}\\n{CountryName!}',
  GL: '{{Company}\\n}{Postboks {PoBoxNumber}\\n}{Street}{ {BuildingNumber}}{, {District}}\\n{{PostalCode!} }{City!}\\n{CountryName!}',
  GD: '{{Company}\\n}{{Street}\\n}{P.O. BOX {PoBoxNumber}\\n}{{City!}\\n}GRENADA (WEST INDIES)',
  GP: '{{Company}\\n}{{SubBuilding}\\n}{{BuildingName}\\n}{{BuildingNumber} }{Street!}\\n{{District!}\\n}{BP {PoBoxNumber}\\n}{{PostalCode!} }{City!}\\n{CountryName!}',
  GU: '{{Company}\\n}{{Line1}\\n}{{Line2}\\n}{City!}{ {Province!}}{ {PostalCode!}}\\n{CountryName!}',
  GT: '{{Company}\\n}{{BuildingName}\\n}{{BuildingNumber} }{Street}\\n{{PostalCode!}-}{City!}\\n{CountryName!}',
  GG: '{{Company}\\n}{{Line1}\\n}{{Line2}\\n}{{Line3}\\n}{{Line4}\\n}{{Line5}\\n}{{City!}\\n}{{PostalCode!}\\n}{CountryName!}',
  GN: '{{Company}\\n}{{PostalCode!} }{BP {PoBoxNumber} }{City!}\\n{CountryName!}',
  GW: '{{Company}\\n}{Street}{ {BuildingNumber}}\\n{{PostalCode!} }{City!}\\n{CountryName!}',
  GY: '{{Company}\\n}{{BuildingNumber} }{Street}\\n{City!}{ {Province!}}{ {PostalCode!}}\\n{CountryName!}',
  HT: '{{Company}\\n}{{BuildingName}\\n}{Street}{ {BuildingNumber}}\\n{HT{PostalCode!} }{City!}\\n{CountryName!}',
  HN: '{{Company}\\n}{Street}{ {BuildingNumber}}\\n{{PostalCode!} }{City!}{, {Province!}}\\n{CountryName!}',
  HK: '{{Company}\\n}{{SubBuilding}\\n}{{BuildingName}\\n}{{BuildingNumber} }{Street}\\n{P.O. Box {PoBoxNumber}\\n}{District!}\\n{CountryName!}',
  HU: '{{Company}\\n}{{City!}\\n}{Street}{ {BuildingNumber}}\\n{PF. {PoBoxNumber}\\n}{{PostalCode!}\\n}{CountryName!}',
  IS: '{{Company}\\n}{Street}{ {BuildingNumber}}\\n{{PostalCode!} }{City!}\\n{CountryName!}',
  IN: '{{Company}\\n}{{SubBuilding} }{{BuildingName}\\n}{{BuildingNumber}, }{Street}\\n{City}\\n{{PostalCode!}\\n}{CountryName!}',
  ID: '{{Company}\\n}{{BuildingName}\\n}{Street}{ No. {BuildingNumber}}{, {SubBuilding}}\\n{{District}\\n}{City!}{ {PostalCode!}}\\n{CountryName!}',
  IR: '{{Company}\\n}{City}{, {District}}\\n{{Street}\\n}{{BuildingName} }{No. {BuildingNumber}}{, {SubBuilding}}\\n{{Province}\\n}{{PostalCode!}\\n}{CountryName!}',
  IQ: '{{Company}\\n}{P.O. box {PoBoxNumber}\\n}{{BuildingNumber} }{Street}\\n{City!}{, {Province!}}\\n{PostalCode!}\\n{CountryName!}',
  IE: '{{Company}\\n}{{Department}\\n}{{Line1}\\n}{{Line2}\\n}{{Line3}\\n}{{Line4}\\n}{{Line5}\\n}{{City!}\\n}{{PostalCode!}\\n}{CountryName!}',
  IM: '{{Company}\\n}{{SubBuilding}\\n}{{BuildingName}\\n}{{BuildingNumber} }{Street}\\n{{District}\\n}{{City!}\\n}{{PostalCode!}\\n}{CountryName!}',
  IL: '{{Company}\\n}{{BuildingName}\\n}{{SubBuilding}\\n}{{BuildingNumber}, }{Street}\\n{{PostalCode!} }{City!}\\n{CountryName!}',
  IT: '{{Company!}\\n}{Street!}{ {BuildingNumber}}{ {SubBuilding!}}\\n{{PostalCode!} }{City!}{ {Province!}}\\n{CountryName!}',
  CI: '{{Company}\\n}{{BuildingName}\\n}{{Street}\\n}{B.P. {PoBoxNumber}}{ {City!}}\\n{CountryName!}',
  JM: '{{Company}\\n}{P.O. Box {PoBoxNumber}\\n}{{BuildingNumber} }{Street}\\n{City!}{ {PostalCode!}}\\n{CountryName!}',
  JP: '{{Company}\\n}{BuildingName}{BuildingNumber}{ {Block}}{, {Neighbourhood}}\\n{{District}, }{{City}, }{Province!}\\n{{PostalCode!} }\\n{CountryName!}<br><br>----- or -----<br><br>{{Company}\\n}{{BuildingName}\\n}{{BuildingNumber} }{{Street}}{ {Block}}{, {Neighbourhood}\\n}{{District}, }{{City}, }{Province!}\\n{{PostalCode!} }\\n{CountryName!}<br><br>----- or -----<br><br>{CountryName!}\\n{{PostalCode!} }{Province!}\\n{, {City}, }{{District}\\n}{, {Neighbourhood}\\n}{{Block} }{{Street} }{{BuildingNumber} }{{BuildingName}\\n}{{Company}}<br><br>----- or -----<br><br>{CountryName!}\\n{{PostalCode!} }{Province!}\\n{, {City}, }{{District}\\n}{, {Neighbourhood}\\n}{{Block} }{{Street} }{{BuildingNumber} }{{BuildingName}\\n}{{Company}}',
  JE: '{{Company}\\n}{{Line1}\\n}{{Line2}\\n}{{Line3}\\n}{{Line4}\\n}{{Line5}\\n}{{City!}\\n}{{PostalCode!}\\n}{CountryName!}',
  JO: '{{Company}\\n}{{Province}\\n}{{District}\\n}{Street}{\\n{BuildingNumber}}{ {SubBuilding}}\\n{PO Box {PoBoxNumber}\\n}{City!}{ {PostalCode!}}\\n{CountryName!}',
  KZ: '{{Company}\\n}{Street}{, {BuildingNumber}}{, {SubBuilding}}\\n{City!}{ {District}}\\n{CountryName!}{\\n{PostalCode!}}',
  KE: '{{Company}\\n}{{BuildingName}\\n}{{Street}\\n}{P O Box {PoBoxNumber}\\n}{{City!}\\n}{PostalCode!}\\n{CountryName!}',
  KI: '{{Company}\\n}{PO Box {PoBoxNumber}\\n}{{BuildingNumber} }{Street}\\n{{City!}\\n}{{Province!}\\n}{CountryName!}',
  KP: '{{Company}\\n}{Street}{ {BuildingNumber}}\\n{City!}{ {PostalCode!}}\\n{CountryName!}',
  KR: '{{Company}\\n}{{BuildingName}\\n}{{BuildingNumber}, }{Street}\\n{Province!}{ {PostalCode!}}\\n{CountryName!}',
  KW: '{{Company}\\n}{B.P. {PoBoxNumber} }{{District}\\n}{Street}{ {BuildingNumber}}\\n{{PostalCode!} }{City!}\\n{CountryName!}',
  KG: '{{Company}\\n}{{BuildingNumber}, }{Street}{, {SubBuilding}}\\n{{PostalCode!} }{City!}\\n{{Province!}\\n}{CountryName!}',
  LA: '{{Company}\\n}{{BuildingNumber}, }{Street}\\n{{PostalCode!} }{City!}\\n{CountryName!}',
  LV: '{{Company}\\n}{Street}{ {BuildingNumber}}{, {SubBuilding}}\\n{City!}{, {PostalCode!}}\\n{CountryName!}',
  LB: '{{Company}\\n}{P.O. Box {PoBoxNumer}\\n}{{BuildingNumber} }{Street}{. {SubBuilding}}\\n{City!}{ {PostalCode!}}\\n{CountryName!}',
  LS: '{{Company}\\n}{P.O. Box {PoBoxNumber}\\n}{{Street}\\n}{City!}{ {PostalCode!}}\\n{CountryName!}',
  LR: '{{Company}\\n}{{Street}\\n}{{PostalCode!} }{City!}{ {Province!}}\\n{CountryName!}',
  LY: '{{Company}\\n}{Street}{ {BuildingNumber}}\\n{{City!}\\n}{CountryName!}',
  LI: '{{Company}\\n}{Street}{ {BuildingNumber}} {SubBuilding}\\n{{PostalCode!} }{City!}\\n{CountryName!}',
  LT: '{{Company}\\n}{Street}{ {BuildingNumber}}\\n{{District}\\n}{{PostalCode!} }{City!}\\n{CountryName!}',
  LU: '{{Company}\\n}{BP {PoBoxNumber}\\n}{{BuildingNumber} }{Street}\\n{{PostalCode!} }{City!}\\n{CountryName!}',
  MO: '{{Company}\\n}{P.O. Box {PoBoxNumber}\\n}{Street}{ {BuildingNumber}}{, {SubBuilding}}\\n{{District}\\n}{CountryName!}',
  MK: '{{Company}\\n}{P.O. Box {PoBoxNumber}\\n}{Street}{ {BuildingNumber}}\\n{{PostalCode!} }{City!}\\n{CountryName!}',
  MG: '{{Company}\\n}{B.P. {PoBoxNumber}\\n}{{BuildingNumber} }{Street}{ {District}}\\n{{PostalCode!} }{City!}\\n{CountryName!}',
  MW: '{{Company}\\n}{{Street}\\n}{P.O. Box {PoBoxNumber}\\n}{{City!}\\n}{CountryName!}',
  MY: '{{Company}\\n}{{SubBuilding} }{Building}\\n{{BuildingNumber}, }{Street}\\n{{District}\\n}{{PostalCode!} }{City!}\\n{{Province!}\\n}{CountryName!}',
  MV: '{{Company}\\n}{{BuildingNumber}, }{Street}\\n{City!}{ {PostalCode!}}\\n{CountryName!}',
  ML: '{{Company}\\n}{Street}{ {BuildingNumber}}\\n{{District}\\n}{{City!}\\n}{CountryName!}',
  MT: '{{Company}\\n}{Kaxxa Postali {PoBoxNumber}\\n}{{BuildingNumber} }{Street}\\n{City!}\\n{Province!}{ {PostalCode!}}\\n{CountryName!}',
  MH: '{{Company}\\n}{{BuildingNumber} }{Street}\\n{City!}{ {Province!}}{ {PostalCode!}}\\n{CountryName!}',
  MQ: '{{Company}\\n}{{SubBuilding}\\n}{{BuildingName}\\n}{{BuildingNumber} }{Street!}\\n{{District!}\\n}{BP {PoBoxNumber}\\n}{{PostalCode!} }{City!}\\n{CountryName!}',
  MR: '{{Company}\\n}{B.P. {PoBoxNumber}\\n}{{City!}\\n}{CountryName!}',
  MU: '{{Company}\\n}{{BuildingNumber}, }{Street}\\n{{District}\\n}{{City!}\\n}{CountryName!}',
  YT: '{{Company}\\n}{{SubBuilding}\\n}{{BuildingName}\\n}{{BuildingNumber} }{Street!}\\n{{District!}\\n}{BP {PoBoxNumber}\\n}{{PostalCode!} }{City!}\\n{CountryName!}',
  MX: '{{Company}\\n}{Street}{ {BuildingNumber}}{ - {SubBuilding}}\\n{{District}\\n}{{PostalCode!} }{City!}{, {Province!}}\\n{CountryName!}',
  FM: '{{Company}\\n}{{BuildingNumber} }{Street}\\n{City!}{ {Province!}}{ {PostalCode!}}\\n{CountryName!}',
  MD: '{{Company}\\n}{{BuildingNumber} }{Street}{, {SubBuilding}}\\n{MD-{PostalCode!} }{City!}\\n{CountryName!}',
  MC: '{{Company}\\n}{{SubBuilding}\\n}{{BuildingName}\\n}{{BuildingNumber} }{Street!}\\n{{District!}\\n}{BP {PoBoxNumber}\\n}{{PostalCode!} }{City!}\\n{CountryName!}',
  MN: '{{Company}\\n}{PO Box {PoBoxNumber}\\n}{{SubBuilding}, }{Street}{ {BuildingNumber} }\\n{{District}\\n}{City!}{ {PostalCode!}}\\n{CountryName!}',
  ME: '{{Company}\\n}{Street}{ {BuildingNumber}}\\n{{PostalCode!} }{City!}\\n{CountryName!}',
  MS: '{{Company}\\n}{{Line1}\\n}{{Line2}\\n}{{Line3}\\n}{{Line4}\\n}{{Line5}\\n}{{City!}\\n}{{PostalCode!}\\n}{CountryName!}',
  MA: '{{Company!}\\n}{P.O {PoBoxNumber}\\n}{{SubBuilding!}\\n}{{BuildingName!}}{{BuildingNumber!} }{Street!}\\n{{PostalCode!} }{City!}\\n{CountryName!}',
  MZ: '{{Company}\\n}{P.O {PoBoxNumber}\\n}{Street}{ {BuildingNumber}}{, {SubBuilding}}\\n{{PostalCode!} }{City!}\\n{{Province!}\\n}{CountryName!}',
  NA: '{{Company}\\n}{P.O. Box {PoBoxNumber}\\n}{{City!}\\n}{CountryName!}',
  NR: '{{Company}\\n}{{BuildingName}\\n}{{PoBoxNumber}\\n}{{District}\\n}{{City!}\\n}{CountryName!}',
  NP: '{{Company}\\n}{Street}{, {BuildingNumber}}\\n{{District}\\n}{City!}{ {PostalCode!}}\\n{CountryName!}',
  NL: '{{Company}\\n}{{SubBuilding}\\n}{{BuildingName}\\n}{Street}{ {BuildingNumber}}\\n{{PostalCode!} }{City!}\\n{CountryName!}',
  NC: '{{Company}\\n}{{SubBuilding}\\n}{{BuildingName}\\n}{{BuildingNumber} }{Street}\\n{{District}\\n}{{PostalCode!} }{City!}\\n{CountryName!}',
  NZ: '{{Company}\\n}{{Line1}\\n}{{Line2}\\n}{{Line3}\\n}{{Line4}\\n}{{Line5}\\n}{City!}{ {PostalCode!}}\\n{CountryName!}',
  NI: '{{Company}\\n}{{BuildingName} }{Street}{ {BuildingNumber}}\\n{{District}\\n}{{PostalCode!}\\n}{City!}{, {Province!}}\\n{CountryName!}',
  NE: '{{Company}\\n}{BP {PoBoxNumber}\\n}{{BuildingNumber} }{Street}\\n{{PostalCode!} }{City!}\\n{CountryName!}',
  NG: '{{Company}\\n}{P.O. Box {PoBoxNumber}\\n}{{BuildingNumber} }{Street}{, {District}}\\n{City!}{ {PostalCode!}}\\n{{Province!}\\n}{CountryName!}',
  NU: '{{Company}\\n}{{Line1}\\n}{{Line2}\\n}{{Line3}\\n}{{Line4}\\n}{{Line5}\\n}{City!}{ {PostalCode!}}\\n{CountryName!}',
  NF: '{{Company}\\n}{{Line1}\\n}{{Line2}\\n}{{Line3}\\n}{{Line4}\\n}{{Line5}\\n}{{City!} }{{Province!} }{PostalCode!}\\n{CountryName!}',
  MP: '{{Company}\\n}{{BuildingNumber} }{Street}\\n{City!}{ {Province!}}{ {PostalCode!}}\\n{CountryName!}',
  NO: '{{Company}\\n}{Postboks {PoBoxNumber}\\n}{Street}{ {BuildingNumber}}\\n{{PostalCode!} }{City!}\\n{CountryName!}',
  OM: '{{Company}\\n}{BP {PoBoxNumber}\\n}{{PostalCode!}\\n}{{City!}\\n}{{Province!}\\n}{CountryName!}',
  PK: '{{Company}\\n}{{BuildingName}\\n}{{BuildingNumber} }{Street}\\n{{District}\\n}{City!}{-{PostalCode!}}\\n{CountryName!}',
  PW: '{{Company}\\n}{{Line1}\\n}{{Line2}\\n}{{Line3}\\n}{{Line4}\\n}{{Line5}\\n}{City!}{ {Province!}}{ {PostalCode!}}\\n{CountryName!}',
  PS: '{{Company}\\n}{{BuildingNumber} }{Street}\\n{{PostalCode!} }{City!}\\n{CountryName!}',
  PA: '{{Company}\\n}{Street}{ {BuildingNumber}}\\n{{PostalCode!}, }{City!}\\n{{Province!}\\n}{CountryName!}',
  PG: '{{Company}\\n}{P.O. Box {PoBoxNumber}\\n}{City!}{ {PostalCode!}}{ {Province!}}\\n{CountryName!}',
  PY: '{{Company}\\n}{Street}{ {BuildingNumber}}\\n{{PostalCode!} }{City!}\\n{CountryName!}',
  PE: '{{Company}\\n}{Street}{ {BuildingNumber}}{, {District}}\\n{{PostalCode!}\\n}{{City!}\\n}{CountryName!}',
  PH: '{{Company}\\n}{P.O. Box {PoBoxNumber}\\n}{{BuildingName}\\n}{{BuildingNumber} }{Street}{, {District}}\\n{{City!}\\n}{{PostalCode!} }{Province!}\\n{CountryName!}',
  PN: '{{Company}\\n}{{Line1}\\n}{{Line2}\\n}{{Line3}\\n}{{Line4}\\n}{{Line5}\\n}{{City!}\\n}{{PostalCode!}\\n}{CountryName!}',
  PL: '{{Company}\\n}{Street}{ {BuildingNumber}}\\n{{PostalCode!} }{City!}\\nPOLSKA / POLAND',
  PT: '{{Company}\\n}{{BuildingName}\\n}{Street}{ {BuildingNumber}}\\n{{District}\\n}{{PostalCode!} }{City!}\\n{CountryName!}',
  PR: '{{Company}\\n}{{Line1}\\n}{{Line2}\\n}{{Line3}\\n}{{Line4}\\n}{{Line5}\\n}{City!}{ {Province!}}{ {PostalCode!}}\\n{CountryName!}',
  QA: '{{Company}\\n}{{BuildingName}\\n}{P.O. Box {PoBoxNumber}\\n}{{City!}\\n}{CountryName!}',
  RE: '{{Company}\\n}{{SubBuilding}\\n}{{BuildingName}\\n}{{BuildingNumber} }{Street!}\\n{{District!}\\n}{BP {PoBoxNumber}\\n}{{PostalCode!} }{City!}\\n{CountryName!}',
  RO: '{{Company}\\n}{Street}{ {BuildingNumber}}\\n{{District}\\n}{{PostalCode!} }{City!}\\n{CountryName!}',
  RU: '{{Company}\\n}{{Street}\\n}{a/ya {PoBoxNumber}\\n}{ {BuildingNumber}}{, {SubBuilding}}\\n{{City!}\\n}{{District!}\\n}{{Province!}\\n}{CountryName!}{\\n{PostalCode!}}',
  RW: '{{Company}\\n}{B.P. {PoBoxNumber}\\n}{{City!}\\n}{CountryName!}',
  MF: '{{Company}\\n}{{SubBuilding}\\n}{{BuildingName}\\n}{{BuildingNumber} }{Street!}\\n{{District!}\\n}{BP {PoBoxNumber}\\n}{{PostalCode!} }{City!}\\n{CountryName!}',
  WS: '{{Company}\\n}{P.O Box {PoBoxNumber}\\n}{{BuildingNumber} }{{Street}\\n}{{District}\\n}{{City!}\\n}{CountryName!}',
  SM: '{{Company}\\n}{Street}{ {BuildingNumber}}\\n{{PostalCode!} }{City!}\\n{CountryName!}',
  ST: '{{Company}\\n}{Street}{ {BuildingNumber}}{, {SubBuildling}}\\n{{City!}\\n}{CountryName!}',
  SA: '{{Company}\\n}{{PoBoxNumber}\\n}{{BuildingNumber} }{Street}{, {District}}\\n{City!}{ {PostalCode!}}\\n{CountryName!}',
  SN: '{{Company}\\n}{{SubBuilding}\\n}{{BuildingName}\\n}{{BuildingNumber} }{Street}\\n{BP {PoBoxNumber}\\n}{{PostalCode!} }{City!}\\n{CountryName!}',
  RS: '{{Company}\\n}{PP {PoBoxNumber}\\n}{Street}{ {BuildingNumber}}{ /{SubBuilding}}\\n{{City!}\\n}{{PostalCode!}\\n}{CountryName!}',
  SC: '{{Company}\\n}{P.O. Box {PoBoxNumber} }{{City!}\\n}{{Province!}\\n}{CountryName!}',
  SL: '{{Company}\\n}{{BuildingNumber} }{Street}\\n{{City!}\\n}{CountryName!}',
  SG: '{{Company}\\n}{{BuildingNumber} }{Street}\\n{{SubBuilding} }{{BuildingName}\\n}{City!}{ {PostalCode!}}\\n{CountryName!}',
  SX: '{{Company}\\n}{Street}{ {BuildingNumber}}\\n{City!}{ {Province!}}\\n{CountryName!}',
  SK: '{{Company}\\n}{P.O. Box {PoBoxNumber}\\n}{Street}{ {BuildingNumber}}\\n{{PostalCode!} }{City!}\\n{CountryName!}',
  SI: '{{Company}\\n}{p.p. {PoBoxNumber}\\n}{Street}{ {BuildingNumber}}\\n{SI-{PostalCode!} }{City!}\\n{CountryName!}',
  SB: '{{Company}\\n}{P.O. Box {PoBoxNumber}\\n}{{City!}\\n}{{Province!}\\n}{CountryName!}',
  SO: '{{Company}\\n}{P.O. Box {PoBoxNumber}\\n}{{BuildingNumber} }{Street}\\n{City!}{, {Province!}}{ {PostalCode!}}\\n{CountryName!}',
  ZA: '{{Company}\\n}{PO Box {PoBoxNumber}\\n}{{SubBuilding} }{{BuildingName}\\n}{{BuildingNumber} }{Street}\\n{{District}\\n}{{City!}\\n}{{PostalCode!}\\n}{CountryName!}',
  GS: '{{Company}\\n}{{Line1}\\n}{{Line2}\\n}{{Line3}\\n}{{Line4}\\n}{{Line5}\\n}{{City!}\\n}{{PostalCode!}\\n}{CountryName!}',
  SS: '{{Company}\\n}{POST OFFICE BOX {PoBoxNumber}\\n}{{PostalCode!} }{City!}\\n{CountryName!}',
  ES: '{{Company}\\n}{Apartado {PoBoxNumber}\\n}{{BuildingName}\\n}{Street}{ {BuildingNumber}}{, {SubBuilding}}\\n{{PostalCode!} }{City!}\\n{{Province!}\\n}{CountryName!}',
  LK: '{{Company}\\n}{{BuildingNumber} }{Street}\\n{{District}\\n}{{City!}\\n}{{PostalCode!}\\n}{CountryName!}',
  BL: '{{Company}\\n}{{SubBuilding}\\n}{{BuildingName}\\n}{{BuildingNumber} }{Street!}\\n{{District!}\\n}{BP {PoBoxNumber}\\n}{{PostalCode!} }{City!}\\n{CountryName!}',
  SH: '{{Company}\\n}{{Line1}\\n}{{Line2}\\n}{{Line3}\\n}{{Line4}\\n}{{Line5}\\n}{{City!}\\n}{{PostalCode!}\\n}{CountryName!}',
  KN: '{{Company}\\n}{{BuildingNumber} }{Street}\\n{{City!}\\n}{{Province!}\\n}{CountryName!}',
  LC: '{{Company}\\n}{{BuildingNumber} }{Street}\\n{{City!}\\n}{CountryName!}',
  PM: '{{Company}\\n}{{SubBuilding}\\n}{{BuildingName}\\n}{{BuildingNumber} }{Street!}\\n{{District!}\\n}{BP {PoBoxNumber}\\n}{{PostalCode!} }{City!}\\n{CountryName!}',
  VC: '{{Company}\\n}{P.O. Box {PoBoxNumber}\\n}{{BuildingNumber} }{Street}\\n{City!}{ VC{PostalCode!}}\\n{CountryName!}',
  SD: '{{Company}\\n}{{BuildingName}\\n}{B.P. {PoBoxNumber}\\n}{{PostalCode!}\\n}{{City!}\\n}{CountryName!}',
  SR: '{{Company}\\n}{Street}{ {BuildingNumber}}\\n{{District}\\n}{{City!}\\n}{CountryName!}',
  SJ: '{{Company}\\n}{Postboks {PoBoxNumber}\\n}{Street}{ {BuildingNumber}}\\n{{PostalCode!} }{City!}\\n{CountryName!}',
  SE: '{{Company}\\n}{BOX {PoBoxNumber}\\n}{Street}{ {BuildingNumber}}\\n{SE-{PostalCode!} }{City!}\\n{CountryName!}',
  CH: '{{Company}\\n}{Street}{ {BuildingNumber}}\\n{Case postale {PoBoxNumber}\\n}{{PostalCode!} }{City!}\\n{CountryName!}',
  SY: '{{Company}\\n}{Street}{, {BuildingNumber}}\\n{{District}\\n}{{PostalCode!} }{City!}\\n{CountryName!}',
  TW: '{{Company}\\n}{{BuildingNumber} }{Street}{, {District}}\\n{{PostalCode!} }{City!}\\n{CountryName!}<br><br>----- or -----<br><br>{CountryName!}\\n{{PostalCode!} }{City!}\\n{, {Province}}{{District} }{Street}{{BuildingNumber} }\\n{{Company}}<br><br>----- or -----<br><br>{{Company}\\n}{{BuildingNumber} }{Street}{, {District}}{ {Province}}\\n{City!}{ {PostalCode!}}\\n{CountryName!}',
  TJ: '{{Company}\\n}{Street}{ {BuildingNumber}}\\n{{PostalCode!} }{City!}\\n{CountryName!}',
  TZ: '{{Company}\\n}{P.O. Box {PoBoxNumber}\\n}{{SubBuilding}\\n}{{BuildingNumber} }{Street}\\n{{PostalCode!} }{City!}\\n{{Province!}\\n}{CountryName!}',
  TH: '{{Company}\\n}{{BuildingNumber} }{Street}\\n{{District}, }{City!}{, {Province!}}\\n{{PostalCode!}\\n}{CountryName!}',
  TG: '{{Company}\\n}{Street}{ {BuildingNumber}}\\n{B.P. {PoBoxNumber}\\n}{{City!}\\n}{CountryName!}',
  TO: '{{Company}\\n}{PO BOX {PoBoxNumber}\\n}{{City!}\\n}{CountryName!}',
  TT: '{{Company}\\n}{{BuildingName}\\n}{{BuildingNumber} }{Street}{ {SubBuilding}}\\n{P.O. Box {PoBoxNumber}\\n}{City!}{, {PostalCode!}}\\n{CountryName!}',
  TN: '{{Company}\\n}{{BuildingNumber} }{Street}\\n{{PostalCode!} }{City!}{ {Province!}}\\n{CountryName!}',
  TR: '{{Company}\\n}{{District} Mh.\\n}{Street}{ {BuildingNumber}}\\n{{BuildingName}\\n}{PK {PoBoxNumber}\\n}{{PostalCode!} }{City!}{/{Province!}}\\n{CountryName!}',
  TM: '{{Company}\\n}{{BuildingNumber}, }{Street}\\n{{PostalCode!} }{City!}\\n{CountryName!}',
  TC: '{{Company}\\n}{{Line1}\\n}{{Line2}\\n}{{Line3}\\n}{{Line4}\\n}{{Line5}\\n}{{City!}\\n}{{PostalCode!}\\n}{CountryName!}',
  TV: '{{Company}\\n}{P.O. Box {PoBoxNumber}\\n}{{City!}\\n}{{Province!}\\n}{CountryName!}',
  UG: '{{Company}\\n}{P.O. Box {PoBoxNumber}\\n}{{City!}\\n}{CountryName!}',
  UA: '{{Company}\\n}{Street}{, {BuildingNumber}}{, {SubBuilding}}\\n{City!}{, {District}}\\n{{Province!}\\n}{{PostalCode!}\\n}{CountryName!}',
  AE: '{{Company}\\n}{B.P. {PoBoxNumber}\\n}{{City!}\\n}{{Province!}\\n}{CountryName!}',
  GB: '{{Company}\\n}{{Department}\\n}{{Line1}\\n}{{Line2}\\n}{{Line3}\\n}{{Line4}\\n}{{Line5}\\n}{{City!}\\n}{{PostalCode!}\\n}{{BFPONumber!}\\n}{CountryName!}',
  US: '{{Company}\\n}{{Line1}\\n}{{Line2}\\n}{{Line3}\\n}{{Line4}\\n}{{Line5}\\n}{City!}{ {Province!}}{ {PostalCode!}}\\n{CountryName!}',
  VI: '{{Company}\\n}{{Line1}\\n}{{Line2}\\n}{{Line3}\\n}{{Line4}\\n}{{Line5}\\n}{City!}{ {Province!}}{ {PostalCode!}}\\n{CountryName!}',
  UY: '{{Company}\\n}{Street}{ {BuildingNumber}}{ {SubBuilding}}\\n{{PostalCode!} }{City!}\\n{CountryName!}',
  UZ: '{{Company}\\n}{Street}{ {BuildingNumber}}{, {SubBuilding}}\\n{a/ya {PoBoxNumber}\\n}{{City!}\\n}{CountryName!}{\\n{PostalCode!}}',
  VU: '{{Company}\\n}{P.O. Box {PoBoxNumber}\\n}{{City!}\\n}{CountryName!}',
  VA: '{{Company}\\n}{Street}{ {BuildingNumber}}\\n{{PostalCode!} }{City!}{ {Province!}}\\n{CountryName!}',
  VE: '{{Company}\\n}{{District}\\n}{{Street}\\n}{{BuildingName}\\n}{{SubBuilding}\\n}{City!}{ {PostalCode!}}{, {Province!}}\\n{CountryName!}',
  VN: '{{Company}\\n}{P.O Box {PoBoxNumber}\\n}{{SubBuilding}\\n}{No {BuildingNumber}, }{Street}\\n{{District}, }{City!}\\n{Province!}{ {PostalCode!}}\\n{CountryName!}',
  WF: '{{Company}\\n}{{SubBuilding}\\n}{{BuildingName}\\n}{{BuildingNumber} }{Street!}\\n{{District!}\\n}{BP {PoBoxNumber}\\n}{{PostalCode!} }{City!}\\n{CountryName!}',
  YE: '{{Company}\\n}{B.P. {PoBoxNumber}\\n}{{BuildingNumber} }{Street}\\n{{City!}\\n}{CountryName!}',
  ZM: '{{Company}\\n}{P.O. Box {PoBoxNumber}\\n}{{BuildingNumber} }{Street}\\n{{District}\\n}{City!}{ {PostalCode!}}\\n{CountryName!}',
  ZW: '{{Company}\\n}{P.O. Box {PoBoxNumber}\\n}{{BuildingNumber} }{Street}\\n{{City!}\\n}{{Province!}\\n}{CountryName!}'
};

const destructureFormatString = (formatString: string) => {
  return formatString
    .replace(/[{]/gi, '')
    .replace(/\\n}/gi, '')
    .replace(/{\\n/gi, '<br>')
    .replace(/[}]/gi, '<br>')
    .replace(/\\n/gi, '<br>')
    .replace(/\[\[/gi, '[')
    .replace(/\]\]/gi, ']')
    .replace(/\/\//g, '')
    .replace(/\[ /gi, ' ')
    .replace(/ \]/gi, ' ')
    .replace(/(\[)([\S][^\]]+)(\[)/g, '$2[')
    .replace(/ <br>/g, '<br>')
    .replace(/<br><br>/g, '<br>')
    .replace(/(<br>$)/, '')
    .replace(/, /, '')
    .replace(/!/g, '')
    .split('<br>');
};

export const findFormatByCode = (code: string): string[] | false => {
  const formattedCode = code.toUpperCase();
  const codeIsPresent = countryFormats.hasOwnProperty(formattedCode);
  const formatString = countryFormats[formattedCode];

  return codeIsPresent && formatString
    ? destructureFormatString(formatString)
        .filter(format => Boolean(format))
        .map(format => format.trim())
    : false;
};

export const availableCountryCodes = Object.keys(countryFormats);
