import { Button, SxProps, Theme } from '@mui/material';
import { ElementType, MouseEventHandler, ReactNode, Ref, forwardRef } from 'react';
import { useVigoTranslation } from 'src/@core/hooks/useVigoTranslation';

export interface IButton {
  name: string;
  value: string | undefined;
  type?: 'button' | 'submit' | 'reset' | undefined;
  form?: string;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  component?: ElementType;
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  hidden?: boolean;
  disabled?: boolean;
  disableElevation?: boolean;
  disableFocusRipple?: boolean;
  disableRipple?: boolean;
  fullWidth?: boolean;
  href?: string;
  size?: 'small' | 'medium' | 'large';
  sx?: SxProps<Theme>;
  disableTranslation?: boolean;
  variant?: 'contained' | 'outlined' | 'text';
  ariaControls?: string | undefined;
  ariaHasPopup?: boolean | 'dialog' | 'menu' | 'true' | 'false' | 'grid' | 'listbox' | 'tree' | undefined;
  ariaExpanded?: boolean | undefined;
  onClick?: MouseEventHandler<HTMLSpanElement>;
  tabIndex?: number;
}

export const VigoButton = forwardRef(
  (
    {
      value,
      type,
      form,
      startIcon,
      endIcon,
      component,
      color,
      hidden,
      disabled,
      disableElevation,
      disableFocusRipple,
      disableRipple,
      fullWidth,
      href,
      size,
      sx,
      variant,
      ariaControls,
      ariaHasPopup,
      ariaExpanded,
      disableTranslation,
      name,
      onClick,
      tabIndex,
      ...rest
    }: IButton,
    ref: Ref<any>
  ) => {
    const { t } = useVigoTranslation();

    return (
      <Button
        {...rest}
        ref={ref}
        name={name}
        {...(component ? { component: { component } } : {})}
        {...(tabIndex && { tabIndex })}
        type={type}
        form={form}
        startIcon={startIcon}
        endIcon={endIcon}
        color={color}
        hidden={hidden}
        disabled={disabled}
        disableElevation={disableElevation}
        disableFocusRipple={disableFocusRipple}
        disableRipple={disableRipple}
        fullWidth={fullWidth}
        href={href}
        size={size}
        sx={sx}
        variant={variant}
        aria-controls={ariaControls}
        aria-haspopup={ariaHasPopup}
        aria-expanded={ariaExpanded}
        onClick={e => (onClick ? onClick(e) : null)}>
        {disableTranslation ? value : t(value)}
      </Button>
    );
  }
);
