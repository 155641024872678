import { GetSearchSearchQueryParams, OmniSearchDto } from 'src/models/search';
import { GetAxiosInstance } from '../baseService';
import { ServiceLocation } from '../serviceLocationsAxios';

// Get Routes
export const getOmniSearchResults = async (params?: GetSearchSearchQueryParams, source?: string) => {
  return await GetAxiosInstance(undefined, undefined, undefined, source).get<OmniSearchDto>(
    ServiceLocation.search + '/search/Search',
    { params }
  );
};
