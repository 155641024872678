import { styled } from '@mui/material';
import MuiMenu, { MenuProps } from '@mui/material/Menu';
import MuiMenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import MuiDivider, { DividerProps } from '@mui/material/Divider';

// Menu
export const Menu = styled(MuiMenu)<MenuProps>(({ theme }) => ({
  '& .MuiMenu-paper': {
    minWidth: 320,
    overflow: 'hidden',
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  '& .MuiMenu-list': {
    padding: 0
  }
}));

//  Menu Item
export const MenuItem = styled(MuiMenuItem)<MenuItemProps>(() => ({
  paddingInline: '20px',
  paddingTop: '20px',
  paddingBottom: '16px'
}));

export const Divider: React.FC<DividerProps> = styled(MuiDivider)<DividerProps>(() => ({
  margin: '0 !important'
}));
