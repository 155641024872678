// ** Mock
import mock from 'src/@fake-db/mock';

// ** Types
import { UserDetailsType } from 'src/types/apps/usersTypes';

export const data: { users: UserDetailsType[] } = {
  users: [
    // {
    //   id: '0',
    //   userName: 'gslixby0',
    //   contactNumber: '(479) 232-9151',
    //   roleDescription: ['User'],
    //   company: 'Yotz PVT LTD',
    //   displayName: 'Galen Slixby',
    //   jobTitle: 'Engineer',
    //   image: ''
    // },
    // {
    //   id: '1',
    //   userName: 'hredmore1',
    //   contactNumber: '(472) 607-9137',
    //   roleDescription: ['User'],
    //   company: 'Skinder PVT LTD',
    //   displayName: 'Halsey Redmore',
    //   jobTitle: 'Engineer',
    //   image: ''
    // },
    // {
    //   id: '2',
    //   userName: 'msicely2',
    //   contactNumber: '(321) 264-4599',
    //   roleDescription: ['Admin'],
    //   company: 'Oozz PVT LTD',
    //   displayName: 'Marjory Sicely',
    //   jobTitle: 'Tester',
    //   image: ''
    // },
    // {
    //   id: '3',
    //   userName: 'crisby3',
    //   contactNumber: '(923) 690-6806',
    //   roleDescription: ['User'],
    //   company: 'Oozz PVT LTD',
    //   displayName: 'Cyrill Risby',
    //   jobTitle: 'Business Analyst',
    //   image: ''
    // },
    // {
    //   id: '4',
    //   userName: 'mhurran4',
    //   contactNumber: '(669) 914-1078',
    //   roleDescription: ['User'],
    //   company: 'Aimbo PVT LTD',
    //   displayName: 'Maggy Hurran',
    //   jobTitle: 'Engineer',
    //   image: ''
    // },
    // {
    //   id: '5',
    //   userName: 'gslixby0',
    //   contactNumber: '(479) 232-9151',
    //   roleDescription: ['User'],
    //   company: 'Yotz PVT LTD',
    //   displayName: 'Galen Slixby',
    //   jobTitle: 'Engineer',
    //   image: ''
    // },
    // {
    //   id: '6',
    //   userName: 'hredmore1',
    //   contactNumber: '(472) 607-9137',
    //   roleDescription: ['User'],
    //   company: 'Skinder PVT LTD',
    //   displayName: 'Halsey Redmore',
    //   jobTitle: 'Engineer',
    //   image: ''
    // },
    // {
    //   id: '7',
    //   userName: 'msicely2',
    //   contactNumber: '(321) 264-4599',
    //   roleDescription: ['Admin'],
    //   company: 'Oozz PVT LTD',
    //   displayName: 'Marjory Sicely',
    //   jobTitle: 'Tester',
    //   image: ''
    // },
    // {
    //   id: '8',
    //   userName: 'crisby3',
    //   contactNumber: '(923) 690-6806',
    //   roleDescription: ['User'],
    //   company: 'Oozz PVT LTD',
    //   displayName: 'Cyrill Risby',
    //   jobTitle: 'Business Analyst',
    //   image: ''
    // },
    // {
    //   id: '9',
    //   userName: 'mhurran4',
    //   contactNumber: '(669) 914-1078',
    //   roleDescription: ['User'],
    //   company: 'Aimbo PVT LTD',
    //   displayName: 'Maggy Hurran',
    //   jobTitle: 'Engineer',
    //   image: ''
    // },
    // {
    //   id: '10',
    //   userName: 'gslixby0',
    //   contactNumber: '(479) 232-9151',
    //   roleDescription: ['User'],
    //   company: 'Yotz PVT LTD',
    //   displayName: 'Galen Slixby',
    //   jobTitle: 'Engineer',
    //   image: ''
    // },
    // {
    //   id: '11',
    //   userName: 'hredmore1',
    //   contactNumber: '(472) 607-9137',
    //   roleDescription: ['User'],
    //   company: 'Skinder PVT LTD',
    //   displayName: 'Halsey Redmore',
    //   jobTitle: 'Engineer',
    //   image: ''
    // },
    // {
    //   id: '12',
    //   userName: 'msicely2',
    //   contactNumber: '(321) 264-4599',
    //   roleDescription: ['User'],
    //   company: 'Oozz PVT LTD',
    //   displayName: 'Marjory Sicely',
    //   jobTitle: 'Tester',
    //   image: ''
    // },
    // {
    //   id: '13',
    //   userName: 'crisby3',
    //   contactNumber: '(923) 690-6806',
    //   roleDescription: ['User'],
    //   company: 'Oozz PVT LTD',
    //   displayName: 'Cyrill Risby',
    //   jobTitle: 'Business Analyst',
    //   image: ''
    // },
    // {
    //   id: '14',
    //   userName: 'mhurran4',
    //   contactNumber: '(669) 914-1078',
    //   roleDescription: ['User'],
    //   company: 'Aimbo PVT LTD',
    //   displayName: 'Maggy Hurran',
    //   jobTitle: 'Engineer',
    //   image: ''
    // },
    // {
    //   id: '15',
    //   userName: 'mhurran4',
    //   contactNumber: '(669) 914-1078',
    //   roleDescription: ['User'],
    //   company: 'Aimbo PVT LTD',
    //   displayName: 'Maggy Hurran',
    //   jobTitle: 'Engineer',
    //   image: ''
    // }
  ]
};

// GET:
mock.onGet('/apps/SystemUsers/list').reply(config => {
  // const { q = '' } = config.params ?? '';

  // const queryLowered = q.toLowerCase();

  const filteredData = data.users.filter(
    user =>
      // user.userName.toLowerCase().includes(queryLowered) ||
      // user.company.toLowerCase().includes(queryLowered) ||
      // user.contactNumber.toLowerCase().includes(queryLowered) ||
      // user.displayName.toLowerCase().includes(queryLowered) ||
      // user.jobTitle.toLowerCase().includes(queryLowered)
      user
  );

  return [
    200,
    {
      allData: data.users,
      users: filteredData,
      params: config.params,
      total: filteredData.length
    }
  ];
});

// POST: Add new user
mock.onPost('/apps/SystemUsers/new-user').reply(config => {
  const newUser: UserDetailsType = JSON.parse(config.data);
  data.users.push(newUser);

  return [200, { newUser }];
});

// POST: Edit user
mock.onPost('/apps/SystemUsers/edit-user').reply(config => {
  const editedUser: UserDetailsType = JSON.parse(config.data);
  const user = data.users.filter(user => user.id == editedUser.id)[0];

  if (user !== undefined) {
    EditUser(user, editedUser);

    return [201, { user }];
  } else {
    return [400];
  }
});

function EditUser(user: UserDetailsType, editedUser: UserDetailsType) {
  // user.displayName = editedUser.displayName;
  // user.userName = editedUser.userName;
  // user.image = editedUser.image;
  // user.roleDescription = editedUser.roleDescription;
  console.log(user, editedUser);
}
