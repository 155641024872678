import { GetAxiosInstance } from '../baseService';
import { ConvertServiceLocationToEnum } from '../serviceLocationsAxios';
import { GetUicomponentByidQueryParams, UicomponentDto } from '../../models/account';
import { AxiosRequestConfig } from 'axios';
import {
  UiflowBuilderDto,
  UiflowDto,
  GetUicomponentHintQueryParams,
  UicomponentHintDto,
  GetUicomponentHelpStepQueryParams,
  UicomponentHelpStepDto,
  UicomponentHintDtoApiResponse,
  UicomponentHelpStepDtoApiResponse,
  GetUicomponentTypeQueryParams
} from '../../models/systemconfig';
import {
  DeleteUicomponentHelpStepByIdParams,
  DeleteUicomponentHintByIdParams,
  DeleteUiFlowParams,
  DeleteUiFlowStep,
  GetUIComponents,
  GetUiFlowObjectParams,
  GetUiFlowParams,
  GetUiFlowSteps,
  GetUiFlowTypeParams,
  PostUicomponentHelpStepParams,
  PostUicomponentHintParams,
  PostUiflowParams,
  PostUiFlowStep,
  UpdateUiFlowParams
} from 'src/types/uiflow/uiflow-types';

/**
 * Get all Uiflow objects
 *
 * @param none
 * @returns UiflowDto
 */
export const getAllUiFlow = async (params: GetUiFlowParams) => {
  params.ignoreOwner = true;

  return GetAxiosInstance(undefined, undefined, undefined, undefined, undefined, undefined, true).get<UiflowDto[]>(
    `${ConvertServiceLocationToEnum('systemconfig')}/Uiflow/`,
    { params }
  );
};

/**
 * Get Uiflow object by id
 *
 * @param params GetUiFlowObjectParams
 * @returns UiflowBuilderDto
 */
export const getUiFlowObject = async (params: GetUiFlowObjectParams) => {
  return GetAxiosInstance().get<UiflowBuilderDto>(
    `${ConvertServiceLocationToEnum(params.serviceLocation)}/${params.endPoint}/${params.id}`
  );
};

/**
 * Add a new Uiflow
 *
 * @param params PostUiflowParams
 * @returns
 */
export const postUiFlow = async (params: PostUiflowParams) => {
  return GetAxiosInstance().post(
    `${ConvertServiceLocationToEnum(params.serviceLocation)}/${params.endPoint}`,
    params.data
  );
};

/**
 * Update a Uiflow by id
 *
 * @param params UpdateUiFlowParams
 * @returns
 */
export const updateUiFlow = async (params: UpdateUiFlowParams) => {
  return GetAxiosInstance().put(`${ConvertServiceLocationToEnum('systemconfig')}/Uiflow/${params.id}`, params.data);
};

/**
 * Delete a Uiflow by id
 *
 * @param params DeleteUiFlowParams
 * @returns
 */
export const deleteUiFlow = async (params: DeleteUiFlowParams) => {
  return GetAxiosInstance().delete(
    `${ConvertServiceLocationToEnum(params.serviceLocation)}/${params.endPoint}/${params.id}`,
    params.data
  );
};

/**
 * Get all UI Flow Types
 *
 * @params params GetUiFlowTypeParams
 * @returns UiflowTypeDto[]
 */
export const getUiFlowTypes = async (params?: GetUiFlowTypeParams) => {
  return GetAxiosInstance().get(`${ConvertServiceLocationToEnum('systemconfig')}/UiflowType`, { params });
};

/**
 * Get UI Flow by id
 *
 * @params params GetUiFlowParams
 * @returns UiflowDto
 */
export const getUiFlow = async (params: GetUiFlowParams) => {
  return GetAxiosInstance().get(`${ConvertServiceLocationToEnum('systemconfig')}/Uiflow/${params.id}`, { params });
};

/**
 *  Get all ui components
 *
 * @param params GetUIComponents
 * @returns UicomponentDto[]
 */
export const getUIComponents = async (params?: GetUIComponents) => {
  return GetAxiosInstance().get(`${ConvertServiceLocationToEnum('systemconfig')}/Uicomponent`, { params });
};

/**
 *
 * @param params GetUicomponentByidQueryParams
 * @returns UicomponentDto
 */
export const getUIComponentById = (params?: GetUicomponentByidQueryParams) => {
  return GetAxiosInstance().get<UicomponentDto>(`${ConvertServiceLocationToEnum('systemconfig')}/Uicomp`, { params });
};

/**
 * Update a Uicomponent by id
 *
 * @param params
 * @returns
 */
export const updateUIComponentById = async (params: { id: string; data: any }) => {
  return GetAxiosInstance().put(
    `${ConvertServiceLocationToEnum('systemconfig')}/Uicomponent/${params.id}`,
    params.data
  );
};

/**
 *
 * @param params GetUicomponentTypeQueryParams
 * @returns UicomponentType[]
 */
export const getUIComponentTypes = async (params?: GetUicomponentTypeQueryParams) => {
  return GetAxiosInstance(undefined, undefined, undefined, undefined, undefined, undefined, true).get(
    `${ConvertServiceLocationToEnum('systemconfig')}/UicomponentType`,
    { params }
  );
};

/**
 *  Get all Uiflow steps
 *
 * @param params GetUiFlowSteps
 * @returns UiflowStepDto[]
 */
export const getUiFlowSteps = async (params?: GetUiFlowSteps) => {
  return GetAxiosInstance().get(`${ConvertServiceLocationToEnum('systemconfig')}/UiflowStep`, {
    params
  });
};

/**
 * Add a new Ui flow step
 *
 * @param params PostUiFlowStep
 * @returns
 */
export const postUiFlowStep = async (params: PostUiFlowStep) => {
  return GetAxiosInstance().post(`${ConvertServiceLocationToEnum('systemconfig')}/UiflowStep`, params.data);
};

/**
 * Delete a Ui flow step
 *
 * @param params DeleteUiFlowStep
 * @returns
 */
export const deleteUiFlowStep = async (params: DeleteUiFlowStep) => {
  return GetAxiosInstance().delete(
    `${ConvertServiceLocationToEnum('systemconfig')}/UiflowStep/${params.id}`,
    params.data
  );
};

/**
 * Get UI component hints
 *
 * @param params GetUicomponentHintQueryParams
 * @returns UicomponentHintDto[]
 */
export const getUicomponentHints = async (params?: GetUicomponentHintQueryParams) => {
  return GetAxiosInstance().get<UicomponentHintDto[]>(
    `${ConvertServiceLocationToEnum('systemconfig')}/UicomponentHint`,
    { params }
  );
};

/**
 * Post new UI component hint
 *
 * @param params PostUicomponentHintParams
 * @returns UicomponentHintDtoApiResponse
 */
export const postUicomponentHint = (params: PostUicomponentHintParams) => {
  return GetAxiosInstance().post<UicomponentHintDtoApiResponse>(
    `${ConvertServiceLocationToEnum('systemconfig')}/UicomponentHint`,
    params.data
  );
};

/**
 * Delete UI component by id
 *
 * @param params
 * @returns
 */
export const deleteUicomponentHintById = (params: DeleteUicomponentHintByIdParams) => {
  const reqBody: AxiosRequestConfig<any> = {
    data: params.data
  };

  return GetAxiosInstance().delete<UicomponentHintDtoApiResponse>(
    `${ConvertServiceLocationToEnum('systemconfig')}/UicomponentHint/${params.id}`,
    reqBody
  );
};

/**
 * Get UI component help steps
 *
 * @param params GetUicomponentHelpStepQueryParams
 * @returns UicomponentHelpStepDto[]
 */
export const getUicomponentHelpStep = async (params?: GetUicomponentHelpStepQueryParams) => {
  return GetAxiosInstance().get<UicomponentHelpStepDto[]>(
    `${ConvertServiceLocationToEnum('systemconfig')}/UicomponentHelpStep`,
    { params }
  );
};

/**
 * Post new UI component help step
 *
 * @param params PostUicomponentHelpStepParams
 * @returns UicomponentHelpStepDtoApiResponse
 */
export const postUicomponentHelpStep = async (params: PostUicomponentHelpStepParams) => {
  return GetAxiosInstance().post<UicomponentHelpStepDtoApiResponse>(
    `${ConvertServiceLocationToEnum('systemconfig')}/UicomponentHelpStep`,
    params.data
  );
};

/**
 *
 * @param params DeleteUicomponentHelpStepByIdParams
 * @returns UicomponentHelpStepDtoApiResponse
 */
export const deleteUicomponentHelpStepById = (params: DeleteUicomponentHelpStepByIdParams) => {
  const reqBody: AxiosRequestConfig<any> = {
    data: params.data
  };

  return GetAxiosInstance().delete<UicomponentHelpStepDtoApiResponse>(
    `${ConvertServiceLocationToEnum('systemconfig')}/UicomponentHelpStep/${params.id}`,
    reqBody
  );
};
