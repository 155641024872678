import {
  Box,
  ListItemIcon,
  ListItemText,
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
  MenuItemProps,
  SvgIcon,
  styled,
  useTheme,
  MenuProps
} from '@mui/material';
import { useEffect, useState } from 'react';
import AccountKey from './icons/AccountKey';
import { ChevronDown } from 'mdi-material-ui';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getRolesAsync, putUserProfileAsync } from 'src/services/identity';
import { RoleDto, UserDto, UserProfileDto } from 'src/models/identity';
import { useVigoToast } from 'src/@core/hooks/useVigoToast';
import { useSettings } from 'src/@core/hooks/useSettings';
import { useRouter } from 'next/router';

interface UserRoleMenuProps {
  data: UserDto;
}

// Menu
const Menu = styled(MuiMenu)<MenuProps>(({ theme }) => ({
  '& .MuiMenu-paper': {
    minWidth: 180,
    overflow: 'hidden',
    ...(theme.palette.mode === 'dark' && { backgroundColor: '#39393D' })
  },
  '& .MuiMenu-list': {
    padding: 0
  }
}));

//  Menu Item
const MenuItem = styled(MuiMenuItem)<MenuItemProps>(({ theme }) => ({
  '&:hover': {
    fontWeight: 600,
    backgroundColor: theme.palette.customColors.primaryGradient,
    color: '#fff',
    textShadow: '1px 1px 2px rgba(0,0,0,0.5)'
  }
}));

const UserRoleMenu = ({ data }: UserRoleMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<(EventTarget & Element) | null>(null);
  const [userRoles, setUserRoles] = useState<RoleDto[]>([]);
  const [defaultRole, setDefaultRole] = useState<string>();
  const router = useRouter();
  // Hooks
  const theme = useTheme();

  const { vigoToast } = useVigoToast({});
  const { saveSettings, settings } = useSettings();

  // Queries
  const { data: systemRoles } = useQuery({
    queryKey: ['systemRoles'],
    queryFn: async () => {
      const res = await getRolesAsync();

      return res.data;
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: 180000
  });

  // Mutations
  const putUserProfile = useMutation((userProfile: UserProfileDto): any => {
    return putUserProfileAsync(data.id!, userProfile);
  });

  // Functions

  /**
   * Determines if the user has no available roles.
   *
   * @return {boolean} true if the user has no available roles, false otherwise
   */
  const userHasNoAvailableRoles = (): boolean => {
    return !userRoles || userRoles.length < 2;
  };

  /**
   * Determines if a role update is in progress.
   *
   * @return {boolean} The current status of the role update.
   */
  const roleUpdateIsInProgress = (): boolean => {
    return putUserProfile.isLoading;
  };

  /**
   * Handles the opening of an event.
   *
   * @param {React.MouseEvent<any>} event - The event that triggered the opening.
   * @return {void}
   */
  const handleOpen = (event: React.MouseEvent<any>): void => {
    if (roleUpdateIsInProgress() || userHasNoAvailableRoles()) return;
    setAnchorEl(event.currentTarget);
  };

  /**
   * Closes the menu.
   *
   * @return {void}
   */
  const handleClose = (): void => {
    setAnchorEl(null);
  };

  /**
   * Populates the available user roles based on the system roles and the roles in the data.
   *
   * @return {void}
   */
  const populateAvailableUserRoles = (): void => {
    if (systemRoles) {
      const roles = systemRoles.filter(role => data.roles?.includes(role.id!));
      setUserRoles(roles);
    }
  };

  /**
   * Sets the default role from the user data.
   *
   * @return {void} - No return value.
   */
  const setDefaultRoleFromUserData = (): void => {
    const role = systemRoles?.find(role => role.id === settings.currentRole);

    setDefaultRole(role?.description ?? '');
  };

  /**
   * Persists a role change for a user.
   *
   * @param {string} roleId - The ID of the new role.
   * @return {void} This function does not return anything.
   */
  const persistRoleChangetoSettings = (roleId: string, rolename: string): void => {
    if (!data) return;
    saveSettings({ ...settings, currentRole: roleId as string });

    setDefaultRole(rolename);
    vigoToast.success('Role changed successfully.');
    router.push('/dashboards/Home');
  };

  /**
   * Handles the change of a role.
   *
   * @param {string} roleId - The ID of the role to be changed.
   * @return {void} This function does not return anything.
   */
  const handleRoleChange = (roleId: string, rolename: string): void => {
    handleClose();
    persistRoleChangetoSettings(roleId, rolename);
  };

  // Effects
  useEffect(() => {
    populateAvailableUserRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.roles, systemRoles]);

  useEffect(() => {
    setDefaultRoleFromUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.userProfile?.defaultRoleId, systemRoles]);

  return (
    <>
      <MuiMenuItem sx={{ paddingBlock: '10px' }} onClick={handleOpen}>
        <ListItemIcon>
          <SvgIcon>
            <AccountKey productColor={theme.palette.customColors.primaryGradient} />
          </SvgIcon>
        </ListItemIcon>
        <ListItemText
          sx={{
            '& span:hover': { color: theme.palette.customColors.primaryGradient },
            ...(Boolean(anchorEl) && {
              '& span': { color: `${theme.palette.customColors.primaryGradient} !important` }
            })
          }}>
          {defaultRole}{' '}
          {!userHasNoAvailableRoles() && (
            <Box component="span">
              <ChevronDown sx={{ transform: 'translate(12px, 5px)' }} />
            </Box>
          )}
        </ListItemText>
      </MuiMenuItem>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        transformOrigin={{ horizontal: -20, vertical: 'top' }}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}>
        {userRoles.map(role => (
          <MenuItem key={role.id} onClick={() => handleRoleChange(role?.id ?? '', role.description ?? '')}>
            {role?.description ?? ''}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default UserRoleMenu;
