import { Stack, useTheme } from '@mui/material';
import { VigoTypography } from '../vigo-typography';
import getConfig from 'next/config';
import { Products, useGetProductLevel } from 'src/@core/utils/getProductLevel';

interface VigoLogoProps {
  displayVersion?: boolean;
  forceProduct?: Products;
  forceMode?: 'light' | 'dark';
}

const VigoLogo = (props: VigoLogoProps) => {
  const { displayVersion, forceProduct, forceMode } = props;

  const theme = useTheme();
  const { publicRuntimeConfig } = getConfig();
  const prod = useGetProductLevel();

  let product = prod;
  if (forceProduct) {
    product = forceProduct;
  }

  const mode = forceMode ? forceMode : theme.palette.mode;

  return (
    <>
      <Stack direction="row" spacing={2} sx={{ display: 'flex' }}>
        {product === Products.Flow && (
          <>
            <img
              src="/images/products/flowicon.svg"
              alt="Flow icon"
              style={{
                height: '30px',
                marginTop: '-5px'
              }}
            />
            <img
              src={mode === 'dark' ? '/images/products/flowlogodark.svg' : '/images/products/flowlogolight.svg'}
              alt="Flow Logo"
              style={{
                height: '30px'
              }}
            />
          </>
        )}
        {product === Products.MyPortal && (
          <>
            <img
              src="/images/products/pulseicon.svg"
              alt="Pulse icon"
              style={{
                height: '30px',
                marginTop: '-2px'
              }}
            />
            <img
              src={mode === 'dark' ? '/images/products/myportaldark.svg' : '/images/products/myportallight.svg'}
              alt="MyPortal Logo"
              style={{
                height: '30px'
              }}
            />
          </>
        )}

        {displayVersion && (
          <VigoTypography
            disableTranslation
            value={`Version ${publicRuntimeConfig.Version}`}
            sx={{
              marginTop: '5px !important',
              marginLeft: '15px !important',
              width: '100% !important',
              textAlign: 'right !important'
            }}
          />
        )}
      </Stack>
    </>
  );
};

export default VigoLogo;
