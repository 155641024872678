import { ListItemIcon, ListItemText, SvgIcon, useTheme } from '@mui/material';
import { MenuItem } from './styledComponents';
import DarkModeIcon from './icons/DarkModeIcon';
import DarkModeSwitch from './DarkModeSwitch';
import { useSettings } from 'src/@core/hooks/useSettings';

const DarkModeToggler = () => {
  const { settings, saveSettings } = useSettings();
  const theme = useTheme();

  const { mode } = settings;

  return (
    <MenuItem sx={{ paddingBlock: '10px' }}>
      <ListItemIcon>
        <SvgIcon>
          <DarkModeIcon />
        </SvgIcon>
      </ListItemIcon>
      <ListItemText sx={{ '& span:hover': { color: theme.palette.customColors.primaryGradient } }}>
        Dark Mode
      </ListItemText>
      <DarkModeSwitch
        name="darkMode-toggle"
        checked={mode === 'dark'}
        onChange={event => {
          saveSettings({ ...settings, mode: event.target.checked ? 'dark' : 'light' });
        }}
      />
    </MenuItem>
  );
};

export default DarkModeToggler;
