import { Box, Card, CardHeader, Grid, Icon, IconButton, InputAdornment, MenuItem, Stack } from '@mui/material';
import MuiDrawer, { DrawerProps } from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import { Close, FilterVariant, History, Launch } from 'mdi-material-ui';
import { useRouter } from 'next/router';
import { createElement, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { VigoTypography } from 'src/@vigo/vigo-typography';
import { VigoTextInput } from 'src/@vigo/vigo-textinput';
import PerfectScrollbar from 'react-perfect-scrollbar';
import getConfig from 'next/config';
import dynamic from 'next/dynamic';

interface RecentHistoryLinksProps {
  drawerOpen: Dispatch<SetStateAction<boolean>>;
}

export interface RecentHistory {
  type: string;
  toPath: string;
  expiry: Date;
  timeOfChange: Date;
  record: string;
  uiflowName: string;
  icon: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ActivityCard = styled(Card)(({ theme }) => ({
  height: '55px',
  boxShadow: 'none',
  borderColor: '#eee',
  borderStyle: 'solid',
  borderRadius: '0px',
  borderWidth: '0px 0px 1px 0px'
}));

const Drawer = styled(MuiDrawer)<DrawerProps>(({ theme }) => ({
  width: 600,
  zIndex: theme.zIndex.modal,
  '& .MuiDrawer-paper': {
    border: 0,
    width: '30%',
    minWidth: '300px',
    top: '50px',
    height: '96%',
    zIndex: theme.zIndex.modal,
    boxShadow: theme.shadows[9]
  }
}));

const RecentHistoryOptionsSpacing = styled('div')(({ theme }) => ({
  padding: theme.spacing(5, 6),
  display: 'flex',
  flexDirection: 'column'
}));

const RecentHistoryLinks = (props: RecentHistoryLinksProps) => {
  const [filterString, setFilterString] = useState<string>('');
  const [history, setHistory] = useState<RecentHistory[]>([]);

  const { drawerOpen } = props;

  const router = useRouter();
  const { publicRuntimeConfig } = getConfig();

  const oidcStorage = sessionStorage.getItem(
    'oidc.user:' + publicRuntimeConfig.Authority + ':' + publicRuntimeConfig.ClientId
  );

  let userId = '';
  if (oidcStorage) {
    userId = JSON.parse(oidcStorage).profile.tenantUserId;
  }

  let recentHistory: RecentHistory[] = [];
  const historyStorage = localStorage.getItem(`recent-history-${userId}`);
  if (historyStorage) {
    recentHistory = JSON.parse(historyStorage);
  }

  const handleDrawerClose = (url: string) => {
    router.push(url).then(() => drawerOpen(false));
  };

  const filterRecentHistory = (filter: string): RecentHistory[] => {
    if (filter == '') {
      return recentHistory;
    }

    const filteredHistory: RecentHistory[] = [];

    for (let recentHistoryIndex = 0; recentHistoryIndex < recentHistory.length; recentHistoryIndex++) {
      const historyElement = recentHistory[recentHistoryIndex];

      // Check UIFlow title
      if (historyElement.uiflowName?.toLowerCase().includes(filter.toLowerCase())) {
        filteredHistory.unshift(historyElement);
      }

      // Check record name
      if (
        historyElement.record.toLowerCase().includes(filter.toLowerCase()) &&
        !filteredHistory.includes(historyElement)
      ) {
        filteredHistory.unshift(historyElement);
      }
    }

    return filteredHistory;
  };

  const IconRenderer = ({ recentHistory }: { recentHistory: any }) => {
    let icon = recentHistory.icon;
    if (!recentHistory.icon) {
      // @ts-ignore
      icon = dynamic(() => import('mdi-material-ui').then(mod => mod[['AccountCircleOutline']]));
    } else {
      // @ts-ignore
      icon = dynamic(() => import('mdi-material-ui').then(mod => mod[[recentHistory.icon]]));
    }

    return (
      <Icon
        sx={{
          height: '1.4rem'
        }}>
        {createElement(icon)}
      </Icon>
    );
  };

  useEffect(() => {
    const filteredHistory = filterRecentHistory(filterString);
    setHistory(filteredHistory);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterString]);

  if (history.length == 0) {
    return (
      <Box>
        <Grid container direction="column" spacing={2}>
          <Grid>
            <VigoTextInput
              name="recentHistoryFilterNoHistory"
              label="Filter"
              fullWidth
              value={filterString}
              onChange={e => setFilterString(e.target.value)}
            />
          </Grid>
          <Grid sx={{ pt: '10px' }}>
            <Card>
              <CardHeader title="No Activity Found" titleTypographyProps={{ textAlign: 'center' }}></CardHeader>
            </Card>
          </Grid>
        </Grid>
      </Box>
    );
  }

  return (
    <Box>
      <Grid container direction="column" spacing={2}>
        <Grid>
          <VigoTextInput
            name="recentHistoryFilter"
            placeholder="Filter"
            fullWidth
            value={filterString}
            onChange={e => setFilterString(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FilterVariant />
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid maxWidth="100%" sx={{ pt: '10px' }}>
          {history.map((recentHistory: RecentHistory) => (
            <ActivityCard key={recentHistory.expiry.toString()} sx={{ width: '100%' }}>
              <MenuItem key={recentHistory.expiry.toString()} onClick={() => handleDrawerClose(recentHistory.toPath)}>
                <Stack sx={{ width: '100%' }}>
                  <Grid container direction="row">
                    <Grid item xs={0.7}>
                      <IconRenderer recentHistory={recentHistory} />
                    </Grid>
                    <Grid item xs={10.8}>
                      <VigoTypography
                        disableTranslation
                        value={`${recentHistory.uiflowName}: ${recentHistory.record} - ${recentHistory.type}`}
                        sx={{ mb: '3px' }}
                      />
                      <VigoTypography
                        sx={{ fontSize: '0.85rem' }}
                        disableTranslation
                        value={`${new Date(recentHistory.timeOfChange).toLocaleString()}`}
                      />
                    </Grid>
                    <Grid sx={{ mt: '10px' }} item xs={0.5}>
                      <Launch sx={{ cursor: 'pointer' }} fontSize="medium" />
                    </Grid>
                  </Grid>
                </Stack>
              </MenuItem>
            </ActivityCard>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

const RecentHistoryPanel = () => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <History id="recenthistoryutton" onClick={() => setOpen(true)} />

      <Drawer
        open={open}
        sx={{ '.MuiModal-backdrop': { backgroundColor: 'transparent !important' } }}
        onClose={(e, reason) => {
          if (reason === 'backdropClick') {
            setOpen(false);
          }
        }}
        anchor="right">
        <Box
          className="customizer-header"
          sx={{
            position: 'relative',
            p: theme => theme.spacing(3.5, 5),
            borderBottom: theme => `1px solid ${theme.palette.divider}`
          }}>
          <VigoTypography value="Latest Activity" variant="h6" sx={{ fontWeight: 600, textTransform: 'uppercase' }} />
          <IconButton
            onClick={() => setOpen(false)}
            sx={{
              cursor: 'pointer',
              right: 20,
              top: '50%',
              position: 'absolute',
              color: 'text.secondary',
              transform: 'translateY(-50%)'
            }}>
            <Close />
          </IconButton>
        </Box>
        <PerfectScrollbar options={{ wheelPropagation: false }}>
          <RecentHistoryOptionsSpacing>
            <RecentHistoryLinks drawerOpen={setOpen} />
          </RecentHistoryOptionsSpacing>
        </PerfectScrollbar>
      </Drawer>
    </>
  );
};

export default RecentHistoryPanel;
