import React, { RefObject, useReducer } from 'react';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';

interface GridApiRefContextProps {
  state: {
    gridApiRef: RefObject<GridApiPremium> | null;
  };
  dispatch: React.Dispatch<any>;
}

const GridApiRefContext = React.createContext<GridApiRefContextProps | null>(null);

const gridRefReducer = (state: any, action: { type: any; payload: any }) => {
  switch (action.type) {
    case 'setGridApiRef':
      return { ...state, gridApiRef: action.payload };
    case 'resetGridApiRef':
      return { ...state, gridApiRef: null };
    default:
      return state;
  }
};

const GridRefProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(gridRefReducer, { gridApiRef: null });
  const value = { state, dispatch };

  return <GridApiRefContext.Provider value={value}>{children}</GridApiRefContext.Provider>;
};

const useGridApiRefContext = () => {
  const context = React.useContext(GridApiRefContext);
  if (context === null) {
    throw new Error('useGridApiRefContext must be used within a GridApiRefProvider');
  }

  return context;
};

export { GridRefProvider, useGridApiRefContext };
