import { Avatar, SxProps } from '@mui/material';
import { UserProfileDto } from 'src/models/identity';

interface UserAvatarProps {
  userProfile?: UserProfileDto;
  sx?: SxProps;
}

const UserAvatar = ({ userProfile, sx = {} }: UserAvatarProps) => {
  const { avatar, forname = '', surname = '' } = userProfile ?? {};

  const fullName = `${forname.trim()} ${surname.trim()}`;

  /**
   * Generates a color code based on the input string.
   * https://mui.com/material-ui/react-avatar/#letter-avatars
   *
   * @param {string} string - The input string to generate the color code from.
   * @return {string} The generated color code.
   */
  function stringToColor(string: string): string {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  /**
   * Generates an avatar for a given name.
   *
   * @param {string} name - The name used to generate the avatar.
   * @return {Object} An object representing the avatar.
   */
  function stringAvatar(name: string): {} {
    return {
      sx: {
        width: '32px',
        height: '32px',
        cursor: 'pointer',
        fontSize: '1rem',
        color: '#fff',
        textShadow: '1px 1px 2px rgba(0,0,0,0.5)',
        bgcolor: stringToColor(name),
        ...sx
      },
      children: !forname || !surname ? null : `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
    };
  }

  return (
    <>
      {!avatar && <Avatar {...stringAvatar(fullName)} />}

      {avatar && <Avatar sx={{ width: 32, height: 32, cursor: 'pointer', ...sx }} src={userProfile?.avatar} />}
    </>
  );
};

export default UserAvatar;
