import { ListItemIcon, ListItemText, SvgIcon, useTheme } from '@mui/material';
import { MenuItem } from './styledComponents';
import AccountPlus from './icons/AccountPlus';
import { UserDto } from 'src/models/identity';
import { useAppDispatch } from 'src/store/baseHooks';
import { pushNavigationStack } from 'src/store/slices/uiFlowSlice';
import { useRouter } from 'next/router';

interface UserSettingsProps {
  userData?: UserDto;
  handleClose: (x?: any, y?: any) => void;
}

const UserSettings = ({ userData, handleClose }: UserSettingsProps) => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const theme = useTheme();

  const { id } = userData ?? {};

  /**
   * Handles the click event.
   *
   * @param {any} e - The event object.
   * @return {void}
   */
  const handleClick = (e: any): void => {
    dispatch(pushNavigationStack(router.asPath));
    router.push(`/uiflow/users/${id}`);
    handleClose(e, 'backdropClick');
  };

  return (
    <MenuItem sx={{ paddingBlock: '10px' }} onClick={handleClick}>
      <ListItemIcon>
        <SvgIcon>
          <AccountPlus productColor={theme.palette.customColors.primaryGradient} />
        </SvgIcon>
      </ListItemIcon>
      <ListItemText sx={{ '& span:hover': { color: theme.palette.customColors.primaryGradient } }}>
        User Settings
      </ListItemText>
    </MenuItem>
  );
};

export default UserSettings;
