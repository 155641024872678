import { useEffect } from 'react';
import VerticalNavLink from '../VerticalNavLink';
import { useGetFailedImportsViewsQuery } from 'src/queries/apps/import';
import { subscribe } from 'src/@core/utils/vigoCustomEvent';
import { Props } from '../VerticalNavItems';
import debug from 'debug';

const debugLog = debug('socket:importFailuresNavLink');

const ImportFailureNavLink = ({ item, ...props }: { item: any; props: Props }) => {
  const { data, refetch } = useGetFailedImportsViewsQuery({ enabled: true });
  const TagName: any = VerticalNavLink;

  item.badgeContent = data?.length ?? '';
  item.badgeColor = 'info';

  useEffect(() => {
    const handler = () => {
      debugLog(`${new Date().toLocaleTimeString()} - ImportFailureNavLink - handler ... refreshing`);
      refetch();
    };

    const unsubscribe = subscribe('REFETCH_IMPORT_FAILURES', handler);

    return () => {
      unsubscribe();
    };
  }, [refetch]);

  return <TagName {...props} item={item} />;
};

export default ImportFailureNavLink;
