import debug from 'debug';
import useAccessToken from 'src/@core/hooks/useAccessToken';
import { useGetProductLevel } from 'src/@core/utils/getProductLevel';
import { ConfigUpdateEmitData } from 'src/pages/api/configurationUpdate';
import { getRelationshipManagementSalesOrderConfiguration } from 'src/services/relationshipmanagement';
import { getSalesOrderSalesOrderConfiguration } from 'src/services/salesorder';
import { useAppDispatch } from 'src/store/baseHooks';
import { setCustomerConfiguration, setUpdateAvailableFlag } from 'src/store/slices/customerConfigurationSlice';
import parseCustomerConfig from 'src/views/uiflow/orders/helpers/parseCustomerConfig';

export const CONFIG_UPDATE_EVENT = 'configurationUpdated';

const debugLog = debug('socket:configurationUpdate');

const getCustomerConfiguration = (id: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const [salesOrderResponse, relationshipManagemtResponse] = await Promise.all([
        getSalesOrderSalesOrderConfiguration(id),
        getRelationshipManagementSalesOrderConfiguration(id)
      ]);

      const customResponse = {
        data: { ...(salesOrderResponse?.data ?? {}), ...(relationshipManagemtResponse?.data ?? {}) }
      };

      resolve(customResponse);
    } catch (err) {
      reject(err);
    }
  });
};

const useConfigUpdateHandler = () => {
  const { tenantId = '', partnerId = '' } = useAccessToken() ?? {};
  const prod = useGetProductLevel();
  const dispatch = useAppDispatch();

  /**
   * Handles the config update request by updating the cached customer configuration in local storage and redux.
   *
   * @param data - The data containing the config update request.
   */
  const configUpdateHandler = (data: ConfigUpdateEmitData) => {
    debugLog('Handling config update request: ', data);

    try {
      const { tenantId: requestTenantId } = data;

      if (tenantId === requestTenantId) {
        debugLog('Tenant id match, updating customer configuration for tenant:', tenantId);
        getCustomerConfiguration(partnerId)
          .then(data => {
            // Remove the cached customer configuration from local storage.
            localStorage.removeItem(`CustomerConfig-${partnerId}-${prod}`);
            localStorage.removeItem(`Redux-CustomerConfig-${partnerId}-${prod}`);

            // Set the cached customer configuration to local storage.
            localStorage.setItem(`CustomerConfig-${partnerId}-${prod}`, JSON.stringify(data));

            // Parse the customer configuration and set it to the redux store.
            const parsedData = parseCustomerConfig(data || ({} as any));
            dispatch(setCustomerConfiguration(parsedData));
            localStorage.setItem(`Redux-CustomerConfig-${partnerId}-${prod}`, JSON.stringify(data));
          })
          .catch(err => {
            console.error('Error getting customer configuration:', err);
          });
      } else {
        debugLog('Tenant id does not match, ignoring configuration update request.');
      }
    } catch (e) {
      debugLog('Error handling configuration update:', e);
    }
  };

  /**
   * Handles the config update request by setting the configuration update flag to true for cached customer configurations in local storage and redux.
   * This will trigger the customer configuration to be updated the next time order entry is rendered.
   *
   * @param data - The data containing the config update request.
   */
  const configUpdateHandlerFlagOnly = (data: ConfigUpdateEmitData) => {
    debugLog('Handling config update request: Flag Only');

    try {
      const { tenantId: requestTenantId } = data;

      if (tenantId === requestTenantId) {
        debugLog('Tenant id match, updating customer configuration update flag for tenant:', tenantId);

        const currentLocalConfig = localStorage.getItem(`CustomerConfig-${partnerId}-${prod}`);
        const currentLocalReduxConfig = localStorage.getItem(`Redux-CustomerConfig-${partnerId}-${prod}`);

        if (currentLocalConfig) {
          const parsedDate = JSON.parse(currentLocalConfig);
          parsedDate.configurationUpdateAvailable = true;
          localStorage.setItem(`CustomerConfig-${partnerId}-${prod}`, JSON.stringify(parsedDate));
        }

        if (currentLocalReduxConfig) {
          const parsedDate = JSON.parse(currentLocalReduxConfig);
          parsedDate.configurationUpdateAvailable = true;
          localStorage.setItem(`Redux-CustomerConfig-${partnerId}-${prod}`, JSON.stringify(parsedDate));
        }

        dispatch(setUpdateAvailableFlag(true));
      } else {
        debugLog('Tenant id does not match, ignoring configuration update request.');
      }
    } catch (e) {
      debugLog('Error handling configuration update:', e);
    }
  };

  return { configUpdateHandler, configUpdateHandlerFlagOnly };
};

export default useConfigUpdateHandler;
