// ** MUI Imports
import { Theme } from '@mui/material/styles';

const Typography = (theme: Theme) => {
  return {
    MuiTypography: {
      styleOverrides: {
        gutterBottom: {
          marginBottom: theme.spacing(2)
        },
        color: theme.palette.mode === 'light' ? '#2B2D42' : '#fff'
      }
    }
  };
};

export default Typography;
