import debug from 'debug';
import useAccessToken from 'src/@core/hooks/useAccessToken';
import { publish } from 'src/@core/utils/vigoCustomEvent';
import { ImportFailuresEmitData } from 'src/pages/api/webhooks/importFailures/notify';

export const IMPORTFAILURE_EVENT = 'importFailures';

const debugLog = debug('socket:importFailuresHandler');

interface UserToken {
  partnerId: string;
  tenantId: string;
}

const useImportFailuresHandler = () => {
  const token = useAccessToken();

  const importFailuresHandler = (data: ImportFailuresEmitData) => {
    const { partnerId, tenantId } = data;
    const { partnerId: tokenPartnerId, tenantId: tokenTenantId } = token as UserToken;

    debugLog(`ImportFailuresHandler called with partnerId: ${partnerId} and tenantId: ${tenantId}`);

    if (partnerId === tokenPartnerId && tenantId === tokenTenantId) {
      publish<null>('REFETCH_IMPORT_FAILURES', null);
    }
  };

  return { importFailuresHandler };
};

export default useImportFailuresHandler;
