import mock from './mock';

import './pages/faq';
import './pages/knowledge-base';
import './server-side-menu/vertical.ts';
import './server-side-menu/horizontal';
import './addons/modules';
import './addons/offers';
import './uiflow';
import './uiflow/users';
import './uiflow/companies';
import './workflow';
import './components/vigo-datagrid/userList.ts';
import './components/vigo-address/fieldsByCountry.ts';
import './components/vigo-address/addressSearch.ts';
import './components/vigo-datatable/userDetailList';
import './dashboard/index';
import './uiflow/newsfeed';
import './pages/tracking'

mock.onAny().passThrough();
